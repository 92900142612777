import React from 'react';

export const ArrowLeft = ({
                              color = '#000000',
                              height = 32,
                              width = 32
                          }: { color: string, width?: number, height?: number }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path d="M15 7L10 12L15 17" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};
