import React, {useEffect, useState} from 'react';
import {QuizReqItem, UploadFileResponse} from '../store/types';
import {PercentInput} from '../../../fields/percentInput';
import styles from '../style.module.scss';
import {Textarea} from '../../../fields/textarea';
import ReactFileReader from 'react-file-reader';
import {ImageLayout} from '../../../layouts';

const plusIcon = require('../../../assets/images/plus.png');
const photoIcon = require('../../../assets/images/photo.png');

interface RenderPercentCaseProps {
    answer: QuizReqItem | undefined;
    files: UploadFileResponse[];
    handleSelect: (item: string, rem: string, type: string) => void;
    handleFiles: (file: File) => void;
}

export const RenderPercentCase = (
    {
        answer,
        files = [],
        handleSelect,
        handleFiles
    }: RenderPercentCaseProps
) => {

    const [rem, setRem] = useState<string>('');
    const [selectButton, setSelectButton] = useState<string>('');


    useEffect(() => {
        answer?.rem ? setRem(answer.rem) : setRem('');
    }, [answer]);

    return (
        <div className={styles.variableList}>
            <p className={styles.count}>Ответ:</p>
            <PercentInput
                defaultValue={answer?.value ?? 0}
                onChange={event => {
                    setSelectButton(event.currentTarget.value);
                    handleSelect(event.currentTarget.value, rem, 'text');
                }}
            />
            <div className={styles.remTextarea}>
                <label className={styles.label}>комментировать ответ и приложить файл</label>
                <Textarea
                    value={rem}
                    placeholder={'Комментарий'}
                    onChange={value => setRem(value)}
                    onBlur={(value) => {
                        handleSelect(selectButton, value, 'text');
                    }}
                />
            </div>
            <div className={styles.files}>
                <ReactFileReader
                    fileTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                    multiple={false}
                    handleFiles={(files: File) => {
                        // setOpen(false)
                        handleFiles(files);
                    }}>
                    <div className={styles.wrapReaderButton}>
                        <button
                            disabled={!answer?.value && !answer?.rem}
                            className={styles.fileReaderButton}
                            onClick={
                                async (e) => {
                                    await sessionStorage.setItem('isFile', 'OK');
                                }
                            }
                        >
                            <ImageLayout
                                src={plusIcon}
                                imageWidth={24}
                                imageHeight={24}
                                containerWidth={24}
                                containerHeight={24}
                            />
                        </button>
                        <span className={styles.minText}>Доб. файл</span>
                    </div>
                </ReactFileReader>

                {files?.length > 0
                    ? <div className={styles.list}>
                        {files.map((file, index) => (
                            <button key={index} className={styles.fileReaderButton}>
                                <ImageLayout
                                    src={photoIcon}
                                    imageWidth={36}
                                    imageHeight={36}
                                    containerWidth={36}
                                    containerHeight={36}/>
                            </button>
                        ))}
                    </div>
                    : null}
            </div>
        </div>
    );
};
