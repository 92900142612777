import React from 'react';
import styles from '../style.module.scss';
import {Scanner} from '@yudiel/react-qr-scanner';
import {scannerFormats} from '../../inventory/ScannerPage';
import {Button} from '../../../fields/button';
import {mainColor} from '../../../utils/constants';

interface ScanContainerProps {
    onCansel: () => void;
    onScan: (value: string) => void;
}

export const ScanContainer = (
    {
        onCansel,
        onScan
    }: ScanContainerProps
) => {
    const handleOnScan = (result: any) => {
        if (result) {
            const value = result[0].rawValue;
            onScan(value);
        }
    };
    return (
        <div className={styles.scanner}>
            <div className={styles.scannerWrapper}>
                <Scanner
                    classNames={{
                        container: 'scanner-frame',
                    }}
                    formats={scannerFormats}
                    onScan={(barcode) => handleOnScan(barcode)}
                />
            </div>

            <div className={styles.scannerButton}>
                <Button
                    text={'Отмена'}
                    textStyleType={'semiBold'}
                    style={{
                        border: `1px solid ${mainColor}`,
                        borderRadius: '12px',
                        fontSize: '14px',
                        height: '43px'
                    }} onClick={onCansel}/>
            </div>

        </div>
    );
};
