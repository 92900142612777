import {ListIdName} from '../../../components/taskFilter/store/type';
import {Message} from '../../../components/chat/store/types';
import {DateType} from '../../../store/types';

export const enum TaskType {
    Insert = 'Insert',
    Update = 'Update',
    Accept = 'Accept',
    Reject = 'Reject',
    Defer = 'Defer'
}

export interface ITaskReq {
    Type: string;
    ID_Type: string;
    SelectRow: string;
    ID_Form: string;
    ID_State: string;
    ID_Sect?: string;
    ID_Obj: string;
    ID_Solver?: string;
    ID_Priority?: string;
    ID_App?: string;
    Name?: string;
    Rem?: string;
    RemVote?: string;
}

export interface ITask {
    ID: number;
    ID_Type: number;
    DI_str: string;
    ID_Source: number;
    ID_Parent: number;
    ID_Sect: number;
    ID_Step: number;
    ID_State: number;
    ID_Priority: number;
    ID_Obj: number;
    ID_Solver: number;
    ID_Author: number;
    ID_User: number;
    ID_Plan: number;
    ID_Color: number;
    ID_Kontra: number;
    IsActive: number;
    SumApp: string | null,
    SumPlat: string | null,
    DateApp: DateType,
    DateClose: string | null,
    DateTimeClose: string | null,
    DateDeadline: DateType,
    DateResume: string | null,
    DateResumeFact: string | null,
    DateWant: string | null,
    DatePlat: string | null,
    DatePlan: string | null,
    DateOrder: string | null,
    NumOrder: string | null,
    Name: string;
    Payer: string | null,
    Payee: string | null,
    PayeeINN: number | null,
    Tag1: string | null,
    Tag2: string | null,
    Tag3: string | null,
    Rem: string;
    DI: DateType,
    UI: number;
    DU: DateType,
    UU: number | null,
    IsNew: number;
    IsEnd: number;
    IsJob: number;
    SourceName: string;
    StateName: string;
    PriorityName: string;
    PriorityColor: string;
    SectName: string;
    ObjName: string;
    ObjCode: string;
    ObjOrd: string | null,
    ObjAddress: string | null,
    ObjEMail: string | null,
    ObjContact: string | null,
    ID_ObjParent: number;
    ObjParentName: string;
    SolverName: string;
    UserName: string;
    AuthorName: string;
    TypeName: string;
    PlanName: string | null,
    PlanNum: string | null,
    PlanIsActive: string | null,
    KontraName: string | null,
    KontraINN: string | null,
    ID_Route: number;
    RouteName: string;
    StepName: string;
    StepOrd: number;
    ID_SolverStep: number;
    ID_EmplOPS: number | null,
    EmplNameOPS: string | null,
    ID_EmplTU: number | null,
    EmplNameTU: number | null,
    ID_ParentSect: number;
}

export interface ITheme {
    id: number;
    name: string;
    childs: ListIdName[];
}

export interface ThemeObjects {
    value: string;
    label: string;
    childs?: ThemeObjects[];
}

export interface TaskFile {
    ID_Note: number;
    ID_File: number;
    ID_App: number;
    ID_User: number;
    Note: string;
    DI: {
        date: string;
        timezone_type: number;
        timezone: string;
    },
    UserName: string;
    NameCurr: string;
    NameOrig: string;
    url: string;
    NameDraft: string;
    Role: string;
    IsActiveFile: number;
    IsActiveNote: number;
    IsActive: number;
    DI_str: string;
}

export interface Signature {
    DateVote: string | null;
    DateVote_str: string;
    GroupName: string | null;
    ID: number | null;
    ID_Group: number | null;
    ID_User: number | null;
    Name: string;
    Ord: number;
    Rem: string | null;
    UserName: string | null;
    Vote: number;
}

export interface MessageFile {
    ID_Note: number;
    ID_File: number;
    ID_App: number;
    ID_User: number;
    Note: string | null;
    DI: {
        date: string;
        timezone_type: number;
        timezone: string;
    },
    UserName: string;
    NameCurr: string;
    NameOrig: string;
    NameDraft: string;
    Role: string | null,
    IsActiveFile: number;
    IsActiveNote: number;
    IsActive: number;
    url: string;
}


export interface TaskState {
    messages: Message[];
}

export interface ICondition {
    FieldList: string | null;
    FieldName: string;
    FieldRem: string | null;
    FieldType: string;
    FieldVal: string | null;
    FileNameCurr: string | null;
    FileNameOrig: string | null;
    ID_App: number;
    ID_AppField: number;
    ID_Field: number;
    ID_File: number | null;
    ID_SectField: number;
    ID_Type: number;
}

export interface TaskLocationState {
    ID_Sect?: number;
    ID_Obj?: number;
    ID_Solver?: number;
    ID_Priority?: number;
    Name?: string;
    Rem?: string;
    ID_App?: number;
    ID_State?: number;
    AuthorName?: string;
    date?: string;
    DateDeadline?: string;
    idForm?: string;
}

export type QuicklyTaskForm = {
    Name: string;
    Rem: string
}

export interface TaskSwitcherState {
    currentTab: number,
    createdTask: CreatedTask | null;
    quicklyTask: QuicklyTaskForm
}

export interface IFillableForm {
    ID_State?: string;
    ID_Sect?: string;
    ID_Obj: string;
    ID_Solver?: string;
    ID_Priority?: string;
    Name: string;
    Rem: string;
    DateDeadline?: string;
}

export interface CreatedTask {
    ID_Sect: '',
    ID_Obj: '',
    ID_Priority: '',
    ID_Solver: '',
    Name: '',
    Rem: '',
    DateDeadline: ''
}
