import {createSlice} from '@reduxjs/toolkit';
import {EmployeeState} from './types';

const initialState: EmployeeState = {
    currentEmployee: null
};

const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setEmployee: (state, {payload}) => {
            state.currentEmployee = payload;
        }
    }
});

export const {setEmployee} = employeeSlice.actions;
export default employeeSlice.reducer;
