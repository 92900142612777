import React from 'react';
//import {FilterOpt} from "../store/types";
import styles from '../style.module.scss';
import {UnmountClosed} from 'react-collapse';
import {DatePicker, Select, TimeRangePickerProps} from 'antd';
import dayjs from 'dayjs';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import {Option} from '../../../store/types';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {setDateFilter, setGroup} from '../store/report.slice';

const {RangePicker} = DatePicker;

const rangePresets: TimeRangePickerProps['presets'] = [
    {label: 'Пос нед', value: [dayjs().add(-7, 'd'), dayjs()]},
    {label: 'Пос мес', value: [dayjs().add(-14, 'd'), dayjs()]},
];

interface ChartControlsProps {
    isOpen: boolean;
}

const groupOptions: Option[] = [
    {label: 'Дни недели', value: 'D'},
    {label: 'Даты', value: 'Date'},
    {label: 'Недели', value: 'W'},
    {label: 'Месяцы', value: 'M'},
];

export const ChartControls = ({isOpen}: ChartControlsProps) => {
    const dispatch = useAppDispatch();
    const {group, date} = useAppSelector(state => state.report);

    const handleChangeDate = (dateStrings: [string, string]) => {
        if (dateStrings.some(i => i === '')) {
            dispatch(setDateFilter([]));
        } else {
            dispatch(setDateFilter(dateStrings));
        }
    };

    const handleSelect = (value: string) => {
        dispatch(setGroup(value));
    };

    return (
        <UnmountClosed isOpened={isOpen}>
            <div className={styles.filterControls}>
                <div className={styles.inputWrap}>
                    <RangePicker
                        dropdownClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format="YYYY-MM-DD"
                        presets={rangePresets}
                        onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                        separator="-"
                        value={
                            date.length > 0
                                ? [dayjs(date[0], 'YYYY-MM-DD'), dayjs(date[1], 'YYYY-MM-DD')]
                                : undefined
                        }
                        allowClear={false}
                    />
                </div>
                <div className={styles.inputWrap}>
                    <span className={styles.inputLabel}>Группировка по периоду</span>
                    <Select
                        options={groupOptions}
                        className={styles.inputRange}
                        value={group}
                        style={{padding: '0px 10px'}}
                        onSelect={(value, option) => {
                            handleSelect(value);
                        }}/>
                </div>
                {/*<div className={styles.filterOptButtons}>*/}
                {/*    <button*/}
                {/*        className={styles.filterButtonsButton}*/}
                {/*        onClick={clearFilter}*/}
                {/*    >Очистить фильтр*/}
                {/*    </button>*/}
                {/*    <button*/}
                {/*        className={styles.filterButtonsButton}*/}
                {/*        onClick={applyFilter}*/}
                {/*    >Применить фильтр*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        </UnmountClosed>

    );
};
