import React, {useEffect, useState} from 'react';
import {ImageLayout, MainLayout} from '../../layouts';
import Header from '../../components/header';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {extractValueFromString, fromJsonToUrlencoded} from '../../helpers';
import {BackButton} from '../../components/backButton';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Input} from '../../fields/input';
import {TextareaControl} from '../../fields/textarea';
import styles from './style.module.scss';
import {Button} from '../../fields/button';
import ReactFileReader from 'react-file-reader';
import {useUploadFileMutation} from '../quiz/store/quiz.api';
import {toast} from 'react-toastify';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {FileFromBack} from '../quiz/store/types';
import {ITaskReq, TaskType} from './store/types';
import {useActionTaskMutation} from './store/task.api';
import {Modal} from '../../components/modal';
import {Success} from './components';
import {mainColor} from '../../utils/constants';
import {useSendMessageMutation} from '../../components/chat/store/chat.api';
import {FinancialFile} from '../financial/state/types';
import {RenderAttachment} from '../../components/renderAttachment';
import {defaultQuicklyTask, setQuicklyTask} from './store/task.slice';

const plusIcon = require('../../assets/images/plus.png');
const photoIcon = require('../../assets/images/photo.png');

interface IForm {
    Name: string;
    Rem: string;
}

const schemaForm = yup.object().shape({
    Name: yup.string().required('Обязательно к заполнению'),
    Rem: yup.string().required('Обязательно к заполнению')
});

export const QuicklyTaskCreate = () => {
    const {search, state} = useLocation();
    const {id} = useParams<{ id: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {id_con} = useAppSelector(state => state.auth.user);

    const [backFiles, setFiles] = useState<FileFromBack[]>([]);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [numTask, setNumTask] = useState<number>(0);

    const [currentFile, setCurrentFiles] = useState<FinancialFile | File>();
    const [numPages, setNumPages] = useState<number>(1);
    const [showFile, setShowFile] = useState<boolean>(false);

    const fromPage = extractValueFromString(search, 'from');

    const [uploadFile] = useUploadFileMutation();
    const [saveTask] = useActionTaskMutation();
    const [sendMessage] = useSendMessageMutation();
    const {quicklyTask} = useAppSelector(state => state.taskSwitch);

    const {control, setValue, watch, handleSubmit} = useForm<IForm>({
        mode: 'onChange',
        resolver: yupResolver(schemaForm)
    });

    useEffect(() => {
        if (quicklyTask) {
            setValue('Name', quicklyTask.Name);
            setValue('Rem', quicklyTask.Rem);
        }
    }, []);

    const handleFiles = (files: any) => {
        const {fileList, base64} = files;
        const formData = new FormData();
        formData.append('load_file', fileList[0], fileList[0].origName);
        formData.append('type', 'task');

        uploadFile({file: formData})
            .unwrap()
            .then(async (result) => {
                await sessionStorage.removeItem('isFile');
                setFiles([...backFiles, {
                    id: result.id,
                    fileName: result.fileName,
                    origName: result.origName,
                    base64
                }]);
                toast(`Загружен файл: ${result.origName}`, {
                    type: 'info',
                    position: 'top-center'
                });
            }).catch(() => {
            toast.error('Ошибка загрузки', {
                position: 'top-center'
            });
        });
    };

    const submit = handleSubmit(values => {
        const data: ITaskReq = {
            Type: TaskType.Insert,
            ID_Type: '2',
            SelectRow: '1',
            ID_Form: '115',
            ID_State: '1',
            ID_Sect: id,
            ID_Obj: `${state.idObj}`,
            Name: values.Name,
            Rem: values.Rem
        };

        saveTask({
            data: fromJsonToUrlencoded({data})
        })
            .unwrap()
            .then((data) => {
                if (data.status) {
                    setNumTask(Number(data?.info?.ID));
                    if (backFiles.length > 0) {
                        const dataForm = new FormData();
                        dataForm.append('Type', 'Insert');
                        dataForm.append('ID_App', `${data?.info?.ID}`);
                        dataForm.append('ID_Form', '115');
                        dataForm.append('SelectRow', '1');

                        for (let i = 0; i < backFiles.length; i++) {
                            dataForm.append(`files[${i}]`, JSON.stringify(backFiles[i]));
                        }
                        sendMessage({id_con, body: dataForm})
                            .unwrap()
                            .then(() => {
                                setOpen(true);
                            }).catch((e: any) => {
                            toast(`Ошибка при добавлении файлов к ${values.Name}`, {
                                type: 'error',
                                position: 'top-center'
                            });
                        });
                    } else {
                        setOpen(true);
                    }
                } else {
                    toast(`Ошибка: ${data?.message}`, {
                        type: 'error',
                        position: 'top-center'
                    });
                }

            })
            .catch((err) => {
                toast(`Ошибка при сохранении ${values.Name}`, {
                    type: 'error',
                    position: 'top-center'
                });
            }).finally(() => dispatch(setQuicklyTask(defaultQuicklyTask)));
    });

    const handleConfirm = () => {
        setOpen(false);
        navigate('/');
    };

    const handleShowFile = (file: any) => {
        setShowFile(true);
        setCurrentFiles(file);
    };

    const handleBack = () => {
        dispatch(setQuicklyTask(defaultQuicklyTask));
        navigate(-1);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Новая задача SOS'}/>
            <BackButton text={`Назад в ${fromPage}`} onClick={handleBack}/>
            <div className={styles.form}>
                <Input
                    type={'text'}
                    fieldName={'Name'}
                    placeholder={'введите название задачи'}
                    defaultValue={watch('Name')}
                    control={control}
                    onChange={value => {
                        dispatch(setQuicklyTask({...quicklyTask, Name: value}));
                        setValue('Name', String(value));
                    }}
                />
                <div className={styles.space}/>
                <TextareaControl
                    fieldName={'Rem'}
                    control={control}
                    placeholder={'введите описание задачи'}
                    defaultValue={watch('Rem')}
                    onChange={value => {
                        dispatch(setQuicklyTask({...quicklyTask, Rem: value}));
                        setValue('Rem', String(value));
                    }}
                />
                <div className={styles.fileList}>
                    <ReactFileReader
                        fileTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                        multiple={false}
                        base64
                        handleFiles={(files: File[]) => {
                            handleFiles(files);
                        }}>
                        <div className={styles.wrapReaderButton}>
                            <button
                                className={styles.fileReaderButton}
                                onClick={
                                    async (e) => {
                                        await sessionStorage.setItem('isFile', 'OK');
                                    }
                                }
                            >
                                <ImageLayout
                                    src={plusIcon}
                                    imageWidth={24}
                                    imageHeight={24}
                                    containerWidth={24}
                                    containerHeight={24}/>
                            </button>
                            <span className={styles.minText}>Доб. файл</span>
                        </div>

                    </ReactFileReader>
                    {backFiles.length > 0
                        ? <div className={styles.list}>
                            {backFiles.map((file, index) => (
                                <div key={index} className={styles.wrapReaderButton}
                                     onClick={() => handleShowFile(file)}>
                                    <button key={`${index}-${file.fileName}`} className={styles.fileReaderButton}>
                                        <ImageLayout src={photoIcon} imageWidth={36} imageHeight={36}
                                                     containerWidth={36}
                                                     containerHeight={36}/>
                                    </button>
                                    <span className={styles.minText}>{file.fileName}</span>
                                </div>
                            ))}
                        </div>
                        : null}
                    {showFile ?
                        <div className={styles.modalFile}>
                    <span
                        className={styles.close}
                        onClick={() => {
                            setShowFile(false);
                        }}
                    />
                            <div className={styles.modalFileContent}>
                                <RenderAttachment
                                    currentFile={currentFile}
                                    pageNumber={numPages}
                                    setNumPages={setNumPages}
                                />
                            </div>

                        </div> : null}
                </div>
                <div className={styles.bottomWrapper}>
                    <Button
                        text={'Сохранить задачу'}
                        textStyleType={'semiBold'}
                        style={{
                            color: mainColor,
                            fontSize: '16px'
                        }}
                        onClick={submit}
                    />
                </div>
            </div>
            <Modal isOpen={isOpen}>
                <Success
                    title={'Новая задача'}
                    text={'По Вашему обращению создана задача'}
                    onConfirm={handleConfirm}
                    numberTask={numTask}/>
            </Modal>
        </MainLayout>
    );
};
