import {useState} from 'react';
import {toast} from "react-toastify";
import {FileFromBack} from "../screens/quiz/store/types";
import {useUploadFileMutation} from "../screens/quiz/store/quiz.api";

interface Ret {
    backFiles: any[],
    base64: string,
    handleFiles: (file: any) => void;
    clearFile: () => void;
}

export const useFileUpload = (): Ret => {
    const [uploadFile] = useUploadFileMutation();

    const [backFiles, setFiles] = useState<FileFromBack[]>([]);
    const [base64, setBase64] = useState<string>('');

    const handleFiles = (files: FileReader) => {
        const formData = new FormData();
        const {fileList, base64} = files as any;
        formData.append('load_file', fileList[0], fileList[0].origName);
        formData.append('type', 'task');

        uploadFile({file: formData})
            .unwrap()
            .then(async (result) => {
                await sessionStorage.removeItem('isFile');
                setFiles([...backFiles, {
                    id: result.id,
                    fileName: result.fileName,
                    origName: result.origName
                }]);
                setBase64(base64);
                toast(`Загружен файл: ${result.origName}`, {
                    type: 'info',
                    position: 'top-center'
                });
            }).catch(() => {
            toast.error('Ошибка загрузки', {
                position: 'top-center'
            });
        });
    };

    const clearFile = () => {
        setFiles([]);
        setBase64('');
    };

    return {handleFiles, backFiles, base64, clearFile};
};
