import React from 'react';
import {UnmountClosed} from 'react-collapse';
import styles from '../style.module.scss';
import {Option} from '../../../store/types';
import {IFinancialFilter} from '../store/type';
import {SelectFormRow} from '../../selectFormRow';

interface FilterProps {
    defaultFilter: IFinancialFilter[];
    isCollapsed: boolean;
    optObjects: Option[];
    optCompanies: Option[];
    optAuthors: Option[];
    handleSelectObj: (value: string) => void;
    handleSelectKontras: (value: string) => void;
    handleSelectPayer: (value: string) => void;
    handleSelectAuthors: (value: string) => void;
    applyFilter: () => void;
    clearFilter: () => void;
}

export const Filter = (
    {
        defaultFilter,
        isCollapsed,
        optObjects,
        optCompanies,
        optAuthors,
        handleSelectObj,
        handleSelectAuthors,
        handleSelectKontras,
        handleSelectPayer,
        applyFilter,
        clearFilter
    }: FilterProps) => {

    const defaultObj = defaultFilter.find(i => i.type === 'FObjects')?.value ?? '';
    const defaultKontras = defaultFilter.find(i => i.type === 'FKontras')?.value ?? '';
    const defaultPayer = defaultFilter.find(i => i.type === 'Payer')?.value ?? '';
    const defaultAuthor = defaultFilter.find(i => i.type === 'FAuthors')?.value ?? '';

    return (
        <UnmountClosed isOpened={isCollapsed}>
            <div className={styles.filterWrapper}>
                <div className={styles.filterCollapse}>
                    <SelectFormRow
                        options={optObjects}
                        label={'Объекты'}
                        disabled={false}
                        isTreeOptions={false}
                        defaultValue={defaultObj as string}
                        handleSelect={handleSelectObj}
                    />
                    {/*<SelectFormRow*/}
                    {/*    options={optCompanies}*/}
                    {/*    label={'Плательщик'}*/}
                    {/*    disabled={true}*/}
                    {/*    isTreeOptions={false}*/}
                    {/*    defaultValue={defaultPayer}*/}
                    {/*    handleSelect={handleSelectPayer}*/}
                    {/*/>*/}
                    <div className={styles.payer}>
                        <div className={styles.payerLabelBox}>
                            <label>Плательщик</label>
                        </div>
                        <div className={styles.payerInputBox}>
                            <input className={styles.input} value={defaultPayer}
                                   onChange={e => handleSelectPayer(e.target.value)}/>
                        </div>
                    </div>
                    <SelectFormRow
                        options={optCompanies}
                        label={'Получатель'}
                        disabled={false}
                        isTreeOptions={false}
                        defaultValue={defaultKontras}
                        handleSelect={handleSelectKontras}
                    />
                    {/*<SelectFormRow*/}
                    {/*    options={optSolver}*/}
                    {/*    label={'Отв.'}*/}
                    {/*    disabled={false}*/}
                    {/*    isTreeOptions={false}*/}
                    {/*    defaultValue={defaultSolver}*/}
                    {/*    handleSelect={handleSelectSolver}*/}
                    {/*/>*/}
                    <SelectFormRow
                        options={optAuthors}
                        label={'Автор'}
                        disabled={false}
                        isTreeOptions={false}
                        defaultValue={defaultAuthor}
                        handleSelect={handleSelectAuthors}
                    />
                    <div className={styles.filterButtons}>
                        <button
                            className={styles.filterButtonsButton}
                            onClick={clearFilter}
                        >Очистить фильтр
                        </button>
                        <button
                            className={styles.filterButtonsButton}
                            onClick={applyFilter}
                        >Применить фильтр
                        </button>
                    </div>
                </div>
            </div>

        </UnmountClosed>
    );
};
