const monthMap: {[key: string]: string} = {
  'янв.': '01',
  'февр.': '02',
  марта: '03',
  'апр.': '04',
  мая: '05',
  июня: '06',
  июля: '07',
  'авг.': '08',
  'сент.': '09',
  'окт.': '10',
  'нояб.': '11',
  'дек.': '12',
};

export const convertDateForRequest = (dateString: string): string => {
  if (!dateString) {
    return new Date().toISOString().split('T')[0];
  }
  const components = dateString.split(' ');

  const day = components[0].trim();
  const month = monthMap[components[1]].trim();
  const year = components[2].trim();

  const dateObject = new Date(year + '-' + month + '-' + day);

  return dateObject.toISOString().split('T')[0];
};
