import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {getObjectByIdForm} from '../../helpers';
import {ListMenu} from '../home/store/types';
import {ListButtons} from '../../components/listButtons';
import {typeFlow} from '../../store/types';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {clearInventoryList} from './store/inventory.slice';

export const Inventory = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {idForm} = useParams<{ idForm: string }>();
    const {data} = useGetAllQuestionsQuery();
    const {currentObj} = useAppSelector(state => state.inventory);

    const buttonVariable = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    const handleClick = (item: ListMenu) => {
        if (item.typeMob === typeFlow.invChek) {
            navigate(`/scanner/${item.idForm}`);
        }
        if (item.typeMob === typeFlow.invNew) {
            navigate(`/inventory-create`, {state: {backPath: `/inventory/${item.idParent}`}});
        }
        if (item.typeMob === typeFlow.invOst) {
            dispatch(clearInventoryList());
            navigate(`/inventory-list`, {state: {backPath: `/inventory/${idForm}`, idForm}});
        }
    };

    return (<ListButtons
        title={`Вы находитесь в ${currentObj?.name}`}
        buttons={buttonVariable?.children ?? []}
        handleClick={handleClick}/>);
};
