import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {OptionChart} from "./store/types";


export const createCircleChart = (chartId: string, items: OptionChart[], cb: (category: string, value: string) => void) => {

    const data = items.map(i => {
        return {
            category: i.label, //formatDate(parseDateString(i.name)),
            value: +i.value,
            totalPaySum: i.totalPaySum
        };
    });

    let root = am5.Root.new(chartId);

    if (root._logo) {
        root._logo.dispose();
    }


    root.setThemes([
        am5themes_Animated.new(root)
    ]);


    let chart = root.container.children.push(am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(20)
    }));


    let series = chart.series.push(am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
        alignLabels: false,
        legendLabelText: "{category}",
        legendValueText: "{totalPaySum} / {valuePercentTotal.formatNumber('0.00')}%",
    }));

    series.set("colors", am5.ColorSet.new(root, {
        colors: [
            am5.color(0x4caf50), // Цвет 1
            am5.color(0xe91e63), // Цвет 2
            am5.color(0x3f51b5), // Цвет 3
            am5.color(0xffeb3b), // Цвет 4
        ],
    }));


    series.labels.template.setAll({
        text: "{valuePercentTotal.formatNumber('0.00')}%",
        // textType: "radial",
        centerX: am5.p50,
        centerY: 0,
        fontSize: "12px",
        textAlign: "left",
    });


    // Добавление легенды
    let legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(30),
        x: am5.percent(30),
        layout: root.verticalLayout,
        marginTop: 15,
        marginBottom: 15,

    }));

    legend.itemContainers.template.events.on('click', (ev) => {
        if (ev.target.dataItem) {
            const dataItem = ev.target.dataItem as any;
            const dataContext = dataItem.dataContext.dataContext as any;

            if (dataContext) {
                const category = dataContext.category;
                const value = dataContext.value;
                cb(category, String(value));
            }
        }
    });

    legend.labels.template.setAll({
        fontSize: "16px", // Размер шрифта
        fontWeight: "500", // Жирный шрифт
        textAlign: "left", // Выравнивание текста по левому краю
    });

    series.data.setAll(data);

    series.appear(1000, 100);

    legend.data.setAll(series.dataItems);


    return root;
};
