import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Map, YMaps} from '@pbe/react-yandex-maps';
import styles from './styles.module.scss';
import {useNavigate} from 'react-router-dom';
import {useLazyGetObjectInMapQuery, useLazyGetPointAllQuery} from './store/monitoring.api';
import {MonitoringFilter, RenderObjPoint, RenderOrderPoint} from './components';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {ArrowLeft, HomeIcon, NavigationIcon, ZoomIn, ZoomOut} from '../../assets/svg';
import {themColors} from '../../hooks/useChangeTheme';
import {changePointOrders} from './store/monitoring.slice';
import classNames from 'classnames';
import {OrderStatusesType} from '../monitoringOrder/store/types';
import {Point} from './store/types';


const switcher: { value: OrderStatusesType, label: string }[] = [
    {label: 'ожидание', value: 'Waiting'},
    {label: 'ожид готовки', value: 'WaitCooking'},
    {label: 'готовка', value: 'CookingStarted'},
    {label: 'на полке', value: 'CookingCompleted'},
    {label: 'в пути', value: 'OnWay'},
    {label: 'доставлено', value: 'Delivered'},
    {label: 'закрыто', value: 'Closed'},
    {label: 'отмена', value: 'Cancelled'},
];

export const MonitoringMap = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [getDataMap, {data, isFetching}] = useLazyGetObjectInMapQuery();

    const [getAllPoints, {data: orders}] = useLazyGetPointAllQuery();

    const {pointsOrders} = useAppSelector(state => state.monitor);
    const {theme} = useAppSelector(state => state.app);
    const mapRef = useRef<any>(null);
    const [selectStatus, setSelectStatus] = useState<OrderStatusesType[]>([]);

    const [zoom, setZoom] = useState(10);
    const [ymaps, setYmaps] = useState<any>();
    const [viewOrders, setViewOrders] = useState<Point[]>([]);

    useEffect(() => {
        getDataMap({params: ''});
        getAllPoints()
            .unwrap()
            .then((data) => {
                dispatch(changePointOrders(data));
            });
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setZoom(zoom);
        }

    }, [zoom]);

    useEffect(() => {
        if (selectStatus?.length === 0) {
            setViewOrders([]);
        } else {
            const newArr = orders?.filter(o => selectStatus.includes(o.status_en)) ?? [];
            setViewOrders(newArr);
        }
    }, [selectStatus]);

    const handleZoomIn = () => {
        setZoom((prevZoom) => prevZoom < 19 ? prevZoom + 1 : 19); // Максимальный зум - 19
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => prevZoom > 0 ? prevZoom - 1 : 0); // Минимальный зум - 0
    };

    const switchStatus = (status: OrderStatusesType) => {
        if (selectStatus.includes(status)) {
            const newArr = selectStatus.filter(i => i !== status);
            setSelectStatus(newArr);
        } else {
            setSelectStatus([...selectStatus, status]);
        }
    };

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const {latitude, longitude} = position.coords;
                    mapRef.current.setCenter([latitude, longitude]);
                },
                (error) => {
                    console.error('Error getting user\'s location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const renderOrder = useMemo(() => {

        return <>{ymaps && viewOrders?.map((item) =>
            <RenderOrderPoint
                key={item.idOrder}
                ymaps={ymaps}
                item={item}/>)}</>;
    }, [viewOrders, ymaps]);

    return (
        <div className={styles.map}>
            <div className={styles.mapHeader}>
                <MonitoringFilter
                    isFixedDate={false}
                    applyFilter={() => {
                    }} clearFilter={() => {
                }}/>
            </div>
            <div className={styles.switcher}>
                {switcher.map(item =>
                    <button
                        className={
                            selectStatus.includes(item.value)
                                ? classNames(styles.switcherButton, styles.switcherButtonActive)
                                : styles.switcherButton
                        }
                        disabled={pointsOrders?.length === 0}
                        onClick={() => switchStatus(item.value)}
                    >{item.label}</button>)}
            </div>
            <button className={classNames(styles.button, styles.zoomIn)} onClick={handleZoomIn}>
                <ZoomIn color={themColors[theme].color}/>
            </button>
            <button className={classNames(styles.button, styles.zoomOut)} onClick={handleZoomOut}>
                <ZoomOut color={themColors[theme].color}/>
            </button>
            <button className={classNames(styles.button, styles.home)} onClick={() => navigate('/')}>
                <HomeIcon color={themColors[theme].color}/>
                <span className={styles.buttonText}>На главную</span>
            </button>
            <button className={classNames(styles.button, styles.back)} onClick={() => navigate(-1)}>
                <ArrowLeft color={themColors[theme]?.color} width={24} height={24}/>
                <span className={styles.buttonText}>Назад</span>
            </button>
            <button className={classNames(styles.button, styles.navigate)} onClick={getUserLocation}>
                <NavigationIcon color={themColors[theme].color}/>
            </button>

            {
                isFetching
                    ? (
                        <div className={styles.loader}>
                            <p className={styles.loaderText}>идет загрузка...</p>
                        </div>
                    )
                    : (
                        <YMaps
                            query={
                                {
                                    lang: 'ru_RU',
                                    apikey: '0adbb008-3071-4219-9486-f3377b7a8089',
                                    load: 'templateLayoutFactory'
                                }
                            }>
                            <Map
                                defaultState={{zoom: 10, center: [55.755864, 37.617698]}}
                                width={window.innerWidth}
                                height={window.innerHeight}
                                instanceRef={mapRef}
                                onLoad={ymaps => {
                                    setYmaps(ymaps);
                                }}
                            >
                                {data?.map((item) => <RenderObjPoint key={item.id} item={item}/>)}

                                {renderOrder}
                            </Map>
                        </YMaps>
                    )
            }
        </div>
    );
};

