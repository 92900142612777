import React, {useState} from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';
import {Message} from './store/types';
import {useAppSelector} from '../../hooks/app.hooks';
import {docxTypes} from './ChatRoom';

export const MessageItem = ({msg}: { msg: Message }) => {

    const {id} = useAppSelector(state => state.auth.user);
    const [url, setUrl] = useState<string>('');
    const [showFile, setShowFile] = useState<boolean>(false);

    const isFrom = id !== msg.ID_User;

    if (msg?.files?.length > 0) {
        return <>
            {msg.files.map((file, index) => {
                let fileExtension = file.url.substring(file.url.lastIndexOf('.') + 1);
                if (docxTypes.includes(fileExtension)) {
                    return (
                        <a key={index}
                           className={
                               isFrom
                                   ? classNames(styles.messageItem, styles.messageItemFrom)
                                   : classNames(styles.messageItem, styles.messageItemTo)
                           }
                           href={file.url} target={'_top'}>
                            <p className={
                                isFrom
                                    ? classNames(styles.messageText, styles.messageTextFrom)
                                    : classNames(styles.messageText, styles.messageTextTo)
                            }>{file.NameOrig}</p>
                            <span className={
                                isFrom
                                    ? classNames(styles.messageText, styles.messageTextFrom)
                                    : classNames(styles.messageText, styles.messageTextTo)
                            }>{file.Note}</span>
                            {isFrom
                                ? <span
                                    className={
                                        classNames(styles.messageDate, styles.messageItemDateFrom)}
                                >{msg.DI_str.split(' ')[1]} <span
                                    className={styles.userNameFrom}>{msg.UserName}</span></span>
                                : <span
                                    className={
                                        classNames(styles.messageDate, styles.messageItemDateTo)
                                    }>
                        <span className={styles.userNameTo}>{msg.UserName}</span> {msg.DI_str.split(' ')[1]}</span>}
                        </a>
                    );
                }

                return (
                    <div key={index}
                         className={
                             isFrom
                                 ? classNames(styles.messageItem, styles.messageItemFrom)
                                 : classNames(styles.messageItem, styles.messageItemTo)
                         }
                         onClick={() => {
                             setUrl(file.url);
                             setShowFile(!showFile);
                         }}>
                        <img className={styles.messageItemImage} src={file.url} alt={file.NameOrig}/>
                        <span className={styles.messageText}>{file.Note}</span>
                        {isFrom
                            ? <span
                                className={
                                    classNames(styles.messageDate, styles.messageItemDateFrom)}
                            >{msg.DI_str.split(' ')[1]} <span
                                className={styles.userNameFrom}>{msg.UserName}</span></span>
                            : <span
                                className={
                                    classNames(styles.messageDate, styles.messageItemDateTo)
                                }>
                        <span className={styles.userNameTo}>{msg.UserName}</span> {msg.DI_str.split(' ')[1]}</span>}
                    </div>
                );
            })}
            {showFile ?
                <div className={styles.modalFile}>
                    <span
                        className={styles.close}
                        onClick={() => {
                            setUrl('');
                            setShowFile(false);
                        }}
                    />
                    <div className={styles.modalFileContent}>
                        <img className={styles.showImage} src={url} alt={'attachment'}/>
                    </div>

                </div> : null}
        </>;
    }
    return (
        <div className={
            isFrom
                ? classNames(styles.messageItem, styles.messageItemFrom)
                : classNames(styles.messageItem, styles.messageItemTo)
        }>
            <span className={styles.messageText}>{msg.Note}</span>
            {
                isFrom
                    ? <span
                        className={
                            classNames(styles.messageDate, styles.messageItemDateFrom)}
                    >{msg.DI_str.split(' ')[1]} <span className={styles.userNameFrom}>{msg.UserName}</span></span>
                    : <span
                        className={
                            classNames(styles.messageDate, styles.messageItemDateTo)
                        }>
                        <span className={styles.userNameTo}>{msg.UserName}</span> {msg.DI_str.split(' ')[1]}
                    </span>
            }
        </div>
    );
};
