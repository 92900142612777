import React from 'react';
import styles from './style.module.scss';

interface ImageLayoutProps {
    src: string;
    imageWidth: number;
    imageHeight: number;
    containerWidth: number;
    containerHeight: number;
}

export const ImageLayout = ({src, imageWidth, imageHeight, containerWidth, containerHeight}: ImageLayoutProps) => {
    return (
        <div className={styles.imageContainer} style={{
            width: `${containerWidth}px`, height: `${containerHeight}px`
        }}>
            <img src={src} alt={'icon'} style={{
                width: `${imageWidth}px`, height: `${imageHeight}px`, objectFit: 'cover'
            }}/>
        </div>
    );
};
