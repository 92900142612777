import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate} from 'react-router-dom';
import {ScrollUp} from '../../components/scrollUp';
import Footer from '../../components/footer';
import styles from './style.module.scss';
import {useLazyGetDeviceListQuery} from './store/inventory.api';
import {clearInventoryList, setInventoryForm} from './store/inventory.slice';
import {InventoryItem} from './store/types';
import AppLoader from '../../components/appLoader';
import {PaperClipOutlined} from '@ant-design/icons';

const validState = ['Отличное', 'Хорошее', 'Среднее', 'Плохое'];

export const InventoryListPage = () => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {list, currentObj} = useAppSelector(state => state.inventory);
    const [getDeviceList, {isLoading}] = useLazyGetDeviceListQuery();

    useEffect(() => {
        getDeviceList({params: `FObjects=${currentObj?.id}`});
    }, []);

    const handleSelectDevice = (item: InventoryItem) => {
        dispatch(setInventoryForm({
            Type: 'Insert',
            ID: String(item.ID),
            ID_Device: String(item.ID_Device),
            DevType: item.DevType,
            Name: item.DevName,
            Code: item.DevCode,
            Code1S: item.DevCode1S ?? '',
            DevRem: item.DevRem,
            Rem: undefined,
            BalCost: item.BalCost ?? '',
            State: validState.includes(item.State) ? item.State : 'Прочее'
        }));
        navigate(`/inventory-check`, {state: {idForm: state.idForm}});
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Список устройств в ${currentObj?.name}`}/>
            <BackButton text={'Назад'} onClick={() => {
                dispatch(clearInventoryList());
                navigate(-1);
            }}/>
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={styles.list}>
                            {list.map((i: InventoryItem, index) => (
                                <div key={`${i.ID}-${index}`} className={styles.inventoryCard}
                                     onClick={() => handleSelectDevice(i)}>
                                    <div className={styles.inventoryCardHead}>
                                        <span className={styles.inventoryCardHeadText}>{i.DevType}</span>
                                        <span className={styles.inventoryCardHeadText}>ID устройства {i.ID_Device}</span>
                                    </div>
                                    <div className={styles.inventoryCardBody}>
                                        <p className={styles.inventoryCardName}>{i.DevName}</p>
                                        {i.FilesCnt > 0
                                            ? <span
                                                className={styles.inventoryCardFilesCount}><PaperClipOutlined/> файлы: {i.FilesCnt}</span>
                                            : null}
                                    </div>
                                    <div className={styles.inventoryCardFooter}>
                                        <span
                                            className={styles.inventoryCardFooterText}><span>состояние:</span>{i.State}</span>
                                        <span className={styles.inventoryCardFooterText}>
                                        <span>место:</span>{i.ObjName}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
            }
            <ScrollUp/>
            <Footer/>
        </MainLayout>
    );
};

