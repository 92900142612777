import React, {useEffect, useState} from 'react';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from '../../hooks/app.hooks';
import {createParams} from './PhoneCallCounts';
import {useGetCallQuery} from './store/call.api';
import AppLoader from '../../components/appLoader';
import styles from './style.module.scss';
import {CallItem} from './store/types';
import {IncomingCall, OutgoingCall} from '../../assets/svg';
import 'react-h5-audio-player/lib/styles.css';
import {ScrollUp} from '../../components/scrollUp';
import {PlayerAudio} from '../../components/player';
import {useDebounce} from '../../hooks';
import {SimpleInput} from '../../fields/simpleInput';
import {CloseCircleOutlined, SearchOutlined} from '@ant-design/icons';
import {Modal} from '../../components/modal';

const viewStatus: { [key: string]: string } = {
    success: 'Отвечен',
    noanswer: 'Нет ответа',
    missed: 'Пропущен',
};

export const PhoneCall = () => {
    const navigate = useNavigate();
    const {filter, dateStart, dateFinish, FObjects} = useAppSelector(state => state.call);

    const params = createParams({filter, dateStart, dateFinish, FObjects});

    const {data, isLoading} = useGetCallQuery({params});
    const [list, setList] = useState<CallItem[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [showPlayer, setShowPlayer] = useState<boolean>(false);
    const [playerUrl, setPlayerUrl] = useState<string | null>(null);

    const {debounceValue} = useDebounce({value: searchText, delay: 300});

    useEffect(() => {
        if (data && data?.length > 0) {
            setList(data);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            const newList = debounceValue !== '' ? data.filter(i => i.Phone.includes(debounceValue)) : data;
            setList(newList);
        }

    }, [debounceValue]);

    const handleListen = (url: string | null) => {
        setPlayerUrl(url);
        setShowPlayer(url !== null);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Звонки'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {isLoading
                ? <AppLoader isFull={false}/>
                : <>
                    <div className={styles.search}>
                        <SimpleInput
                            type={'number'}
                            value={searchText}
                            placeholder={'Поиск по номеру телефона'}
                            readonly={false}
                            size={'sm'}
                            renderIcon={() => <SearchOutlined/>}
                            onChange={setSearchText}/>
                    </div>
                    <div className={styles.list}>
                        {list.map((item: CallItem) => {
                            return (
                                <div key={item.CallID} className={styles.card}>
                                    <div className={styles.cardHeader}>
                                        <span className={styles.cardSubtext}>{item.Date}</span>
                                        <span className={styles.cardSubtext}>{item.Time}</span>
                                        <span className={styles.cardType}>{
                                            item.Type === 'out'
                                                ? <IncomingCall color={'red'}/>
                                                : <OutgoingCall color={'green'}/>
                                        }</span>
                                    </div>
                                    <div className={styles.cardBody}>
                                    <span className={styles.cardTitle}>
                                        <span className={styles.cardSubtext}>тел:</span>
                                        {item.Phone}</span>
                                        <span className={styles.cardText}>
                                        <span className={styles.cardSubtext}>Статус:</span>
                                            {viewStatus[item.Status]}</span>
                                        <span className={styles.cardText}>
                                        <span className={styles.cardSubtext}>Объект:</span>
                                            {item.ObjName}</span>
                                    </div>
                                    <div className={styles.cardFooter}>
                                        {item.Link
                                            ? <span
                                                className={styles.listenText}
                                                onClick={() => handleListen(item.Link)}
                                            >Прослушать запись</span>
                                            : <span className={styles.listenEmpty}>Нет записи</span>}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <Modal isOpen={showPlayer} full={true}>
                        <span className={styles.playerClose} onClick={() => handleListen(null)}>
                            <CloseCircleOutlined/>
                        </span>
                        <div className={styles.player}>

                            {playerUrl ? <PlayerAudio link={playerUrl as string}/> : null}
                        </div>
                    </Modal>
                </>}
            <ScrollUp/>
        </MainLayout>
    );
};
