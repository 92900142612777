import React from 'react';
import styles from "../style.module.scss";


interface CardProps {
    arrayText: string[];
    date: string;
    time: string;
    handlePressCard: () => void;
}

export const Card = (
    {arrayText, time, date, handlePressCard}: CardProps
) => {
    return (
        <div
            className={styles.listItem}
            onClick={() => handlePressCard()}
        >
            <div className={styles.listBody}>
                {arrayText.map((d, idx) => <p key={idx} className={styles.listTitle}>{d}</p>)}
            </div>
            <div className={styles.listInfo}>
                <span className={styles.listDate}>{date}</span>
                <span className={styles.listDate}>{time}</span>
            </div>
        </div>
    );
};
