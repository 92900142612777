import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getObjectByIdForm} from "../../helpers";
import {ListMenu} from "../home/store/types";
import {useGetAllQuestionsQuery} from "../home/store/questions.api";
import {ListButtons} from "../../components/listButtons";
import {typeFlow} from "../../store/types";

export const Purchase = () => {
    const navigate = useNavigate();
    const {idForm} = useParams<{ idForm: string }>();
    const {data} = useGetAllQuestionsQuery();
    const buttonVariable = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    const handlePress = (item: ListMenu) => {

        switch (item.typeMob) {
            case typeFlow.NewClaim:
                return navigate('/task-create', {state: {claim: true}});
            default:
                return null;
        }
    };

    return (
        <ListButtons title={'Заявки'} buttons={buttonVariable?.children ?? []} handleClick={handlePress}/>
    );
};
