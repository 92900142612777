import React, {useEffect, useState} from 'react';
import styles from '../styles.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import {rangePresets} from '../../../components/taskFilter/TaskFilter';
import dayjs from 'dayjs';
import {DatePicker, Select} from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import isToday from 'dayjs/plugin/isToday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {UnmountClosed} from 'react-collapse';
import {useGetFilterObjMutation} from '../../../components/financialFilter/store/financial.filter.api';
import {FilterItemType, IFilter} from '../../../components/taskFilter/store/type';
import {setClearFilter, setFilter} from '../store/monitoring.slice';
import {fromJsonToUrlencoded} from '../../../helpers';
import {userObjParams} from '../../taskCreate/tabs/FormTask';
import {SwitcherTab} from '../../../components/switcherTab';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale('ru');

const {RangePicker} = DatePicker;

interface MonitoringFilterProps {
    isFixedDate: boolean;
    applyFilter: () => void;
    clearFilter: () => void;
}

const buttonsStores: string[] = ['InStore', 'App', 'Web', 'Agr'];
const buttonsTypes: string[] = ['Pin', 'SC', 'DP'];

export const MonitoringFilter = (
    {
        isFixedDate,
        applyFilter,
        clearFilter
    }: MonitoringFilterProps) => {
    const dispatch = useAppDispatch();
    const {filter} = useAppSelector(state => state.monitor);
    const [getObjects, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});

    const [isCollapsed, setCollapse] = useState<boolean>(false);
    const [typeIndex, setTypeIndex] = useState<number[]>([]);
    const [storeIndex, setStoreIndex] = useState<number[]>([]);

    useEffect(() => {
        getObjects({body: fromJsonToUrlencoded({data: userObjParams})});
    }, []);

    const handleChangeDate = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );
        setCollapse(true);

        const newKeys = filter.map(key => {
            if (key.type === 'Start') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish') return {...key, value: formattedDates[1]};
            return key;
        });
        dispatch(setFilter(newKeys));
    };

    const handleSetFilter = (value: string[], type: FilterItemType) => {
        if (filter.some(key => key.type === type)) {
            const newKeys = filter.map(key => key.type === type ? {...key, value} : key);
            dispatch(setFilter(newKeys));
        } else {
            dispatch(setFilter([...filter, {type, value}]));
        }
    };

    const handleSwitchType = (index: number) => {
        const newIdxArr = typeIndex.includes(index)
            ? typeIndex.filter(i => i !== index)
            : [...typeIndex, index];
        setTypeIndex(newIdxArr as number[]);
    };

    const handleSwitchStore = (index: number) => {
        const newIdxArr = storeIndex.includes(index)
            ? storeIndex.filter(i => i !== index)
            : [...typeIndex, index];
        setStoreIndex(newIdxArr as number[]);
    };


    const dateStart = filter.find(k => k.type === 'Start') as IFilter;
    const dateFinish = filter.find(k => k.type === 'Finish') as IFilter;
    const defaultObj = filter.filter(i => i.type === 'ID_Obj').flatMap(i => i.value);

    return (
        <div>
            <div className={styles.dataPickerWrapper}>
                <div className={styles.dataPickerWrapperData}>
                    <RangePicker
                        dropdownClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format="D MMM, YYYY"
                        presets={rangePresets}
                        value={[dayjs(dateStart.value as string, 'YYYY.MM.DD'), dayjs(dateFinish.value as string, 'YYYY.MM.DD')]}
                        onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                        separator="-"
                        disabled={isFixedDate}
                        allowClear={false}
                    />
                </div>
                <button
                    className={styles.dataPickerWrapperButton}
                    onClick={() => setCollapse(!isCollapsed)}
                >
                    {isCollapsed ? 'Скрыть' : 'Фильтр'}
                </button>
            </div>
            <UnmountClosed isOpened={isCollapsed}>
                <div className={styles.filterWrapper}>
                    <div className={styles.filterCollapse}>
                        <div className={styles.inputWrap}>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Выбрать объект"
                                className={styles.select}
                                defaultValue={defaultObj as string[]}
                                onChange={(value) => handleSetFilter(value, 'ID_Obj')}
                                options={objects?.listObject ?? []}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                            />
                        </div>
                        <div className={styles.inputWrap}>
                            <SwitcherTab
                                pageType={'report'}
                                isActive={storeIndex}
                                isMultiple
                                isDisabled={true}
                                buttons={buttonsStores}
                                onClick={(index) => handleSwitchStore(index)}
                            />
                        </div>
                        <div className={styles.inputWrap}>
                            <SwitcherTab
                                pageType={'report'}
                                isActive={typeIndex}
                                isMultiple
                                isDisabled={true}
                                buttons={buttonsTypes}
                                onClick={(index) => handleSwitchType(index)}
                            />
                        </div>
                        <div className={styles.filterButtons}>
                            <button
                                className={styles.filterButtonsButton}
                                onClick={() => {
                                    setCollapse(false);
                                    dispatch(setClearFilter());
                                    clearFilter();
                                }}
                            >Очистить фильтр
                            </button>
                            <button
                                className={styles.filterButtonsButton}
                                onClick={() => {
                                    setCollapse(false);
                                    applyFilter();
                                }}
                            >Применить фильтр
                            </button>
                        </div>
                    </div>

                </div>
            </UnmountClosed>
        </div>
    );
};
