import React, {useEffect, useState} from 'react';
import styles from '../style.module.scss';
import {Chart} from './Chart';
import {ChartReport, FilterOpt, ReportData} from '../store/types';
import {formatNumber} from './Card';
import {useAppSelector} from '../../../hooks/app.hooks';

interface CombineChartsProps {
    dataChart: ReportData[];
    filters: FilterOpt;
    handleClickByLegend: ({cat, type, value}: { cat: string, type: string, value: string }) => void;
}

const initCharts: ChartReport = {
    code: [],
    currency: [],
    type: [],
    placeType: [],
    orderPlace: []
};

const sortBy = ['paySum', 'avgCheck', 'checkCnt'];

export const CombineCharts = (
    {
        dataChart,
        filters,
        handleClickByLegend
    }: CombineChartsProps
) => {

    const [charts, setCharts] = useState<ChartReport>(initCharts);
    const {sortType} = useAppSelector(state => state.report);

    useEffect(() => {
        setCharts((prevCharts) => {
            const exclude = ['period', 'weekDay'];
            const chartTypes = filters ? Object.keys(filters)?.filter(i => !exclude.includes(i)) : [];
            const chartsObj: ChartReport = {...prevCharts};

            for (const chartKey of chartTypes) {
                const uniqueItemsMap = new Map<string, { label: string, value: string, totalPaySum: string }>();

                filters[chartKey as keyof FilterOpt].forEach(t => {
                    const count = dataChart.filter(item => item[chartKey as keyof FilterOpt] === t).length;

                    const sum = dataChart.filter(item => item[chartKey as keyof FilterOpt] === t)
                        .map(i => i[sortBy[sortType] as keyof ReportData])
                        .reduce((acc, i) => (Number(acc) + Number(i)), 0) as number;

                    const totalPaySum: string = formatNumber(Math.round(sortType === 1 ? (sum / count) : sum));
                    uniqueItemsMap.set(t, {
                        label: t,
                        value: String(sortType === 1 && sum > 0 ? (sum / count) : sum),
                        totalPaySum
                    });

                });

                chartsObj[chartKey as keyof ChartReport] = Array.from(uniqueItemsMap.values());
            }

            return chartsObj;
        });
    }, [dataChart, filters, sortType]);

    return (
        <>
            <div className={styles.chartWrapper}>
                <p className={styles.chartName}>типы</p>
                <Chart
                    chart={charts.type}
                    handleClickByLegend={(category, value) =>
                        handleClickByLegend({cat: category, type: 'type', value})}
                />

            </div>
            <div className={styles.chartWrapper}>
                <p className={styles.chartName}>категории</p>
                <Chart chart={charts.code}
                       handleClickByLegend={(category, value) => handleClickByLegend({
                           cat: category,
                           type: 'code',
                           value
                       })}/>
            </div>
            <div className={styles.chartWrapper}>
                <p className={styles.chartName}>источники</p>
                <Chart chart={charts.orderPlace}
                       handleClickByLegend={(category, value) => handleClickByLegend({
                           cat: category,
                           type: 'orderPlace',
                           value
                       })}/>
            </div>
            <div className={styles.chartWrapper}>
                <p className={styles.chartName}>валюты</p>
                <Chart chart={charts.currency}
                       handleClickByLegend={(category, value) => handleClickByLegend({
                           cat: category,
                           type: 'currency',
                           value
                       })}/>
            </div>
            <div className={styles.chartWrapper}>
                <p className={styles.chartName}>размещение</p>
                <Chart chart={charts.placeType}
                       handleClickByLegend={(category, value) => handleClickByLegend({
                           cat: category,
                           type: 'placeType',
                           value
                       })}/>
            </div>
        </>
    );
};
