import React from 'react';
import {TableBodyBudget, TableHeadBudget} from "../state/types";
import styles from '../style.module.scss';

interface BudgetProps {
    tableHead: TableHeadBudget[],
    tableBody: TableBodyBudget
}

export const Budget = (
    {
        tableBody,
        tableHead
    }: BudgetProps
) => {
    const excludeIndexes = tableHead.reduce((acc, item, index) => {
        if (!item.visible) {
            acc.push(index);
        }
        return acc;
    }, [] as number[]);

    const renderHead = tableHead.filter(i => i.visible);
    const width = tableHead.length * 100;

    return (
        <div className={styles.tableWrap}>
            <div className={styles.table} style={{minWidth: `${width}px`}}>
                <div className={styles.tableRow}>
                    {renderHead.map((i, idx) => (
                        <div key={idx} className={styles.tableCel}>{i.title}</div>
                    ))}
                </div>

                {tableBody.map((i: any, idx: number) => (
                    <div className={styles.tableRow} key={idx}>
                        {i.map((el: any, index: number) => {
                            if (excludeIndexes.includes(index)) return null;

                            return <div key={index} className={styles.tableCel}>{el}</div>;
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};
