import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate} from 'react-router-dom';
import styles from './style.module.scss';
import {SwitcherTab} from '../../components/switcherTab';
import {Budget, Form} from './tabs';
import {
    useGetBudgetQuery,
    useGetColorsListQuery,
    useGetPermissionFinancialQuery,
    useLazyGetFinancialByIdQuery
} from './state/financial.api';
import {useLazyGetFilterCompaniesQuery} from '../../components/financialFilter/store/financial.filter.api';
import {ChatRoom} from '../../components/chat';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {Votes} from './tabs/Votes';
import {useGetFilterObjMutation} from '../../components/taskFilter/store/filter.api';
import {fromJsonToUrlencoded} from '../../helpers';
import {defaultFormData, Financial} from './state/types';
import AppLoader from '../../components/appLoader';
import {setCreatedFinancial} from './state/financial.slice';

const buttons: string[] = ['Заявка', 'Бюджет', 'Согл', 'Оплаты', 'Чат'];

export const FinancialCreate = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {state} = useLocation();
    const [getFinancialById, {data: finData, isLoading}] = useLazyGetFinancialByIdQuery();
    const {data: colors} = useGetColorsListQuery();
    const [getCompany] = useLazyGetFilterCompaniesQuery();
    const [getFilterObj, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});
    const {data: budget} = useGetBudgetQuery({ID_Obj: state?.ID_Obj as number}, {skip: !state?.ID_Obj});
    const {data: permission} = useGetPermissionFinancialQuery({ID_App: state.ID_App}, {skip: !state?.ID_App});

    const {company} = useAppSelector(state => state.financialFilter);

    const [activeTab, setActiveTab] = useState<number>(0);

    useEffect(() => {
        getCompany();
        getFilterObj({body: fromJsonToUrlencoded({data: {ShowComp: 'NULL', flagSHID: false}})});
    }, []);

    useEffect(() => {
        if (state?.ID_App) {
            getFinancialById({ID_App: state.ID_App});
        }
    }, [state]);

    const renderTabs = () => {
        switch (activeTab) {
            case 0:
                return <Form
                    item={finData as Financial}
                    colors={colors?.list ?? []}
                    companies={company ?? []}
                    objects={objects?.listObject ?? []}
                />;
            case 1:
                return <Budget
                    tableHead={budget?.tableHead ?? []}
                    tableBody={budget?.tableBody ?? []}/>;
            case 2:
                return <Votes
                    votes={finData?.votes ?? []}
                    ID_App={finData?.ID as number}
                    onChange={() => getFinancialById({ID_App: state.ID_App})}/>;
            case 3:
                return <></>;
            case 4:
                return <ChatRoom ID_App={state?.ID_App}/>;
        }
    };


    return (
        <MainLayout>
            <Header breadCrumbs={state?.ID_App ? `Фин.заявка № ${state.ID_App}` : 'Создать фин заявку'}/>
            <BackButton text={'Назад'} onClick={() => {
                dispatch(setCreatedFinancial(defaultFormData));
                navigate(-1);
            }}/>
            <div className={styles.form}>
                <SwitcherTab
                    pageType={'financial'}
                    buttons={buttons}
                    isActive={activeTab}
                    skip={permission?.permission}
                    onClick={index => setActiveTab(index)}
                />
                {isLoading ? <AppLoader isFull={false}/> : renderTabs()}

            </div>
        </MainLayout>
    );
};
