import {Task} from "../store/types";
import dayjs from "dayjs";
import {IFilter} from "../../../components/taskFilter/store/type";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);
export const CARD_HEIGHT = 176;

export const findMatchingObjects = (dataFilter: IFilter[], dataArray: Task[]): Task[] => {
    const Start = dataFilter.find(i => i.type === 'Start');
    const Finish = dataFilter.find(i => i.type === 'Finish');
    const keysFilter = dataFilter.filter(i => !['Start', 'Finish'].includes(i.type));
    const items = sortByDate(dataArray, Start?.value, Finish?.value);

    return items.filter(obj => {
        if(keysFilter.length > 0) {
            for (const filter of keysFilter) {
                if(String(obj[filter.type as keyof Task]) === String(filter.value)) {
                    return true;
                }
            }
            return false;
        } else {
            return true;
        }
    });
};

export const sortByDate = (dataArray: Task[], startDate: any, endDate: any) => {
    return dataArray.filter(obj => {
        const objDate = dayjs(obj.DI_str, 'DD.MM.YYYY');
        return objDate.isBetween(startDate, endDate, null, '[]');
    });
};

export const parseQueryString = (queryParams: URLSearchParams): { params: IFilter[], currentIndex: number } => {
    const params: IFilter[] = [];
    let currentIndex: number = 0;

    // Перебираем все параметры URLSearchParams
    queryParams.forEach((value, key) => {
        currentIndex = Number(decodeURIComponent(value));
    });

    return {params, currentIndex};
};
