import {TreeItem, TreeList} from "../screens/taskTypes/store/types";

interface MapTaskMenuProps {
    treeList: TreeList
}

export const mapTaskMenu = ({treeList}: MapTaskMenuProps): TreeItem[] => {
    const treeItems = [];

    for (const key in treeList) {
        if (treeList.hasOwnProperty(key)) {
            const treeItem = treeList[key];
            treeItems.push(treeItem);
        }
    }

    return treeItems;
};
