import {api} from "../../../store/api";
import {IObject} from "./types";

const infoApi = api.injectEndpoints({
    endpoints: build => ({
        getObjInfo: build.query<{ object: IObject }, { FObjects: string }>({
            query: ({FObjects}) => ({
                url: `/object/information.php?FObjects=${FObjects}`,
                method: 'GET'
            }),
            transformResponse: ({response}) => {
                return {
                    object: Object.values(response).pop() as IObject
                };
            }
        })
    }),
    overrideExisting: true
});

export const {useGetObjInfoQuery} = infoApi;
