import {ListMenu} from "../screens/home/store/types";

export const mapListMenu = (data: any): ListMenu[] => {
    const result: ListMenu[] = [];

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const menuItemData = data[key];
            const menuItem: ListMenu = {
                idForm: menuItemData.idForm,
                idParent: menuItemData.idParent,
                name: menuItemData.name,
                canRead: menuItemData.canRead,
                canEdit: menuItemData.canEdit,
                icon: {
                    type: menuItemData.icon.type,
                    source: menuItemData.icon.source
                },
                children: [],
                file: menuItemData.file,
                typeMob: menuItemData.typeMob,
                textMob: menuItemData.textMob
            };

            if (menuItemData.children) {
                menuItem.children = mapListMenu(menuItemData.children);
            }

            result.push(menuItem);
        }
    }


    return result;
};
