import React from 'react';
import {Document, Page} from "react-pdf";
import styles from "../../screens/financial/style.module.scss";
import {docxTypes} from "../chat/ChatRoom";

interface RenderAttachmentProps {

    currentFile: any;
    pageNumber: number;
    setNumPages: (num: number) => void;
}

export const RenderAttachment = (
    {
        currentFile, pageNumber, setNumPages
    }: RenderAttachmentProps
) => {

    function onDocumentLoadSuccess({numPages}: { numPages: number }): void {
        setNumPages(numPages);
    }

    if ("url" in currentFile) {
        let fileExtension = currentFile.url.substring(currentFile.url.lastIndexOf('.') + 1);
        if (docxTypes.includes(fileExtension)) {
            return (<a href={currentFile.url} target={'_top'}>
                <span>{currentFile.origName}</span>
            </a>);
        } else {
            return <img className={styles.showImage} src={currentFile.url} alt={'attachment'}/>;
        }
    }

    if ("fileName" in currentFile) {
        if (currentFile.fileName.toLowerCase().includes('.pdf')) {
            return (
                <Document
                    file={currentFile.base64}
                    renderMode={'canvas'}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={'Загружаем ПДФ'}
                >
                    <Page
                        pageNumber={pageNumber}
                        canvasBackground={'#ffffff'}
                        width={window.innerWidth - 40}
                    />
                </Document>
            );
        } else {
            return <img className={styles.showImage} src={currentFile.base64} alt={'attachment'}/>;
        }
    }


    return <></>;
};
