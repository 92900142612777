import React, {useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import Footer from '../../components/footer';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import {useFileUpload} from '../../hooks';
import styles from './style.module.scss';
import ReactFileReader from 'react-file-reader';
import {AddTransparent} from '../../assets/svg';
import {SwitcherTab} from '../../components/switcherTab';
import {EditProfile} from './tabs';
import {useAppSelector} from '../../hooks/app.hooks';
import {useGetProfileQuery} from './store/profile.api';
import AppLoader from '../../components/appLoader';

const tabTitles: string[] = [
    'редактирование профиля пользователя',
    'задачи пользователя - март 2024',
    'активность - март 2024',
];

const switchButtons: string[] = ['Сведения', 'Задачи', 'Активность'];

export const Profile = () => {
    const navigate = useNavigate();
    const {id} = useAppSelector(state => state.auth.user);

    const {data, isLoading} = useGetProfileQuery({idUser: id}, {skip: !id});

    const {handleFiles, base64} = useFileUpload();
    const [currentTab, setCurrentTab] = useState<number>(0);


    const renderTab = () => {
        switch (currentTab) {
            case 0:
                return <EditProfile
                    profile={data?.information}/>;
            case 1:
                return <></>;
            default:
                return <></>;
        }
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Профиль'}/>
            <BackButton text={'Назад в главное меню'} onClick={() => navigate('/')}/>
            <div className={'container'}>
                <div className={styles.avatarWrapper}>
                    <div className={styles.avatar}>
                        <div className={styles.avatarImage}>
                            {base64 !== ''
                                ? <img src={base64} alt={'avatar'}/>
                                : <img src={data?.information?.avatarPath} alt={'avatar'}/>
                            }
                        </div>

                        {currentTab === 0 ? <ReactFileReader
                            fileTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                            multiple={false}
                            base64
                            handleFiles={handleFiles}>
                            <button className={styles.avatarButton}
                                    onClick={
                                        async (e) => {
                                            await sessionStorage.setItem('isFile', 'OK');
                                        }
                                    }>
                                <AddTransparent/>
                            </button>
                        </ReactFileReader> : null}
                    </div>
                </div>
                <p className={styles.title}>{tabTitles[currentTab]}</p>
                <SwitcherTab
                    pageType={'profile'}
                    isActive={currentTab}
                    buttons={switchButtons}
                    onClick={setCurrentTab}
                />
                {isLoading ? <AppLoader isFull={false}/> : renderTab()}
            </div>
            <Footer/>
        </MainLayout>
    );
};
