import React, {HTMLAttributes} from 'react';
import styles from './style.module.scss';
import {resetGlobalAbortController} from '../../store/abortController';
import {ArrowLeft} from '../../assets/svg';
import {useAppSelector} from '../../hooks/app.hooks';
import {themColors} from '../../hooks/useChangeTheme';

const arrowLeft = require('../../assets/images/arrow-left.png');

interface BackButtonProps extends HTMLAttributes<HTMLButtonElement> {
    text: string;
    onClick: () => void;
}

export const BackButton = ({text, onClick, ...props}: BackButtonProps) => {
    const {theme} = useAppSelector(state => state.app);
    return (
        <div className={styles.wrapper}>
            <button {...props} className={styles.button} onClick={() => {
                resetGlobalAbortController();
                onClick();
            }}>
                <ArrowLeft color={themColors[theme]?.color ?? '#000000'}/>
                {/*<ImageLayout*/}
                {/*    src={arrowLeft}*/}
                {/*    imageWidth={32}*/}
                {/*    imageHeight={32}*/}
                {/*    containerWidth={32}*/}
                {/*    containerHeight={32}*/}
                {/*/>*/}
                <span className={styles.buttonText}>{text}</span>
            </button>
        </div>
    );
};
