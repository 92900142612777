import {FaceIdProps} from './types';


export const registerFaceId = async (
    {
        name, displayName
    }: FaceIdProps
): Promise<{ credentialId: string | null }> => {
    try {
        const publicKeyCredentialCreationOptions: PublicKeyCredentialCreationOptions = {
            challenge: new Uint8Array(32),
            rp: {
                name: name as string,
                id: 'ifbx.ru'
            },
            user: {
                id: new Uint8Array([79, 252, 83, 72, 214, 7, 89, 26]),
                name: name as string,
                displayName: displayName as string
            },
            pubKeyCredParams: [
                {
                    type: 'public-key',
                    alg: -7 // ECDSA с SHA-256
                }
            ]
        };

        const credential = await navigator.credentials.create({
            publicKey: publicKeyCredentialCreationOptions
        });
        return {credentialId: credential?.id as string};
    } catch (err) {
        return {credentialId: null};
    }
};
