import React from 'react';
import styles from '../style.module.scss';
import {CardSkeleton} from '../../../components/skeleton';
import {Card} from './Card';
import {IReport} from '../store/types';
import {extractValueFromString} from '../../../helpers';
import {useLocation} from 'react-router-dom';
import {typeFlow} from '../../../store/types';

interface CorpCardProps {
    revenueLoad: boolean;
    writeOffLoad: boolean;
    costPriceLoad: boolean;
    corpRev: IReport;
    corpChecks: IReport;
    corpAvgCheck: IReport;
    corpWriteOff: IReport;
    corpCostPrice: IReport;
    corpCostPricePercent: IReport;
}

export const CombineCard = (
    {
        revenueLoad,
        writeOffLoad,
        costPriceLoad,
        corpRev,
        corpChecks,
        corpAvgCheck,
        corpWriteOff,
        corpCostPrice,
        corpCostPricePercent,
    }: CorpCardProps
) => {
    const {search} = useLocation();
    const retortType = extractValueFromString(search, 'typeMob');
    if (retortType === typeFlow.mainValuesRevenue) {
        return (
            <div className={styles.cardsBox}>
                {revenueLoad ? <CardSkeleton/> : <Card isPlan={true} data={corpRev as IReport}/>}
                {revenueLoad ? <CardSkeleton/> : <Card isPlan={false} data={corpChecks as IReport}/>}
                {revenueLoad ? <CardSkeleton/> : <Card isPlan={false} data={corpAvgCheck as IReport}/>}
            </div>
        );
    }
    if (retortType === typeFlow.mainValuesWriteOff) {
        return (
            <div className={styles.cardsBox}>
                {writeOffLoad ? <CardSkeleton/> : <Card isPlan={false} data={corpWriteOff as IReport}/>}
            </div>
        );
    }
    return (
        <div className={styles.cardsBox}>
            {costPriceLoad ? <CardSkeleton/> :
                <Card isPlan={false} isPercent={true} data={corpCostPricePercent as IReport}/>}
            {costPriceLoad ? <CardSkeleton/> : <Card isPlan={false} data={corpCostPrice as IReport}/>}
        </div>
    );
};
