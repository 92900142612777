import {formatDate} from './formatDate';

interface CheckDeadlineTaskProps {
    date: string | undefined;
}

export const checkDeadlineTask = ({
                                      date,
                                  }: CheckDeadlineTaskProps): { color: string } => {

    let color: string = '#0D101C';
    if (date === undefined) {
        return {color};
    }
    // Заданный срок
    const deadline = new Date(
        formatDate({date: new Date(date), type: 'YYYY-MM-DD'}),
    );

    // Текущая дата
    const currentDate = new Date();

    // Разница между текущей датой и сроком
    const timeDiff = deadline.getTime() - currentDate.getTime();

    // Перевести разницу в дни
    const dayLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (dayLeft > 0 && dayLeft <= 2) {
        color = '#09529f';
    }
    if (dayLeft >= 0) {
        color = '#fd021a';
    }

    return {
        color,
    };
};
