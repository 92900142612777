import {api} from "../../../store/api";
import {ActionItem} from "./types";

const actionListApi = api.injectEndpoints({
    endpoints: build => ({
        getActionList: build.query<ActionItem[], {params: string}>({
            query: ({params}) => ({
                url: `/eventHandlers/getListEvent.php?${params}`,
                method: 'GET'
            }),
            transformResponse: ({list}) => {
                return list;
            }
        })
    }), overrideExisting: true
});

export const {useLazyGetActionListQuery} = actionListApi;
