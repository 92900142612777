import React from 'react';
import styles from "../style.module.scss";
import {ImageLayout} from "../../../layouts";
import {Button} from "../../../fields/button";
import {mainColor} from "../../../utils/constants";

const iconSuccess = require('../../../assets/images/face-success.png');

interface SuccessProps {
    title: string;
    text: string;
    onConfirm: () => void;
    numberTask: number
}

export const Success = ({onConfirm, text, title, numberTask}: SuccessProps) => {

    return (
        <>
            <p className={styles.modalText}>{title}</p>
            <ImageLayout
                src={iconSuccess}
                imageWidth={64}
                imageHeight={64}
                containerWidth={120}
                containerHeight={120}/>
            <p className={styles.modalText} style={{marginBottom: '10px'}}>
                {`${text} №${numberTask}`}
            </p>
            <div className={styles.modalButtons}>
                <Button
                    text={'Закрыть'}
                    textStyleType={'regular'}
                    onClick={onConfirm}
                    style={{
                        border: `1px solid ${mainColor}`,
                        borderRadius: '12px',
                        fontSize: '14px',
                        height: '44px'
                    }}/>
            </div>
        </>
    );
};
