import {api} from "../../../store/api";
import {ResponseTasks} from "./types";

const taskListApi = api.injectEndpoints({
    endpoints: build => ({
        getTaskList: build.query<ResponseTasks, { path: string }>({
            query: ({path}) => ({
                url: path,
                method: 'GET',
                keepUnusedDataFor: 5,
            }),
            transformResponse: ({listID, listStateCnt, listTask}) => {
                return {
                    listID,
                    listStateCnt,
                    listTask: Object.values(listTask)
                };
            }
        }),


    }), overrideExisting: true
});

export const {
    useLazyGetTaskListQuery,
    useGetTaskListQuery,
} = taskListApi;
