import React, {useState} from 'react';
import styles from './style.module.scss';
import {DownCircleOutlined, UpCircleOutlined} from "@ant-design/icons";

export const ScrollUp = () => {
    const [isUp, setIsUp] = useState<boolean>(false);

    const handleScroll = () => {
        const main = document.getElementById('main-container');
        main?.scrollTo({
            top: isUp ? 0 : main.scrollHeight,
            behavior: 'smooth'
        });
        setIsUp(!isUp);
    };

    return (
        <div className={styles.scrollControl}>
            <button className={styles.scrollControlButton} onClick={handleScroll}>
                {isUp ? <UpCircleOutlined/> : <DownCircleOutlined/>}
            </button>
        </div>
    );
};
