import React, {ChangeEvent, KeyboardEvent, ReactNode, useEffect, useState} from 'react';
import {Control, Controller} from "react-hook-form";
import {Value} from "../input/Input";
import styles from "./style.module.scss";
import classNames from "classnames";

interface TextAreaProps {
    fieldName: string;
    control: Control<any>;
    placeholder?: string;
    disabled?: boolean;
    defaultValue?: Value;
    rows?: number;
    onFocus?: (value: Value) => void;
    onBlur?: (value: Value) => void;
    onChange: (value: Value) => void;
    onKeyDown?: () => void;
    renderIcon?: () => ReactNode;
}

export const TextareaControl = (
    {
        control,
        fieldName,
        renderIcon,
        placeholder,
        defaultValue,
        disabled,
        onFocus,
        onBlur,
        rows = 6,
        onChange,
        onKeyDown
    }: TextAreaProps
) => {
    const [valueState, setValueState] = useState<Value>(defaultValue ?? '');

    useEffect(() => {
        defaultValue &&
        setValueState(defaultValue);
    }, [defaultValue]);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const val = e.target.value;
        setValueState(val);
        onChange(val);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
        const key = e.code;
        if (e.target instanceof HTMLInputElement) {
            //const inputElement = e.target as HTMLInputElement;
            //const value = inputElement.value;
            if (key === 'Enter' && onKeyDown) {
                onKeyDown();
            }
        }
    };

    const handleFocus = () => onFocus && onFocus(valueState);
    const handleBlur = () => onBlur && onBlur(valueState);
    return (
        <Controller
            control={control}
            name={fieldName}
            render={
                ({field, fieldState: {error}}) => (
                    <div className={styles.box}>
                        <div className={error
                            ? classNames(styles.wrapper, styles.wrapperError)
                            : styles.wrapper}>
                            {renderIcon ? renderIcon() : null}
                            <textarea
                                {...field}
                                className={styles.textareaControl}
                                value={valueState}
                                rows={rows}
                                disabled={disabled}
                                placeholder={placeholder}
                                style={renderIcon ? {width: '90%'} : {}}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        {error ? <span className={styles.errorMessage}>{error.message}</span> : null}
                    </div>
                )
            }
        />
    );
};
