import {useEffect, useState} from 'react';

type Orientation = 'landscape' | 'portrait'
export const useOrientation = (): { orientation: Orientation } => {
    const [orientation, setOrientation] = useState<Orientation>('portrait');

    useEffect(() => {
        function handleOrientationChange() {
            const newOrientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
            setOrientation(newOrientation);
        }

        window.addEventListener('resize', handleOrientationChange);
        return () => {
            window.removeEventListener('resize', handleOrientationChange);
        };
    }, []);

    return {orientation};
};
