import React from 'react';
import {MainLayout} from "../../layouts";
import Header from "../../components/header";
import {useNavigate} from "react-router-dom";
import {BackButton} from "../../components/backButton";

export const QuizList = () => {
    const navigate = useNavigate();

    return (
        <MainLayout>
            <Header breadCrumbs={'Список отчетов'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
        </MainLayout>
    );
};
