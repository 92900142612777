import React, {ChangeEvent, HTMLInputTypeAttribute, KeyboardEvent, ReactNode, useEffect, useState} from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';
import {Control, Controller} from 'react-hook-form';

export type Value = string | number;

interface InputProps {
    type: HTMLInputTypeAttribute;
    fieldName: string;
    disabled?: boolean;
    control: Control<any>;
    defaultValue?: Value;
    placeholder?: string;
    onFocus?: (value: Value) => void;
    onBlur?: (value: Value) => void;
    onChange: (value: Value) => void;
    onKeyDown?: () => void;
    renderIcon?: () => ReactNode;
    readOnly?: boolean;
}

export const Input = (
    {
        control,
        fieldName,
        type = 'text',
        renderIcon,
        placeholder,
        defaultValue,
        disabled,
        onFocus,
        onBlur,
        onChange,
        onKeyDown,
        readOnly = false
    }: InputProps) => {

    const [valueState, setValueState] = useState<Value>(defaultValue ?? '');

    useEffect(() => {
        defaultValue &&
        setValueState(defaultValue);
    }, [defaultValue]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setValueState(val);
        onChange(val);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const key = e.code;
        if (e.target instanceof HTMLInputElement) {
            if (key === 'Enter' && onKeyDown) {
                onKeyDown();
            }
        }
    };

    const handleFocus = () => onFocus && onFocus(valueState);
    const handleBlur = () => onBlur && onBlur(valueState);

    return (
        <Controller
            control={control}
            name={fieldName}
            render={
                ({field, fieldState: {error}}) => (
                    <div className={styles.box}>
                        <div className={error
                            ? classNames(styles.wrapper, styles.wrapperError)
                            : styles.wrapper}>
                            {renderIcon ? renderIcon() : null}
                            <input
                                {...field}
                                type={type}
                                className={styles.input}
                                value={valueState}
                                readOnly={readOnly}
                                placeholder={placeholder}
                                disabled={disabled}
                                style={renderIcon ? {width: '90%'} : {}}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                        {error ? <span className={styles.errorMessage}>{error.message}</span> : null}
                    </div>
                )
            }
        />
    );
};
