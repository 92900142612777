import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import {useGetFileDataMutation} from './store/test.api';
import styles from './style.module.scss';
import classNames from 'classnames';
import AppLoader from '../../components/appLoader';
import {Document} from 'react-pdf';

const fileTypes: any = [
    {value: 1, name: 'pdf'},
    {value: 2, name: 'png'},
    {value: 3, name: 'jpg'},
    {value: 4, name: 'xlsx'},
    {value: 5, name: 'txt'},
    {value: 6, name: 'docx'},
];


export const TestPage = () => {
    const navigate = useNavigate();

    const [value, setValue] = useState<number>(1);
    const [src, setSrc] = useState<string | null>(null);
    const [getFile, {data, isLoading, reset}] = useGetFileDataMutation();
    const [blob, setBlob] = useState<any>(null);

    useEffect(() => {
        if (data) {
            setBlob(data);
            reset();
        }
    }, [data]);

    useEffect(() => {

        if (blob) {
            if (value === 1) {
                const pdfURL = URL.createObjectURL(blob);
                setSrc(`${pdfURL}.pdf`);
                setBlob(null);
                return;
            }
            if (value === 2 || value === 3) {
                const imgContainer = document.querySelector('#img-container');
                const imgURL = URL.createObjectURL(blob);
                const img = document.createElement('img');
                img.src = imgURL;
                imgContainer?.appendChild(img);
                setBlob(null);
                return;
            }
            if (value >= 4) {
                if (blob instanceof Blob) {
                    const href = URL.createObjectURL(blob);
                    setSrc(`${href}.${fileTypes.find((i: any) => i.value === value).name}`.replace('blob:', ''));
                }
                setBlob(null);
                return;
            }
        }
    }, [blob, value]);


    const renderFile = () => {
        switch (value) {
            case 1:
                return <Document
                    file={{url: src as string}}
                    renderMode={'canvas'}
                    loading={'Загружаем ПДФ'}
                />;
            case 2:
                return <div id={'img-container'} className={styles.img}></div>;
            case 3:
                return <div id={'img-container'} className={styles.img}></div>;

            default:
                return <button onClick={download}>Скачать</button>;
        }
    };


    const download = () => {
        // Создаем ссылку для скачивания
        const link = document.createElement('a');
        link.href = src as string;
        link.download = `filename.${fileTypes.find((i: any) => i.value === value).name}`;  // Укажите желаемое имя файла
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <MainLayout>
            <Header breadCrumbs={'Тестовый показ файлов'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.buttons}>
                {fileTypes.map((item: any) => (
                    <button
                        key={item.value}
                        className={value === item.value ? classNames(styles.buttonsItem, styles.buttonsItemActive) : styles.buttonsItem}
                        onClick={() => {
                            setValue(item.value);
                            getFile({fileValue: item.value});
                        }}
                    >{item.name}</button>
                ))}
            </div>
            {isLoading ? <AppLoader isFull={false}/> : renderFile()}
        </MainLayout>
    );
};
