import React, {ChangeEvent, KeyboardEvent, ReactNode, useState} from 'react';
import styles from '../input/style.module.scss';

interface SingleInputProps {
    type: string;
    value: string;
    readonly: boolean;
    size: 'sm' | 'md';
    placeholder?: string;
    renderIcon?: () => ReactNode;
    onFocus?: (value: string) => void;
    onBlur?: (value: string) => void;
    onChange?: (value: string) => void;
    onKeyDown?: () => void;
}

const heightSize: { [key: string]: string } = {
    sm: '41px',
    md: '56px'
};

export const SimpleInput = (
    {
        type = 'text',
        renderIcon,
        onFocus,
        onBlur,
        value,
        placeholder,
        size,
        readonly,
        onChange,
        onKeyDown
    }: SingleInputProps) => {
    const [valueState, setValueState] = useState<string>(value);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setValueState(val);
        onChange && onChange(val);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        const key = e.code;
        if (e.target instanceof HTMLInputElement) {
            if (key === 'Enter' && onKeyDown) {
                onKeyDown();
            }
        }
    };

    const handleFocus = () => onFocus && onFocus(valueState);
    const handleBlur = () => onBlur && onBlur(valueState);
    return (
        <div className={styles.box}>
            <div className={styles.wrapper} style={{height: heightSize[size]}}>
                {renderIcon ? renderIcon() : null}
                <input
                    type={type}
                    className={styles.input}
                    value={valueState}
                    placeholder={placeholder ?? ''}
                    readOnly={readonly}
                    style={renderIcon
                        ? {
                            width: '90%',
                            height: heightSize[size]
                        } : {height: heightSize[size]}}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                />
            </div>
        </div>
    );
};
