import React from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {useNavigate} from 'react-router-dom';
import {useLazyGetMainDataQuery} from './store/monitoring.api';
import {MonitoringDataCard, MonitoringFilter} from './components';
import {FilterItemReq, IFilter} from '../../components/taskFilter/store/type';
import {useMonitoringControl} from '../../hooks';
import AppLoader from '../../components/appLoader';

function excludeDate(filter: IFilter[]): string {
    const excludeKey = ['Start', 'Finish'];
    return filter.filter(i => !excludeKey.includes(i.type)).map(key => {
        if (key.type === 'ID_Obj') {
            return `${FilterItemReq[key.type]}=${(key.value as string[]).join(',')}`;
        }
        return `${FilterItemReq[key.type]}=${key.value}`;
    }).join('&');
}

export const MonitoringMainData = () => {
    const navigate = useNavigate();
    const [getMainData, {data, isLoading}] = useLazyGetMainDataQuery();
    const {applyFilter, clearFilter} = useMonitoringControl({
        onFetch: ({params}) => getMainData({params})
    });

    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг - Сегодня'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={true}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {isLoading
                ? <AppLoader isFull={false}/> : (
                    <div className={styles.wrapper}>
                        <div className={styles.list}>
                            <MonitoringDataCard column={2} num={Math.round(data?.paySum as number)} text={'Выручка'}/>
                            <MonitoringDataCard column={2} num={data?.trx} text={'Чеков (TRX)'}/>
                            <MonitoringDataCard column={2} num={data?.at} text={'Сред чек (AT)'}/>
                            <MonitoringDataCard column={2} num={data?.adt} text={'Чеков/дн/тчк (ADT)'}/>
                        </div>
                    </div>
                )}

            <Footer/>
        </MainLayout>
    );
};
