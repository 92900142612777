import {useEffect} from 'react';
import {FilterItemReq} from '../components/taskFilter/store/type';
import {initDateKeys} from '../screens/monitoring/store/monitoring.slice';
import {useAppSelector} from './app.hooks';

interface UseMonitoringControlProps {
    onFetch: ({params}: { params: string }) => void;
}

interface MonitoringControlReturn {
    clearFilter: () => void;
    applyFilter: () => void;
}

export const useMonitoringControl = (
    {
        onFetch
    }: UseMonitoringControlProps
): MonitoringControlReturn => {
    const {filter} = useAppSelector(state => state.monitor);

    useEffect(() => {
        const params = filter.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&');
        onFetch({params: `?${params}`});
    }, []);

    const applyFilter = () => {
        const params = filter.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&');
        onFetch({params});
    };

    const clearFilter = () => {
        const params = initDateKeys.map(key => `${FilterItemReq[key.type]}=${key.value}`).join('&');
        onFetch({params});
    };

    return {applyFilter, clearFilter};
};
