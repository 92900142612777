import React, {useCallback, useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {Location, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useGetQuizQuery, useSaveQuizMutation, useUploadFileMutation} from './store/quiz.api';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {BackButton} from '../../components/backButton';
import {QuizItem, QuizReqItem, UploadFileResponse} from './store/types';
import {
    ErrorReport,
    Finished,
    QuizLoader,
    RenderPercentCase,
    RenderQuestion,
    RenderTextCase,
    Success,
    SuccessReport
} from './components';
import styles from './style.module.scss';
import {Button} from '../../fields/button';
import {mainColor} from '../../utils/constants';
import {Modal} from '../../components/modal';
import {formattedDate} from '../../helpers/formattedDate';
import {clearAnswers, setAnswers} from './store/quiz.slice';
import {updateArray} from '../../helpers';
import {toast} from 'react-toastify';
import {setTemporaryDataFile} from '../camera/store/camera.slice';
import {setEmployee} from '../selectEmployee/store/employee.slice';

export type QuizLocationState = {
    idObj?: number;
    parentName?: string;
    path?: string
    locationName?: string;
    isEmployee: boolean
}

export const Quiz = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location: Location = useLocation();
    const {state, pathname} = location as Location & { state: QuizLocationState };
    const [searchParams] = useSearchParams();
    const {answers, currentLocation} = useAppSelector(state => state.quiz);
    const {temporaryDataFiles} = useAppSelector(state => state.camera);
    const {currentEmployee} = useAppSelector(state => state.employee);

    const {data} = useGetQuizQuery({path: state?.path}, {
        skip: !state?.path,
        refetchOnMountOrArgChange: true
    });
    const [saveQuiz] = useSaveQuizMutation();
    const [uploadFile, {isLoading}] = useUploadFileMutation();
    const [questionIndex, setQuestionIndex] = useState<number>(0);
    const [currentQuestion, setCurrentQ] = useState<QuizItem>(data?.listQuestions[questionIndex] as QuizItem);

    const [isShow, setShow] = useState<boolean>(false);
    const [successOpen, setSuccessOpen] = useState<boolean>(false);
    const [errOpen, setErrOpen] = useState<boolean>(false);

    const [files, setFiles] = useState<UploadFileResponse[]>([]);

    useEffect(() => {
        const index = searchParams.get('index');
        if (index) {
            setQuestionIndex(Number(index));
        } else if (answers.length) {
            const updatedParams = new URLSearchParams(searchParams);
            updatedParams.set('index', `${answers.length}`);
            setQuestionIndex(answers.length);
        }
    }, []);

    useEffect(() => {
        handleCaseTemporaryDataFiles();
    }, [temporaryDataFiles]);

    useEffect(() => {
        const current = data?.listQuestions[questionIndex] as QuizItem;
        const files = answers.filter(i => i.id === current.id).map(i => i.files).flat() as UploadFileResponse[];
        setCurrentQ(current);
        setFiles(files);
    }, [questionIndex, data?.listQuestions, answers]);

    const handleCaseTemporaryDataFiles = () => {
        if (temporaryDataFiles !== null) {
            const file = {
                id: temporaryDataFiles.id ?? 0,
                fileName: temporaryDataFiles.fileName,
                origName: temporaryDataFiles.origName
            };
            const newAnswers = answers.map(ans => {
                if (ans.id === currentQuestion?.id) {
                    return {
                        ...ans, files: [...ans.files, file]
                    };
                }
                return ans;
            });

            dispatch(setAnswers(newAnswers));
            dispatch(setTemporaryDataFile(null));
        }
    };

    const handleChangeIndex = (direction: 'back' | 'forward') => {
        const updatedParams = new URLSearchParams(searchParams);
        if (data) {
            if (direction === 'back' && questionIndex !== 0) {
                setQuestionIndex(questionIndex - 1);
                updatedParams.set('index', `${questionIndex - 1}`);
            }
            if (direction === 'forward' && questionIndex < data?.listQuestions?.length - 1) {
                setQuestionIndex(questionIndex + 1);
                dispatch(setTemporaryDataFile(null));
                setFiles([]);
                updatedParams.set('index', `${questionIndex + 1}`);
            }
        }
        navigate({
            pathname: pathname,
            search: updatedParams.toString()
        }, {state});
    };

    const handleSetAnswer = useCallback((value: string | number, rem: string, type: string) => {
        const answer: QuizReqItem = {
            id: currentQuestion.id,
            value: value,
            rem: rem,
            files: String(value) !== '' || rem !== '' ? files : []
        };

        const newState = updateArray(answer, answers, type);

        dispatch(setAnswers(newState));
    }, [files, currentQuestion, answers]);

    const handleShowModal = () => {
        if (state.isEmployee) {
            if (answers.length !== data?.listQuestions.length) {
                setErrOpen(true);
                return;
            }
        }
        setShow(true);
    };

    const handleFiles = async (file: any) => {
        const formData = new FormData();
        formData.append('load_file', file[0], file[0].origName);
        formData.append('type', 'polls');

        uploadFile({file: formData})
            .unwrap()
            .then(async (result) => {
                await sessionStorage.removeItem('isFile');
                dispatch(setTemporaryDataFile(result));
                toast(`Загружен файл: ${result.origName}`, {
                    type: 'info',
                    position: 'top-center'
                });
            }).catch(() => {
            toast.error('Ошибка загрузки', {
                position: 'top-center'
            });
        });
    };

    const handleBackBeforeRun = () => {
        dispatch(clearAnswers());
        navigate('/', {replace: true});
    };

    const handleSubmit = () => {
        const date = new Date();

        let body = {
            date: formattedDate(date),
            ID_Obj: Number(currentLocation?.id),
            result: answers
        };

        if (state.isEmployee) {
            body = {...body, ...currentEmployee};
        }

        saveQuiz({body}).unwrap().then(({status}) => {
            if (status) {
                setSuccessOpen(true);
                dispatch(setEmployee(null));
            } else {
                toast('Ошибка при сохранении', {
                    type: 'error',
                    position: 'top-center'
                });
            }
        });
    };

    const renderVariable = () => {
        switch (currentQuestion?.type?.sType) {
            case 'text':
                return <RenderTextCase
                    list={currentQuestion.list ?? []}
                    answer={answers.find(i => i.id === currentQuestion.id)}
                    files={files}
                    handleSelect={handleSetAnswer}
                    handleFiles={handleFiles}
                />;
            case 'percent':
                return <RenderPercentCase
                    answer={answers.find(i => i.id === currentQuestion.id)}
                    files={files}
                    handleSelect={handleSetAnswer}
                    handleFiles={handleFiles}
                />;
            case 'number':
                return <RenderTextCase
                    list={currentQuestion.list ?? []}
                    answer={answers.find(i => i.id === currentQuestion.id)}
                    files={files}
                    handleSelect={handleSetAnswer}
                    handleFiles={handleFiles}
                />;
            default:
                return <>Empty</>;
        }
    };

    if (!data) {
        return <></>;
    }

    const answerYes = answers.filter(a => a.value === 'да').length;
    const resultPercent = (answerYes / (data?.listQuestions as QuizItem[])?.length * 100).toFixed(0);

    return (
        <MainLayout>
            {isLoading ? <QuizLoader/> : null}
            <Header breadCrumbs={`${currentQuestion?.parent?.name} ${currentEmployee?.KontraName ?? ''}`}/>
            <BackButton text={'Завершить и выйти'} onClick={handleShowModal}/>
            <div className={styles.container}>
                <RenderQuestion
                    question={currentQuestion}
                    count={`вопрос ${questionIndex + 1} из ${data?.listQuestions?.length}:`}>
                    {renderVariable()}
                </RenderQuestion>
            </div>
            <div className={styles.buttons}>
                <div className={styles.buttonsBox}>
                    <Button
                        text={questionIndex === 0 ? 'Назад к выбору объекта' : 'Предыдущий вопрос'}
                        textStyleType={'regular'}
                        style={{
                            borderRadius: '12px',
                            border: `1px solid ${mainColor}`,
                            fontSize: '14px',
                            height: '64px'
                        }}
                        onClick={() => {
                            if (questionIndex === 0) {
                                dispatch(clearAnswers());
                                navigate(-1);
                            } else {
                                handleChangeIndex('back');
                            }
                        }}
                    />
                </div>
                <div className={styles.buttonsBox}>
                    {questionIndex !== (data?.listQuestions?.length as number) - 1 &&
                        <Button
                            text={'Следующий вопрос'}
                            textStyleType={'regular'}
                            style={{
                                borderRadius: '12px',
                                border: `1px solid ${mainColor}`,
                                fontSize: '14px',
                                height: '64px'
                            }}
                            onClick={() => handleChangeIndex('forward')}
                        />}
                </div>
            </div>
            <Modal isOpen={isShow}>
                <Finished
                    isFinish={answers.length === (data?.listQuestions?.length as number)}
                    onCansel={() => {
                        setShow(false);
                        dispatch(clearAnswers());
                        dispatch(setEmployee(null));
                        navigate('/');
                    }}
                    onConfirmDisable={answers.length === 0}
                    onConfirm={() => {
                        setShow(false);
                        handleSubmit();
                    }}
                />
            </Modal>
            <Modal isOpen={errOpen}>
                <ErrorReport
                    onConfirm={() => setErrOpen(false)}
                    onCancel={() => {
                        handleBackBeforeRun();
                        setErrOpen(false);
                    }}/>
            </Modal>
            <Modal isOpen={successOpen}>
                {state.isEmployee ? (
                    <SuccessReport
                        KontraName={currentEmployee?.KontraName ?? ''}
                        percent={`${resultPercent}%`}
                        onConfirm={handleBackBeforeRun}
                    />) : (
                    <Success
                        address={String(currentLocation?.name)}
                        questTitle={state.parentName}
                        percent={`${resultPercent}%`}
                        onConfirm={handleBackBeforeRun}/>
                )}

            </Modal>
        </MainLayout>
    );
};
