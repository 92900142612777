import React, {useState} from 'react';
import AudioPlayer from 'react-h5-audio-player';
import styles from './style.module.scss';

export const PlayerAudio = ({link}: { link: string }) => {
    const [loading, setLoading] = useState<boolean>(false);
    // const [play, setPlay] = useState<boolean>(false);
    //
    // useEffect(() => {
    //     setPlay(true);
    //     return () => {
    //         setPlay(false);
    //     };
    // }, []);

    return (
        <AudioPlayer
            autoPlay={true}
            src={link}
            onLoadStart={() => setLoading(true)}
            footer={<p className={styles.loading}>{loading ? '' : 'загружается...'}</p>}
        />
    );
};
