import React from 'react';
import {CaretDownOutlined, CaretUpOutlined} from '@ant-design/icons';
import styles from '../style.module.scss';

interface SortControlProps {
    isActive: boolean;
    direction: 'desc' | 'asc';
    onPress: (isActive: 'desc' | 'asc') => void;
}

export const SortControl = ({isActive, direction, onPress}: SortControlProps) => {
    return (
        <div className={styles.sort}>
            <button className={styles.sortItem} onClick={() => onPress(direction)}>
                <CaretUpOutlined style={{color: direction === 'asc' && isActive ? 'red' : '#c2c2c2'}}/>
                <CaretDownOutlined style={{color: direction === 'desc' && isActive ? 'red' : '#c2c2c2'}}/>
            </button>
        </div>
    );
};
