import React, {useState} from 'react';
import styles from '../style.module.scss';
import classNames from "classnames";

interface PaginationProps {
    totalPages: number;
    onPageChange: (page: number) => void
}

export const Pagination = ({totalPages, onPageChange}: PaginationProps) => {
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        onPageChange(page);
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        if (totalPages <= 5) {
            // Если totalPages меньше или равно 5, отображаем все страницы
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => handlePageChange(i)}
                        className={currentPage === i
                            ? classNames(styles.button, styles.buttonActive)
                            : styles.button}>
                        {i}
                    </button>
                );
            }
        } else {
            // Если totalPages больше 5, отображаем первые три, последние три и многоточие между ними
            for (let i = 1; i <= 3; i++) {
                pageNumbers.push(
                    <button key={i} onClick={() => handlePageChange(i)}
                            className={currentPage === i
                                ? classNames(styles.button, styles.buttonActive)
                                : styles.button}>
                        {i}
                    </button>
                );
            }
            pageNumbers.push(<span key="ellipsis">...</span>);
            for (let i = totalPages - 2; i <= totalPages; i++) {
                pageNumbers.push(
                    <button key={i} onClick={() => handlePageChange(i)}
                            className={currentPage === i
                                ? classNames(styles.button, styles.buttonActive)
                                : styles.button}>
                        {i}
                    </button>
                );
            }
        }
        return pageNumbers;
    };

    return (
        <div className={styles.pagination}>
            <button
                disabled={currentPage === 1}
                className={styles.button}
                onClick={() => handlePageChange(currentPage - 1)}>
                <i className="fas fa-caret-left"/>
            </button>
            {renderPageNumbers()}
            <button
                disabled={currentPage === totalPages}
                className={styles.button}
                onClick={() => handlePageChange(currentPage + 1)}>
                <i className="fas fa-caret-right"/>
            </button>
        </div>
    );
};
