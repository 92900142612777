import {createSlice} from '@reduxjs/toolkit';
import {InventoryStore} from './types';
import {inventoryApi} from './inventory.api';

export const defaultInventoryForm = {
    Type: 'Insert',
    ID: undefined,
    ID_Device: undefined,
    DevType: undefined,
    Name: undefined,
    Code: undefined,
    Code1S: undefined,
    Rem: undefined,
    DevRem: undefined,
    BalCost: undefined,
    IsActive: undefined,
    State: undefined,
};

const initialState: InventoryStore = {
    list: [],
    currentObj: undefined,
    editObjId: null,
    inventoryForm: defaultInventoryForm,
    createStep: 1
};

const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setCurrentLocationObj: (state, {payload}) => {
            state.currentObj = payload;
        },
        clearInventoryList: (state) => {
            state.list = [];
        },
        setEditId: (state, {payload}) => {
            state.editObjId = payload;
        },
        setInventoryForm: (state, {payload}) => {
            state.inventoryForm = payload;
        },
        changeCreateStep: (state, {payload}) => {
            state.createStep = payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            inventoryApi.endpoints.getDeviceList.matchFulfilled,
            (state, {payload}) => {
                state.list = payload.listDevices;
            }
        );
    }
});

export const {
    setCurrentLocationObj,
    clearInventoryList,
    setEditId,
    setInventoryForm,
    changeCreateStep
} = inventorySlice.actions;
export default inventorySlice.reducer;
