import {api} from '../../../store/api';
import {Employee} from './types';

const employeeApi = api.injectEndpoints({
    endpoints: build => ({
        getEmployee: build.query<Employee[], { idObj: number }>({
            query: ({idObj}) => ({
                url: `/listData/listEmpl.php?idObj=${idObj}`,
                method: 'GET'
            }),
            transformResponse: ({list}) => {
                return list;
            }
        })
    }), overrideExisting: true
});

export const {
    useGetEmployeeQuery
} = employeeApi;
