import {useLazyGetPdfQuery} from '../screens/quiz/store/quiz.api';
import {toast} from 'react-toastify';

export const useShare = (
    {
        screenViewName,
        path
    }: { screenViewName: string, path: string }
): { isFetching: boolean; handleShare: () => void } => {
    const [getFile, {isFetching}] = useLazyGetPdfQuery();
    const handleShare = async () => {
        try {
            const url = path.replace('answerType=1', 'answerType=10');

            const data = await getFile({path: url}).unwrap();
            const blob = new Blob([data], {type: 'application/pdf'});
            const file = new File([blob], `${screenViewName || 'default'}.pdf`, {type: 'application/pdf'});

            if (navigator.share) {
                try {
                    await navigator.share({
                        title: screenViewName || 'Document',
                        files: [file]
                    });
                } catch (error) {
                    toast.error(`Sharing failed: ${JSON.stringify(error, null, 2)}`);
                }
            } else {
                toast.error('Ваш браузер не поддерживает API Share');
            }
        } catch (error: any) {
            toast.error(`Error: ${JSON.stringify(error, null, 2) || 'An unexpected error occurred'}`);
        }
    };

    return {isFetching, handleShare};
};
