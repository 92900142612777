import {createSlice} from "@reduxjs/toolkit";
import {CameraState} from "./types";

const initialState: CameraState = {
    base64: '',
    temporaryDataFiles: null
};

const cameraSlice = createSlice({
    name: 'camera',
    initialState,
    reducers: {
        setBase64: (state, {payload}) => {
            state.base64 = payload;
        },
        setTemporaryDataFile: (state, {payload}) => {
            state.temporaryDataFiles = payload;
        }
    },
});

export const {setBase64, setTemporaryDataFile} = cameraSlice.actions;
export default cameraSlice.reducer;
