export interface RouteOpt {
    id: number;
    path: string;
    routeName: string;
    src: string;
    isDisable: boolean;
}

const home = require('../../assets/images/home-icon.png');
const calendar = require('../../assets/images/calendar.png');
const profile = require('../../assets/images/profile-circle.png');
const setting = require('../../assets/images/setting.png');
const add = require('../../assets/images/add-icon.png');

export const bottomNavigationConfig: RouteOpt[] = [
    {path: '/', id: 1, isDisable: false, routeName: '', src: home},
    {path: '/action-list', id: 2, isDisable: false, routeName: '', src: calendar},
    {path: '/profile', id: 3, isDisable: false, routeName: '', src: profile},
    {path: '/settings', id: 4, isDisable: false, routeName: '', src: setting},
    {path: '/task-create', id: 5, isDisable: false, routeName: '', src: add},
];
