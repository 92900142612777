import React from 'react';
import styles from '../style.module.scss';
import {ImageLayout} from '../../../layouts';
import {Button} from '../../../fields/button';
import {disabledColor, mainColor} from '../../../utils/constants';

const iconFail = require('../../../assets/images/face-fail.png');
const iconSuccess = require('../../../assets/images/face-success.png');

interface FinishedProps {
    isFinish: boolean;
    onConfirmDisable: boolean;
    onCansel: () => void;
    onConfirm: () => void;
}

export const Finished = ({isFinish, onConfirmDisable, onCansel, onConfirm}: FinishedProps) => {
    return (
        <>
            <p className={styles.modalText}>{isFinish ? 'Опрос пройден до конца!' : 'Опрос не пройден до конца!'}</p>
            <ImageLayout
                src={isFinish ? iconSuccess : iconFail}
                imageWidth={64}
                imageHeight={64}
                containerWidth={120}
                containerHeight={120}/>
            {isFinish
                ? <p className={styles.modalText}>Сохранить и выйти.</p>
                : <p className={styles.modalText}>Хотите сохранить опрос <br/>в таком виде?</p>}

            <div className={styles.modalButtons}>
                <div className={isFinish ? styles.modalButtonsBoxFull : styles.modalButtonsBox}>
                    <Button
                        text={'Да'}
                        textStyleType={'regular'}
                        onClick={onConfirm}
                        disabled={onConfirmDisable}
                        style={{
                            border: `1px solid ${onConfirmDisable ? disabledColor : mainColor}`,
                            borderRadius: '12px',
                            fontSize: '14px',
                            height: '44px'
                        }}/>
                </div>
                {!isFinish ? <div className={styles.modalButtonsBox}>
                    <Button
                        text={'Нет'}
                        textStyleType={'regular'}
                        onClick={onCansel}
                        style={{
                            border: `1px solid ${mainColor}`,
                            borderRadius: '12px',
                            fontSize: '14px',
                            height: '44px'
                        }}
                    />
                </div> : null}
            </div>
        </>
    );
};
