import React from 'react';
import styles from './style.module.scss';
import {ImageLayout} from "../../layouts";
import classNames from "classnames";
import {bottomNavigationConfig} from "./bottomNavigationConfig";
import {useLocation, useNavigate} from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const handleNavigate = (path: string) => {
        navigate(path);
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.footer}>
                {bottomNavigationConfig.map(route => (
                    <div key={route.id}
                         className={
                             route.path === pathname
                                 ? classNames(styles.button, styles.buttonActive)
                                 : styles.button
                         }
                         onClick={() => handleNavigate(route.path)}
                    >
                        <ImageLayout
                            src={route.src}
                            imageWidth={route.id === 5 ? 44 : 20}
                            imageHeight={route.id === 5 ? 44 : 20}
                            containerWidth={route.id === 5 ? 44 : 20}
                            containerHeight={route.id === 5 ? 44 : 20}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Footer;
