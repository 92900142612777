import React from 'react';
import {useParams} from "react-router-dom";
import {useGetAllQuestionsQuery} from "../home/store/questions.api";
import {getObjectByIdForm} from "../../helpers";
import {ListMenu} from "../home/store/types";
import {ListButtons} from "../../components/listButtons";

export const CallCenter = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const {data} = useGetAllQuestionsQuery();
    const buttonVariable = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    return <ListButtons title={'Колл центр'} buttons={buttonVariable?.children ?? []} handleClick={item => {
    }}/>;
};
