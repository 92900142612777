export const removeEmptyValues = (obj: {[key: string]: any}) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmptyValues(obj[key]);
    } else if (obj[key] == null || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};

