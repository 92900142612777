import React from 'react';

export const HomeIcon = ({color = '#000000'}: { color: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.3753 2.21913C11.7406 1.92696 12.2595 1.92696 12.6247 2.21913L22.6247 10.2191C23.056 10.5641 23.1259 11.1934 22.7809 11.6247C22.4359 12.056 21.8066 12.1259 21.3753 11.7809L20.5 11.0806V21C20.5 21.5523 20.0523 22 19.5 22H4.50004C3.94775 22 3.50004 21.5523 3.50004 21V11.0806L2.62473 11.7809C2.19347 12.1259 1.56418 12.056 1.21917 11.6247C0.874158 11.1934 0.94408 10.5641 1.37534 10.2191L11.3753 2.21913ZM9.5 13.5C8.94772 13.5 8.5 13.9477 8.5 14.5V20H15.5V14.5C15.5 13.9477 15.0523 13.5 14.5 13.5H9.5Z"
                  fill={color} fillOpacity="0.85"/>
        </svg>
    );
};
