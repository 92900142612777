import React, {useState} from 'react';
import styles from "../../taskCreate/style.module.scss";
import {Button} from "../../../fields/button";
import {mainColor} from "../../../utils/constants";
import {Vote} from "../state/types";
import classNames from "classnames";
import {useFinancialConfirmMutation} from "../state/financial.api";
import {TaskType} from "../../taskCreate/store/types";

const successIcon = require('../../../assets/images/signature/succses.png');
const rejectIcon = require('../../../assets/images/signature/reject.png');
const questionIcon = require('../../../assets/images/signature/question.png');

const buttonSubmit = ['Подтвердить', 'Отложить', 'Отклонить'];

function renderIconSource(type: number) {
    if (type < 0) {
        return rejectIcon;
    }
    if (type > 0) {
        return successIcon;
    }
    return questionIcon;
}

interface VotesProps {
    votes: Vote[];
    ID_App: number;
    onChange: () => void;
}

export const Votes = (
    {
        votes,
        ID_App,
        onChange
    }: VotesProps
) => {
    const [financialConfirm] = useFinancialConfirmMutation();
    const [isIndex, setIndex] = useState<number>(1);
    const [voteType, setVoteType] = useState<TaskType.Accept | TaskType.Defer | TaskType.Reject>(TaskType.Accept);

    const handleSwitchType = (index: number) => {
        setIndex(index);
        switch (index) {
            case 1:
                return setVoteType(TaskType.Defer);
            case 2:
                return setVoteType(TaskType.Reject);
            default:
                return setVoteType(TaskType.Accept);
        }
    };

    const submit = () => {
        financialConfirm({
            body: {
                Type: voteType, listIDApp: [ID_App]
            }
        }).unwrap()
            .then(() => onChange());
    };

    return (
        <>
            <div className={styles.signature}>
                {votes?.map((i, index) => (
                    <div key={index} className={styles.signatureItem}>
                        <div className={styles.signatureAvatar}>
                            <span className={styles.signatureAvatarText}>{i.name}</span>
                        </div>
                        <div className={styles.icon}>
                            <img src={renderIconSource(i.value)} alt={'запись'}/>
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.container}>
                {buttonSubmit.map((button, index) =>
                    <button
                        key={index}
                        className={isIndex === index ? classNames(styles.button, styles.buttonActive) : styles.button}
                        onClick={() => handleSwitchType(index)}
                    >{button}</button>)}
            </div>
            <div className={styles.bottomWrapper}>
                <Button
                    text={'Сохранить'}
                    textStyleType={'semiBold'}
                    style={{
                        color: mainColor,
                        fontSize: '16px',
                        borderRadius: '12px',
                        border: `1px solid ${mainColor}`
                    }}
                    onClick={submit}
                />
            </div>
        </>
    );
};
