import React, {useEffect, useState} from 'react';
import {useGetConditionsMutation, useSaveConditionsMutation} from "../store/task.api";
import {fromJsonToUrlencoded} from "../../../helpers";
import {CustomCollapse} from "../components";
import styles from '../style.module.scss';
import {DatePicker, Input, Select} from "antd";
import dayjs from "dayjs";
import {ICondition, ITask} from "../store/types";
import {toast} from "react-toastify";
import ReactFileReader from "react-file-reader";
import {CloudUploadOutlined} from '@ant-design/icons';
import AppLoader from "../../../components/appLoader";
import {Button} from "../../../fields/button";
import {mainColor} from "../../../utils/constants";

interface ConditionsProps {
    isEdit: boolean;
    task: ITask | undefined,
}

export const Conditions = ({isEdit, task}: ConditionsProps) => {
    const [getConditions, {isLoading}] = useGetConditionsMutation();
    const [appFields, setAppFields] = useState<ICondition[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [saveConditions] = useSaveConditionsMutation();
    const [loading, setLoading] = useState<boolean>(false);
 
    useEffect(() => {
        if (task) {
            getConditions({body: fromJsonToUrlencoded({data: {FApps: task.ID}})})
                .unwrap()
                .then((data) => setAppFields(data.appFields))
                .catch(() => {
                    toast(`Ошибка получения данных`, {
                        type: 'error',
                        position: 'top-center'
                    });
                });
        }

    }, []);


    const renderSelect = (item: ICondition) => {

        const opt = item?.FieldList?.split(';').map(i => ({value: i, label: i}));
        return <Select
            className={styles.select}
            value={item?.FieldVal}
            options={opt}
            onChange={value => {
                setAppFields(prevState => {
                    return prevState.map(i => {
                        if (i.ID_Field === item.ID_Field) {
                            return {...i, FieldVal: value};
                        }
                        return i;
                    });
                });
            }}
        />;
    };

    const changeItemById = (value: string, ID_Field: number) => {
        setAppFields(prevState => {
            return prevState.map(item => {
                if (item.ID_Field === ID_Field) {
                    return {...item, FieldVal: value};
                }
                return item;
            });
        });
    };

    const handleFiles = (fileItems: any, ID_Field: number) => {
        setFiles([...files, fileItems[0]]);
        setAppFields(prevState => {
            return prevState.map(item => {
                if (item.ID_Field === ID_Field) {
                    return {...item, FileNameOrig: fileItems[0].name};
                }
                return item;
            });
        });
    };

    const submit = () => {
        const form = new FormData();
        const saveData = appFields
            .map(i => ({
                ID_Type: String(i.ID_Type),
                ID_SectField: String(i.ID_SectField),
                ID_App: String(i.ID_App),
                Val: i.FieldVal,
                FileNameOrig: i.FileNameOrig
            }));

        form.append('ID_Form', '115');
        form.append('saveData', JSON.stringify(saveData));
        form.append('ID_Sect', `${task?.ID_Sect}`);
        for (const file of files) {
            form.append('load_files[]', file);
        }
        setLoading(true);
        saveConditions({body: form}).unwrap()
            .then(() => toast(`Доп.условия сохранены`, {
                type: 'info',
                position: 'top-center'
            }))
            .catch(() => toast(`Ошибка сохранения`, {
                type: 'error',
                position: 'top-center'
            }))
            .finally(() => setLoading(false));
    };
    if (isLoading) {
        return <AppLoader isFull={false}/>;
    }

    return (
        <>
            {loading ? <div className={styles.loadingWrapper}>
                <p className={styles.loadingText}>загрузка...</p>
            </div> : null}
            <div className={styles.collapseWrap}>
                {appFields.map((item) =>
                    <CustomCollapse
                        key={item.ID_Field}
                        title={item.FieldName}>
                        {item.ID_Type === 1
                            ? <Input
                                type={'text'}
                                value={item.FieldVal ? String(item.FieldVal) : ''}
                                onChange={event => {
                                    changeItemById(event.target.value, item.ID_Field);
                                }}
                                placeholder={'введите текст'}
                            />
                            : null}
                        {item.ID_Type === 2
                            ? <Input
                                type={'number'}
                                value={item.FieldVal ? Number(item.FieldVal) : ''}
                                onChange={event => {
                                    changeItemById(event.target.value, item.ID_Field);
                                }}
                                placeholder={'введите число'}
                            />
                            : null}
                        {item.ID_Type === 3
                            ? <DatePicker
                                placeholder={'дата'}
                                defaultValue={item.FieldVal ? dayjs(item.FieldVal) : undefined}
                                onChange={(date, dateString) => {
                                    changeItemById(dateString as string, item.ID_Field);
                                }}
                                className={styles.picker}
                            />
                            : null}
                        {item.ID_Type === 4 ? renderSelect(item) : null}
                        {item.ID_Type === 5
                            ?
                            <div className={styles.conditionFile}>
                                <div className={styles.conditionFileBody}>
                                    <span className={styles.conditionFileText}>файл:</span>
                                    <span className={styles.conditionFileText}>{
                                        item.FileNameOrig
                                    }</span>
                                </div>
                                <ReactFileReader
                                    fileTypes={[".png", ".jpg", ".jpeg", ".pdf"]}
                                    multiple={false}
                                    handleFiles={async (files: File) => {
                                        handleFiles(files, item.ID_Field);
                                    }}>
                                    <button className={styles.conditionFileButton}
                                            onClick={
                                                async (e) => {
                                                    await sessionStorage.setItem('isFile', 'OK');
                                                }
                                            }>
                                        <CloudUploadOutlined/>
                                    </button>
                                </ReactFileReader>
                            </div>
                            : null}
                    </CustomCollapse>)}
            </div>
            {isEdit ? <div className={styles.bottomWrapper}>
                <Button
                    text={'Сохранить'}
                    textStyleType={'semiBold'}
                    style={{
                        color: mainColor,
                        fontSize: '16px',
                        borderRadius: '12px',
                        border: `1px solid ${mainColor}`
                    }}
                    onClick={submit}
                />
            </div> : null}
        </>
    );
};
