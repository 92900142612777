import {createSlice} from '@reduxjs/toolkit';
import {TableSortColumn, TableState} from './types';

export const defaultSortColumn: TableSortColumn = {key: '', direction: 'desc'};
const initialState: TableState = {
    sortColumn: defaultSortColumn
};

const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        setSortColumn: (state, {payload}) => {
            state.sortColumn = payload;
        }
    }
});

export const {setSortColumn} = tableSlice.actions;
export default tableSlice.reducer;
