import {createSlice} from "@reduxjs/toolkit";
import {defaultFormData, FinancialMenuState} from "./types";

const initialState: FinancialMenuState = {
    quantity: [],
    createdFinancial: defaultFormData
};

const financialSlice = createSlice({
    name: 'financialMenu',
    initialState,
    reducers: {
        setFinancialQuantity: (state, {payload}) => {
            if (!state.quantity.some(i => i.mode === payload.mode)) {
                state.quantity.push(payload);
            } else {
                state.quantity = state.quantity.map(i => (i.mode === payload.mode ? payload : i));
            }
        },
        clearFinancialQuantity: (state) => {
            state.quantity = [];
        },
        setCreatedFinancial: (state, {payload}) => {
            state.createdFinancial = payload;
        }
    },
});

export const {setFinancialQuantity, clearFinancialQuantity, setCreatedFinancial} = financialSlice.actions;

export default financialSlice.reducer;
