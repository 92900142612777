import React, {useLayoutEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate} from 'react-router-dom';
import {QrcodeOutlined} from '@ant-design/icons';
import styles from './style.module.scss';
import {Input} from 'antd';
import {Button} from '../../fields/button';
import {greyColor, mainColor, validState} from '../../utils/constants';
import {useLazyGetDeviceListQuery} from './store/inventory.api';
import AppLoader from '../../components/appLoader';
import {Modal} from '../../components/modal';
import {InventoryItem} from './store/types';
import {defaultInventoryForm, setInventoryForm} from './store/inventory.slice';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {extractValueFromString} from '../../helpers';
import {Scanner} from '@yudiel/react-qr-scanner';

export const scannerFormats: BarcodeFormat[] = [
    'qr_code',
    'itf',
    'ean_8',
    'ean_13',
    'codabar',
    'code_93',
    'code_39',
    'code_128',
    'upc_a',
    'upc_e',
];

const buttonItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

export const ScannerPage = () => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [heightWindow, setHWindow] = useState<number>(320);
    const [isInput, setIsInput] = useState<boolean>(false);
    const [inputCode, setInputCode] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);


    const [getDeviceList, {isLoading}] = useLazyGetDeviceListQuery();

    const {inventoryForm} = useAppSelector(state => state.inventory);

    useLayoutEffect(() => {
        if (window) {
            const width = window.innerWidth;
            setHWindow(width);
        }

    }, []);

    const handleOnScan = (result: any) => {
        if (result) {
            const value = result[0].rawValue;
            setInputCode(String(value));

            getDeviceList({params: `FCodes=${value}`})
                .unwrap()
                .then(({listDevices}) => isDevice(listDevices, String(value)));
        }
    };

    const handleSubmit = () => {
        getDeviceList({params: `FCodes=${inputCode}`})
            .unwrap()
            .then(({listDevices}) => isDevice(listDevices, String(inputCode)));
    };

    const isDevice = (listDevices: InventoryItem[], code: string) => {

        if (listDevices.length === 0) {
            setIsOpen(true);
        } else {
            const idForm = extractValueFromString(search, 'idForm');
            const item = listDevices.find(d => String(d.DevCode) === code) as InventoryItem;

            if (item) {
                dispatch(setInventoryForm({
                    Type: 'Update',
                    ID: String(item.ID),
                    ID_Device: String(item.ID_Device),
                    DevType: item.DevType,
                    Name: item.DevName,
                    Code: item.DevCode,
                    Code1S: item.DevCode1S ?? '',
                    Rem: item.Rem !== null ? item.Rem : undefined,
                    BalCost: item.BalCost ?? '',
                    State: validState.includes(item.State) ? item.State : 'Прочее'
                }));
                navigate(`/inventory-check`, {state: {idForm}});
            }

        }
    };

    const handleAddDevice = () => {
        dispatch(setInventoryForm({...inventoryForm, Code: inputCode}));
        navigate(`/inventory-create`);
    };

    const handleBack = () => {
        dispatch(setInventoryForm(defaultInventoryForm));
        navigate(-1);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Сканер'}/>
            <BackButton text={'Назад в инвентаризацию'} onClick={handleBack}/>
            {isLoading
                ? <AppLoader isFull={false}/>
                : isInput
                    ? (
                        <div className={styles.inputCode} style={{
                            width: `${heightWindow}px`,
                            height: `${heightWindow}px`,
                        }}>
                            <Input
                                variant={'outlined'}
                                placeholder={'введите код'}
                                className={styles.input}
                                value={inputCode}
                                onChange={(event) => setInputCode(event.target.value)}
                            />
                            <div className={styles.inputCodeButton}>
                                <Button
                                    text={'Отправить'}
                                    textStyleType={'semiBold'}
                                    style={{
                                        color: mainColor,
                                        fontSize: '16px'
                                    }}
                                    onClick={handleSubmit}
                                />
                            </div>
                        </div>
                    )
                    :
                    (

                        <div className={styles.scannerWrapper}>
                            <Scanner
                                classNames={{
                                    container: 'scanner-frame',
                                }}
                                formats={scannerFormats}
                                onScan={(barcode) => handleOnScan(barcode)}
                            />

                        </div>
                    )
                // <Scanner
                //     styles={{
                //         container: {
                //             backgroundColor: '#000',
                //             height: `${heightWindow}px`,
                //             marginTop: '20px'
                //         },
                //     }}
                //     formats={scannerFormats}
                //     onScan={handleOnScan}
                // />
            }
            <div className={styles.scannerControl}>
                <div className={styles.buttonWrap}>
                    <div className={styles.toggle} onClick={() => setIsInput(!isInput)}>
                        {
                            isInput
                                ? <QrcodeOutlined style={{fontSize: '100px'}}/>
                                : buttonItems.map(item => <span key={item} className={styles.toggleItem}>{item}</span>)
                        }

                    </div>
                </div>
            </div>
            <Modal isOpen={isOpen}>
                <div className={styles.deviceNotFound}>
                    <p className={styles.deviceNotFoundText}>Устройство не найдено.</p>
                    <p className={styles.deviceNotFoundText}>Добавить?</p>
                    <div className={styles.deviceNotFoundButtons}>
                        <Button
                            text={'нет'}
                            textStyleType={'regular'}
                            style={{border: `1px  solid ${greyColor}`, borderRadius: '8px', marginRight: '3px'}}
                            onClick={() => setIsOpen(false)}
                        />
                        <Button
                            text={'да'}
                            textStyleType={'regular'}
                            style={{border: `1px  solid ${mainColor}`, borderRadius: '8px', marginLeft: '3px'}}
                            onClick={handleAddDevice}
                        />
                    </div>
                </div>
            </Modal>
        </MainLayout>
    );
};
