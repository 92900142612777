import React from 'react';

export const ZoomOut = ({color = '#000000'}: { color: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.5 1C5.25332 1 1 5.25332 1 10.5C1 15.7467 5.25332 20 10.5 20C12.7631 20 14.8415 19.2086 16.4733 17.8875L20.1464 21.5606C20.5369 21.9511 21.1701 21.9511 21.5606 21.5606C21.9511 21.1701 21.9511 20.5369 21.5606 20.1464L17.8875 16.4733C19.2086 14.8415 20 12.7631 20 10.5C20 5.25332 15.7467 1 10.5 1ZM7.5 9.5C6.94772 9.5 6.5 9.94771 6.5 10.5C6.5 11.0523 6.94772 11.5 7.5 11.5H13.5C14.0523 11.5 14.5 11.0523 14.5 10.5C14.5 9.94771 14.0523 9.5 13.5 9.5H7.5Z"
                  fill={color} fillOpacity="0.85"/>
        </svg>
    );
};
