import React from 'react';
import {useForm} from 'react-hook-form';
import styles from '../style.module.scss';
import {Input} from '../../../fields/input';
import {Button} from '../../../fields/button';
import {IUser} from '../store/types';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

interface IForm {
    lastName: string;
    name?: string | null;
    surName?: string | null;
    email?: string | null;
    phone?: string | null;
    inn?: number | null;
}

const profileSchema = yup.object().shape({
    lastName: yup.string().required('Обязательное поле'),
    name: yup.mixed(),
    surName: yup.mixed(),
    email: yup.mixed(),
    phone: yup.mixed(),
    inn: yup.mixed(),
});

interface EditProfileProps {
    profile?: IUser;
}

export const EditProfile = ({profile}: EditProfileProps) => {


    const {control, watch} = useForm<IForm>({
        mode: 'onChange',
        resolver: yupResolver(profileSchema),
        defaultValues: {
            lastName: profile?.name?.split(' ')[0] ?? '',
            name: profile?.name?.split(' ')[1] ?? '',
            surName: null,
            email: profile?.email,
            phone: profile?.phone,
            inn: profile?.inn
        }
    });


    return (
        <>
            <div className={styles.form}>
                <div className={styles.formCol}>
                    <Input
                        placeholder={'Фамилия'}
                        type={'text'}
                        fieldName={'lastName'}
                        defaultValue={watch('lastName')}
                        control={control}
                        onChange={() => {
                        }}
                    />
                </div>
                <div className={styles.formCol}>
                    <Input
                        placeholder={'E-mail'}
                        type={'text'}
                        fieldName={'email'}
                        defaultValue={watch('email') ?? ''}
                        control={control}
                        onChange={() => {
                        }}/>
                </div>
                <div className={styles.formCol}>
                    <Input
                        placeholder={'Имя'}
                        type={'text'}
                        fieldName={'name'}
                        defaultValue={watch('name') ?? ''}
                        control={control}
                        onChange={() => {
                        }}/>
                </div>
                <div className={styles.formCol}>
                    <Input
                        placeholder={'Тел'}
                        type={'text'}
                        fieldName={'phone'}
                        defaultValue={watch('phone') ?? ''}
                        control={control}
                        onChange={() => {
                        }}/>
                </div>
                <div className={styles.formCol}>
                    <Input
                        placeholder={'Отчество'}
                        type={'text'}
                        fieldName={'surName'}
                        defaultValue={watch('surName') ?? ''}
                        control={control}
                        onChange={() => {
                        }}/>
                </div>
                <div className={styles.formCol}>
                    <Input
                        placeholder={'ИНН'}
                        type={'text'}
                        fieldName={'inn'}
                        defaultValue={watch('inn') ?? ''}
                        control={control}
                        onChange={() => {
                        }}/>
                </div>

            </div>
            <div className={styles.buttons}>
                <div className={styles.buttonsItem}>
                    <Button
                        text={'Сохранить изменения'}
                        textStyleType={'semiBold'}
                        style={{}}
                    />
                </div>
            </div>
        </>
    );
};
