import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './style.module.scss';
import {Button} from '../../fields/button';
import {mainColor} from '../../utils/constants';
import {TextareaControl} from '../../fields/textarea';
import {Spin} from 'antd';
import {EditOutlined, HistoryOutlined, LoadingOutlined} from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {
    useActionDeviceCheckMutation,
    useLazyGetDeviceFilesQuery,
    useUploadDeviceFileMutation
} from './store/inventory.api';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {IInventory} from './store/types';
import {setInventoryForm} from './store/inventory.slice';
import {fromJsonToUrlencoded} from '../../helpers';
import {DeviseState} from './components';
import {FileFromBack} from '../quiz/store/types';
import {UploadFiles} from '../../components/uploadFiles';
import {toast} from 'react-toastify';

const schema = yup.object().shape({
    Type: yup.mixed().required().default('Insert'),
    ID: yup.mixed(),
    ID_Device: yup.mixed(),
    DevType: yup.mixed().required('Обязательно заполнить'),
    Name: yup.mixed().required('Обязательно заполнить'),
    Code: yup.mixed().required('Обязательно заполнить'),
    Code1S: yup.mixed(),
    Rem: yup.mixed(),
    BalCost: yup.mixed(),
    State: yup.mixed(),
});

export const InventoryCheckForm = () => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [actionDeviceCheck, {isLoading}] = useActionDeviceCheckMutation();

    const [uploadFile, {isLoading: loadingFile}] = useUploadDeviceFileMutation();
    const [deviceFiles] = useLazyGetDeviceFilesQuery();

    const {currentObj, inventoryForm} = useAppSelector(state => state.inventory);

    const {setValue, watch, control, handleSubmit, clearErrors, reset} = useForm<any>({
        mode: 'onChange',
        defaultValues: inventoryForm,
        resolver: yupResolver(schema)
    });

    const [formData, setFormData] = useState<FormData | null>(null);
    const [backFiles, setFiles] = useState<FileFromBack[]>([]);
    const [uploadedCount, setUploadedCount] = useState<string[]>([]);
    const [location, setLocation] = useState({latitude: 0, longitude: 0});
    const [errorLocation, setErrorLocation] = useState<any>(null);

    useEffect(() => {
        deviceFiles({ID_Tab: String(inventoryForm.ID_Device)}).unwrap()
            .then(({filesList}) => {
                const files = filesList.map((f: any) => (
                    {
                        id: f.ID,
                        fileName: f.NameOrig,
                        origName: f.NameOrig,
                        url: f.url
                    }
                )) as FileFromBack[];
                setFiles(files);
                setUploadedCount(files.map(i => i.id));
            }).catch(() => {
        });

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handleSuccessPosition, handleErrorPosition);
        }

        return () => reset();
    }, []);


    const handleSuccessPosition = (position: GeolocationPosition) => {
        const {latitude, longitude} = position.coords;
        setLocation({latitude, longitude});
        setErrorLocation(null);
    };

    const handleErrorPosition = (error: any) => {
        setErrorLocation(error.message);
    };

    const handleBack = () => {
        reset();
        navigate(-1);
    };

    const handleSetValue = (field: keyof IInventory, value: string) => {
        clearErrors(field);
        setValue(field, value.length === 0 ? undefined : value);
        dispatch(setInventoryForm({...inventoryForm, [field]: value}));
    };

    const onSubmit = async (values: IInventory) => {
        const obj = {
            Type: values.Type,
            ID: values.ID,
            ID_Device: values.ID_Device,
            ID_Obj: currentObj?.id,
            State: values.State,
            Rem: values.Rem,
            EstimCost: values.BalCost,
            Latitude: location.latitude,
            Longitude: location.longitude
        };

        if (backFiles.length > uploadedCount.length && formData) {
            formData.append('ID_Tab', String(values.ID_Device));
            uploadFile({formData})
                .unwrap()
                .then(() => sendData(obj))
                .catch(() => {
                });
        } else {
            sendData(obj);
        }
    };

    const sendData = (initSave: any) => {
        actionDeviceCheck({body: fromJsonToUrlencoded({data: initSave})})
            .unwrap()
            .then(({status, info}) => {
                if (status) {
                    dispatch(setInventoryForm({
                        ...inventoryForm,
                        Rem: info.Rem,
                        State: info.State
                    }));
                    toast.info('Успешно изменено', {
                        onClose: () => handleBack()
                    });
                }
            })
            .catch(() => toast.error('Ошибка проверки устройства'));
    };

    const handleEdit = () => {
        navigate(`/inventory-view`, {state: {idForm: state.idForm}});
    };

    return (
        <MainLayout>
            <Header
                breadCrumbs={watch('ID')
                    ? `Редактировать устройство в ${currentObj?.name}`
                    : `Добавить устройство в ${currentObj?.name}`}
            />
            <BackButton
                text={'Назад'}
                onClick={handleBack}
            />
            <div className={styles.container}>
                <div className={styles.inventoryCard}>
                    <div className={styles.inventoryCardHead}>
                        <span className={styles.inventoryCardHeadText}>{watch('DevType')}</span>
                        <span className={styles.inventoryCardHeadText}>ID устройства {watch('ID_Device')}</span>
                    </div>

                    <div className={styles.inventoryCardBody}>
                        <p className={styles.inventoryCardName}>{watch('Name')}</p>
                    </div>
                    <div className={styles.inventoryCardFooter}>
                     <span className={styles.inventoryCardFooterText}>
                                        <span>место:</span>{currentObj?.name}</span>
                        <div className={styles.control}>
                            <button className={styles.controlButton} onClick={handleEdit}><EditOutlined/></button>
                            <button className={styles.controlButton}><HistoryOutlined/></button>
                        </div>
                    </div>
                </div>
            </div>

            <form className={styles.inventoryForm}>
                <div className={styles.inventoryFormBox}>
                    <label className={styles.inventoryFormLabel}>Примечание проверки</label>
                    <TextareaControl
                        fieldName={'Rem'}
                        control={control}
                        defaultValue={watch('Rem')}
                        onChange={value => handleSetValue('Rem', String(value))}/>
                </div>
                {errorLocation !== null
                    ? (
                        <div className={styles.inventoryFormBox}>
                            <p className={styles.errLocationText}>
                                Для того чтобы менять статус устройства, разрешите, в настройках телефона, использование
                                геолокации.
                            </p>
                        </div>
                    ) : (
                        <>
                            <div className={styles.inventoryFormBox}>
                                <UploadFiles
                                    isLoading={loadingFile}
                                    appendObj={null}
                                    viewFiles={backFiles}
                                    handleBase64={baseFile => {
                                        setFiles([...backFiles, baseFile]);
                                    }}
                                    handleCallback={setFormData}
                                />
                            </div>
                            <DeviseState state={watch('State')} onChange={state => setValue('State', state)}/>
                            <div className={styles.inventoryFormBox}>
                                {isLoading ?
                                    <Spin indicator={<LoadingOutlined spin style={{color: '#fff'}}/>} size="large"
                                          style={{height: '54px'}}/> :
                                    <Button
                                        text={'Фиксировать'}
                                        textStyleType={'semiBold'}
                                        style={{
                                            border: `1px solid ${mainColor}`,
                                            borderRadius: '8px',
                                            width: '100%',
                                            height: '54px'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleSubmit(onSubmit)();
                                        }}
                                    />}
                            </div>
                        </>
                    )}

            </form>

        </MainLayout>
    );
};
