import React, {useEffect, useRef, useState} from 'react';
import styles from './style.module.scss';
import {toast} from "react-toastify";
import ReactFileReader from "react-file-reader";
import InfiniteScroll from 'react-infinite-scroll-component';

import dayjs from "dayjs";
import {Message} from "./store/types";
import {useGetMessagesQuery, useSendMessageMutation} from "./store/chat.api";
import {fromJsonToUrlencoded} from "../../helpers";
import {useUploadFileMutation} from "../../screens/quiz/store/quiz.api";
import {useAppSelector} from "../../hooks/app.hooks";
import {FileFromBack} from "../../screens/quiz/store/types";
import {MessageItem} from "./MessageItem";
import AppLoader from "../appLoader";

const addIcon = require('../../assets/images/messages/add.png');
const sendIcon = require('../../assets/images/messages/send.png');

export const docxTypes = ['docx', 'xlsx'];

interface ChatRoomProps {
    ID_App: string
}

function isToday(dateString: string) {

    const today = dayjs().startOf('day'); // Получаем текущую дату
    const givenDate = dayjs(dateString, 'DD.MM.YYYY').startOf('day'); // Преобразуем заданную дату в объект dayjs

    return today.isSame(givenDate, 'day'); // Сравниваем дни
}

function groupMessagesByDate(messages: Message[]) {
    let groupedMessages: any[] = [];

    for (const msg of messages) {
        const dateStr = msg.DI_str.split(' ')[0];
        if (isToday(dateStr)) {
            groupedMessages = [...groupedMessages, msg];
        } else {
            if (!groupedMessages.some(i => i === 'Сегодня')) {
                groupedMessages = [...groupedMessages, 'Сегодня'];
            }
            if (groupedMessages.some(i => i === dateStr)) {
                groupedMessages = [...groupedMessages, msg];
            } else {
                groupedMessages = [...groupedMessages, msg, dateStr];
            }
        }
    }

    return groupedMessages;
}

export const ChatRoom = ({ID_App}: ChatRoomProps) => {
    const listRef = useRef<any>(null);

    const [pollingEnabled, setPollingEnabled] = useState(true);
    const {refetch, isLoading} = useGetMessagesQuery(
        {body: fromJsonToUrlencoded({data: {ID_App: ID_App, chatMode: 1}})},
        {pollingInterval: pollingEnabled ? 2000 : undefined}
    );

    const [uploadFile] = useUploadFileMutation();
    const [sendMessage] = useSendMessageMutation();

    const {messages} = useAppSelector(state => state.chat);

    const [fileAttachment, setFiles] = useState<FileFromBack | null>(null);

    const [note, setNote] = useState<string>('');

    useEffect(() => {
        const handleScroll = () => {
            if (listRef.current) {
                const {scrollTop, scrollHeight, clientHeight} = listRef.current;
                const isAtBottom = scrollHeight - scrollTop === clientHeight;
                if (isAtBottom) {
                    setPollingEnabled(true);
                } else {
                    setPollingEnabled(false);
                }
            }
        };

        if (listRef && listRef.current) {
            listRef.current.addEventListener('scroll', handleScroll);
        }
        refetch();
        return () => {
            if (listRef && listRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                listRef.current.removeEventListener('scroll', handleScroll);
            }
            // dispatch(clearMessages())
        };
    }, []);


    useEffect(() => {
        if (listRef && listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight;
        }
    }, [messages]);

    const handleFiles = (files: any) => {
        const {fileList} = files;
        const formData = new FormData();
        formData.append('load_file', fileList[0], fileList[0]?.origName);
        formData.append('type', 'task');

        uploadFile({file: formData})
            .unwrap()
            .then(async (result) => {
                await sessionStorage.removeItem('isFile');
                setFiles({
                    id: result.id,
                    fileName: result.fileName,
                    origName: result.origName
                });

                toast(`Загружен файл: ${result.origName}`, {
                    type: 'info',
                    position: 'top-center'
                });
            }).catch(() => {
            toast.error('Ошибка загрузки', {
                position: 'top-center'
            });
        });
    };

    const handleSendMessage = () => {
        if (note === '' && fileAttachment === null) {
            return toast(`Добавьте файл или текст`, {
                type: 'error',
                position: 'top-center'
            });
        }

        const dataForm = new FormData();
        dataForm.append('Type', 'Insert');
        dataForm.append('ID_App', `${ID_App}`);
        dataForm.append('ID_Form', '115');
        dataForm.append('SelectRow', '0');
        dataForm.append('Note', note);

        if (fileAttachment) {
            dataForm.append(`files[0]`, JSON.stringify(fileAttachment));
        }
        sendMessage({body: dataForm})
            .unwrap()
            .then(() => {
                setNote('');
                clearAttachment();
                refetch();
            }).catch((e) => {
            toast(`Ошибка при добавлении файлов`, {
                type: 'error',
                position: 'top-center'
            });
        });
    };

    const clearAttachment = () => {
        setFiles(null);
    };

    const fetchMoreData = () => {
    };

    const groupByDate = groupMessagesByDate(messages ?? []);

    return (
        <>
            {isLoading
                ? <AppLoader isFull={false}/>
                : <>
                    <div ref={listRef} className={styles.chat} id={'chat'}>
                        {groupByDate.length > 0
                            ? <InfiniteScroll
                                dataLength={0}
                                next={fetchMoreData}
                                className={styles.listMessage}
                                inverse={true}
                                hasMore={true}
                                loader={<></>}
                                scrollableTarget={listRef?.current}
                            >

                                {groupByDate.map((msg, index) => {
                                    if (typeof msg === 'string') {
                                        return <span key={index} className={styles.separator}>{msg}</span>;
                                    }

                                    return (<MessageItem key={msg.ID} msg={msg}/>);
                                })}
                            </InfiniteScroll> : null}
                    </div>
                    <div className={styles.control}>

                        {fileAttachment?.origName
                            ? <div className={styles.controlAttachment}>
                                <span className={styles.close} onClick={clearAttachment}/>
                                <p>{fileAttachment?.origName}</p>
                            </div> : null
                        }
                        <div className={styles.inputBlock}>
                            <ReactFileReader
                                fileTypes={[".png", ".jpg", ".jpeg", ".pdf", ".docx", ".xlsx"]}
                                multiple={false}
                                base64
                                handleFiles={(files: any) => {
                                    handleFiles(files);
                                }}>
                                <button className={styles.inputBlockButton}
                                        onClick={
                                            async (e) => {
                                                await sessionStorage.setItem('isFile', 'OK');
                                            }
                                        }
                                >
                                    <img src={addIcon} alt={'button add'}/>
                                </button>
                            </ReactFileReader>
                            <div className={styles.inputBlockInput}>
                                <input className={styles.inputText} value={note}
                                       onChange={event => setNote(event.target.value)}/>
                            </div>
                            <button className={styles.inputBlockButton} onClick={handleSendMessage}>
                                <img src={sendIcon} alt={'button send'}/>
                            </button>
                        </div>
                    </div>
                </>}
        </>
    );
};
