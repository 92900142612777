import {createSlice} from '@reduxjs/toolkit';
import {CallState, FilterCall} from './types';
import dayjs from 'dayjs';

const today = dayjs();
const monthAgo = dayjs().subtract(3, 'day');

export const defaultCallFilter: FilterCall = {
    all: true,
    out: false,
    in: false,
    missed: false,
    success: false,
    noanswer: false
};

const initialState: CallState = {
    filter: defaultCallFilter,
    dateStart: monthAgo.format('YYYY-MM-DD'),
    dateFinish: today.format('YYYY-MM-DD'),
    FObjects: '0'
};

const callSlice = createSlice({
    name: 'call',
    initialState,
    reducers: {
        setFilterCall: (state, {payload}) => {
            state.filter = payload;
        },
        setObj: (state, {payload}) => {
            state.FObjects = payload;
        },
        setChangeDate: (state, {payload}: { payload: { dateStart: string, dateFinish: string } }) => {
            state.dateStart = payload.dateStart;
            state.dateFinish = payload.dateFinish;
        }
    }
});

export const {setFilterCall, setObj, setChangeDate} = callSlice.actions;
export default callSlice.reducer;
