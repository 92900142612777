import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAppSelector} from '../hooks/app.hooks';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundary from '../ErrorBoundary';

export const PrivateRouteMain = () => {
    const {isAuth} = useAppSelector(state => state.auth);

    return isAuth
        ? <ErrorBoundary>
            <Outlet/>
        </ErrorBoundary>
        : <Navigate to={'/login'} replace={true}/>;
};
