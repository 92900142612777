import React from 'react';
import {UnmountClosed} from 'react-collapse';
import styles from '../style.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import {DatePicker, TimeRangePickerProps} from 'antd';
import dayjs from 'dayjs';
import {SwitcherTab} from '../../../components/switcherTab';

const {RangePicker} = DatePicker;

const rangePresets: TimeRangePickerProps['presets'] = [
    {label: 'Пос нед', value: [dayjs().add(-7, 'd'), dayjs()]},
    {label: 'Пос мес', value: [dayjs().add(-14, 'd'), dayjs()]},
];

interface FilterProps {
    isCollapsed: boolean;
    applyFilter: () => void;
    clearFilter: () => void;
    typeIndex: number[];
    defaultDate: string[];
    storeIndex: number[];
    buttonsTypes: string[];
    buttonsStores: string[];
    handleChangeDate: (dateStrings: [string, string]) => void;
    handleSwitchStore: (index: number, type: 'store' | 'type') => void;
}

export const Filter = (
    {
        isCollapsed,
        applyFilter,
        clearFilter,
        typeIndex,
        storeIndex,
        buttonsTypes,
        defaultDate,
        buttonsStores,
        handleChangeDate,
        handleSwitchStore
    }: FilterProps
) => {
    return (
        <UnmountClosed isOpened={isCollapsed}>
            <div className={styles.filter}>
                <div className={styles.filterRow}>
                    <div className={styles.inputWrap}>
                        <RangePicker
                            dropdownClassName="custom-range-picker-dropdown"
                            className={styles.inputRange}
                            locale={ru_RU}
                            format="YYYY-MM-DD"
                            presets={rangePresets}
                            onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                            separator="-"
                            value={defaultDate.length > 0
                                ? [dayjs(defaultDate[0], 'YYYY-MM-DD'), dayjs(defaultDate[1], 'YYYY-MM-DD')]
                                : undefined}
                        />
                    </div>
                </div>
                <div className={styles.filterRow}>
                    <div className={styles.inputWrap}>
                        <SwitcherTab
                            pageType={'report'}
                            isActive={storeIndex}
                            buttons={buttonsStores}
                            isMultiple
                            onClick={(index) => handleSwitchStore(index, 'store')}
                        />
                    </div>
                </div>
                <div className={styles.filterRow}>
                    <div className={styles.inputWrap}>
                        <SwitcherTab
                            pageType={'report'}
                            isActive={typeIndex}
                            isMultiple
                            buttons={buttonsTypes}
                            onClick={(index) => handleSwitchStore(index, 'type')}
                        />
                    </div>
                </div>
                <div className={styles.filterButtons}>
                    <button
                        className={styles.filterButtonsButton}
                        onClick={clearFilter}
                    >Очистить фильтр
                    </button>
                    <button
                        className={styles.filterButtonsButton}
                        onClick={applyFilter}
                    >Применить фильтр
                    </button>
                </div>
            </div>
        </UnmountClosed>
    );
};
