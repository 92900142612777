import {createSlice} from "@reduxjs/toolkit";
import {FinancialFilterSlice, IFinancialFilter} from "./type";
import dayjs from "dayjs";
import {financialFilterApi} from "./financial.filter.api";

//const today = dayjs();
const weekForward = dayjs().subtract(-1, 'week');
const weekAgo = dayjs().subtract(1, 'week');

export const dateKeys: IFinancialFilter[] = [
    {type: 'WantStart', value: weekAgo.format('YYYY-MM-DD')},
    {type: 'WantFinish', value: weekForward.format('YYYY-MM-DD')},
];

const initialState: FinancialFilterSlice = {
    keys: dateKeys,
    activeIndicator: false,
    company: []
};
const financialFilterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFinancialFilter: (state, {payload}) => {
            state.keys = payload;
        },
        setClearFinancialFilter: (state) => {
            state.keys = dateKeys;
            state.activeIndicator = false;
        },
        setActiveIndicator: (state, {payload}) => {
            state.activeIndicator = payload;
        },
        setFilterCompanies: (state, {payload}) => {
            state.company = [...state.company, payload];
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            financialFilterApi.endpoints.getFilterCompanies.matchFulfilled,
            (state, {payload}) => {
                state.company = payload.list;
            }
        );
    }
});

export const {
    setFinancialFilter,
    setClearFinancialFilter,
    setFilterCompanies,
    setActiveIndicator
} = financialFilterSlice.actions;

export default financialFilterSlice.reducer;
