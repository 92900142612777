import React from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {clearOrder} from './store/order.slice';

export const OrderDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {order} = useAppSelector(state => state.order);

    const handleBack = () => {
        dispatch(clearOrder());
        navigate(-1);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Заказ ${order?.orderInfo.number}`}/>
            <BackButton text={'Назад'} onClick={handleBack}/>
            <div className={'content'}>
                <h1>Здесь будет данные о заказе {order?.title}</h1>
            </div>
        </MainLayout>
    );
};
