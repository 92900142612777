import {createSlice} from "@reduxjs/toolkit";
import {IPhotoReportState} from "./types";

const initialState: IPhotoReportState = {
    photoReportTypeId: null
};

const photoReportSlice = createSlice({
    name: 'photoReport',
    initialState,
    reducers: {
        setPhotoReportType: (state, {payload}) => {
            state.photoReportTypeId = payload;
        }
    }
});

export const {setPhotoReportType} = photoReportSlice.actions;

export default photoReportSlice.reducer;
