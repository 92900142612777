import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styles from '../style.module.scss';
import classNames from 'classnames';
import {Financial, FinancialFile, IFormData} from '../state/types';
import {DatePicker, Select, Spin} from 'antd';
import dayjs from 'dayjs';
import {Button} from '../../../fields/button';
import {colorsView, mainColor} from '../../../utils/constants';
import {Option} from '../../../store/types';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {TaskType} from '../../taskCreate/store/types';
import {useGetFinancialFilesQuery, useHandleFinancialActionMutation} from '../state/financial.api';
import {fromJsonToUrlencoded} from '../../../helpers';
import {useLocation, useNavigate} from 'react-router-dom';
import {Success} from '../../taskCreate/components';
import {Modal} from '../../../components/modal';
import {toast} from 'react-toastify';
import {useMiddlewareUploadFilesMutation} from '../../../store/api';
import ReactFileReader from 'react-file-reader';
import {LoadingOutlined} from '@ant-design/icons';
import {ImageLayout} from '../../../layouts';
import {createWorker} from 'tesseract.js';
import {mapResponsePhotoReader} from '../helper';
import {useAddCompaniesMutation} from '../../../components/financialFilter/store/financial.filter.api';
import {useAppDispatch, useAppSelector} from '../../../hooks/app.hooks';
import {setFilterCompanies} from '../../../components/financialFilter/store/financial.filter.slice';
import {RenderAttachment} from '../../../components/renderAttachment';
import {setCreatedFinancial} from '../state/financial.slice';

const plusIcon = require('../../../assets/images/plus.png');
const photoIcon = require('../../../assets/images/photo.png');

const schema = yup.object().shape({
    ID_App: yup.string(),
    ID_Obj: yup.string(),
    ID_Color: yup.string(),
    Payer: yup.string(),
    Name: yup.string(),
    ID_Kontra: yup.string(),
    DateWant: yup.string(),
    SumApp: yup.string(),
    NumOrder: yup.string(),
    DateOrder: yup.string(),
    Rem: yup.string(),
});

interface FormProps {
    colors: Option[];
    companies: Option[];
    objects: Option[];
    item: Financial;
}


const revertData = (str: string) => {
    return str.split('.').reverse().join('-');
};

export const Form = (
    {
        colors,
        companies,
        objects,
        item
    }: FormProps
) => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [handleFinancialAction] = useHandleFinancialActionMutation();
    const [uploadFile, {isLoading}] = useMiddlewareUploadFilesMutation();
    const {data} = useGetFinancialFilesQuery({
        params: `ID_Tab=${item?.ID}&typeFile=100`
    }, {skip: !item?.ID});

    const [addCompany] = useAddCompaniesMutation();

    const {createdFinancial} = useAppSelector(state => state.financialMenu);

    const [formData, setFormData] = useState<FormData | null>(null);
    const [viewFiles, setViewFiles] = useState<FinancialFile[] | File[]>([]);
    const [currentFile, setCurrentFiles] = useState<FinancialFile | File>();
    const [waitingFile, setWaitingFile] = useState<string | null>(null);
    const [showFile, setShowFile] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(true);
    const [progressLoading, setLoaderProgress] = useState<number>(0);

    const [showForm, setShowForm] = useState<boolean>(false);
    const [isAddNew, setAddNew] = useState<boolean>(false);
    const [newCompany, setNewCompany] = useState<{ name: string, inn: string }>({name: '', inn: ''});

    const [isOpen, setOpen] = useState<boolean>(false);
    const [financialItem, setFinancialItem] = useState<Financial | null>(null);
    const [numPages, setNumPages] = useState<number>(1);


    useEffect(() => {
        if (!state?.isSos) {
            setShowForm(true);
        }
    }, [state]);

    useEffect(() => {
        if (item && item?.ID) {
            new Promise((resolve) => {
                const formData = {
                    ID_App: String(item.ID),
                    ID_Kontra: String(item.ID_Kontra),
                    Name: item.Name,
                    Rem: item.Rem,
                    DateOrder: item.DateOrder_str,
                    NumOrder: item.NumOrder,
                    Payer: item.Payer,
                    SumApp: String(item.SumApp),
                    DateWant: item.DateWant_str,
                    ID_Obj: String(item.ID_Obj),
                    ID_Color: item.ID_Color,
                } as any;
                for (const key in formData) {
                    if (key === 'DateOrder' || key === 'DateWant') {
                        setValue(key as keyof IFormData, `${revertData(formData[key])}`.trim());
                    } else {
                        setValue(key as keyof IFormData, `${formData[key]}`.trim());
                    }
                }
                resolve(true);
            }).then((status) => {
                setLoading(!status);
            });


        } else {
            new Promise((resolve) => {
                for (const key in createdFinancial) {
                    if (key === 'DateOrder' || key === 'DateWant') {
                        setValue(key as keyof IFormData, `${revertData(createdFinancial[key] as string)}`.trim());
                    } else {
                        //@ts-ignore
                        setValue(key as keyof IFormData, `${createdFinancial[key]}`.trim());
                    }
                }
                resolve(true);
            }).then((status) => {
                setLoading(!status);
            });
        }

    }, [item]);

    useEffect(() => {
        if (data?.filesList) {
            setViewFiles(data.filesList);
        }
    }, [data]);

    const {control, setValue, watch, handleSubmit} = useForm<IFormData>({
        mode: 'onChange',
        resolver: yupResolver(schema)
    });

    const handleChangeField = (key: string, value: string) => {
        setValue(key as keyof IFormData, value);
        dispatch(setCreatedFinancial({...createdFinancial, [key]: value}));
    };

    const handleAddNewCompany = () => {
        setAddNew(false);
        setLoading(true);

        addCompany({body: {Type: 'Insert', Name: newCompany.name, INN: newCompany.inn}})
            .unwrap()
            .then(({kontra}) => {
                dispatch(setFilterCompanies({label: kontra.name, value: String(kontra.id)}));
                handleChangeField('ID_Kontra', String(kontra.id));
            })
            .finally(() => setLoading(false));
    };

    const submit = handleSubmit(values => {
        if (Object.keys(values).length === 0) return;

        handleFinancialAction({
            body: fromJsonToUrlencoded({data: {...values, Type: item?.ID ? TaskType.Update : TaskType.Insert}})
        }).unwrap()
            .then((res) => {
                if (res.status) {
                    setFinancialItem(res.finApp);
                    if (formData) {
                        formData.append('ID_Tab', String(res.finApp.ID));
                        uploadFile({path: '/FinancialApplications/fileAdd.php', body: formData})
                            .unwrap()
                            .then(() => {
                                setOpen(true);
                            }).catch((e: any) => {
                        });
                    } else {
                        setOpen(true);
                    }
                } else {
                    toast(res?.message, {
                        type: 'error',
                        position: 'top-center'
                    });
                }
            }).catch((e) => {
            toast(e.message ?? 'Ошибка', {
                type: 'error',
                position: 'top-center'
            });
        });
    });

    const handleConfirm = () => {
        navigate(-1);
    };

    const handleFiles = (files: any) => {
        const {fileList, base64} = files;
        const formData = new FormData();
        formData.append('load_files[]', fileList[0], `${'origName' in fileList[0] ? fileList[0]?.origName : fileList[0]?.name}`);
        formData.append('typeFile', '100');

        setFormData(formData);
        setViewFiles([...viewFiles, fileList[0]]);
        setWaitingFile(base64);
    };

    const handleSosFiles = async (files: any) => {
        setLoaderProgress(0);
        const {fileList} = files;
        const fileInput = fileList?.[0];

        if (fileInput) {
            handleFiles(files);
            const worker = await createWorker('rus', 1, {
                logger: data => setLoaderProgress(data.progress),
            });
            await worker.recognize(fileInput)
                .then(({data: {text, lines, words}}) => {
                    mapResponsePhotoReader({lines, setValue, words});
                    setShowForm(true);
                    return text;
                });
        }
    };


    const handleShowFile = (file: any) => {
        setShowFile(true);
        setViewPortScalable(true);
        setCurrentFiles(file);
    };

    const handleCloseFile = () => {
        setViewPortScalable(false);
        setShowFile(false);
    };

    const setViewPortScalable = (isAdd: boolean) => {
        const viewportMetaTag = document.querySelector('meta[name="viewport"]');
        if (viewportMetaTag) {
            isAdd
                ? viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=3, user-scalable=1, viewport-fit=cover')
                : viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, viewport-fit=cover');
        }
    };


    if (loading) {
        return <></>;
    }

    return (
        <>
            {state?.isSos && !showForm ?
                (
                    <ReactFileReader
                        fileTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                        multiple={false}
                        base64
                        handleFiles={async (files: File[]) => {
                            await handleSosFiles(files);
                        }}>
                        <div className={styles.bottomWrapper}>
                            <button
                                className={styles.buttonPayment}
                                onClick={
                                    async (e) => {
                                        await sessionStorage.setItem('isFile', 'OK');
                                    }
                                }
                            >
                                Загрузить фото счёта
                            </button>
                            <div className={styles.progressBar}>
                            <span className={styles.progressBarLine} style={{
                                width: `${progressLoading > 0.2 ? (progressLoading * 100) : 0}%`
                            }}/>
                            </div>
                        </div>
                    </ReactFileReader>

                ) : null}
            {showForm
                ? (
                    <>
                        <div className={styles.formRow}>
                            <label className={styles.formLabel}>Объект</label>
                            <Select
                                className={styles.formInput}
                                options={objects}
                                filterOption={(input, option) => {
                                    return (option?.label.toUpperCase() ?? '').includes(input.toUpperCase());
                                }}
                                showSearch
                                value={watch('ID_Obj') ?? ''}
                                onChange={(value) => handleChangeField('ID_Obj', value)}
                            />
                        </div>
                        <Controller
                            control={control}
                            name={'Payer'}
                            render={({field, fieldState}) => (
                                <div className={styles.formRow}>
                                    <label className={styles.formLabel}>Плательщик</label>
                                    <input className={styles.formInput} value={watch('Payer')}
                                           onChange={e => {
                                               handleChangeField('Payer', e.target.value);
                                           }}/>
                                </div>
                            )}/>
                        <Controller
                            control={control}
                            name={'Name'}
                            render={({field, fieldState}) => (
                                <div className={styles.formRow}>
                                    <label className={styles.formLabel}>Получатель</label>
                                    <input className={styles.formInput} value={watch('Name')}
                                           onChange={e => {
                                               handleChangeField('Name', e.target.value);
                                           }}/>
                                </div>
                            )}/>
                        <div className={styles.formRow}>
                            <label className={styles.formLabel}>Контрагент</label>
                            <Select
                                className={styles.formInput}
                                options={companies}
                                filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                                showSearch
                                value={watch('ID_Kontra') ?? ''}
                                onChange={value => handleChangeField('ID_Kontra', value)}
                            />
                        </div>
                        {isAddNew
                            ? (
                                <div className={styles.companyBox}>
                                    <div className={styles.companyBoxRow}>
                                        <label className={styles.formLabel}>Название</label>
                                        <input className={styles.formInput} value={newCompany.name}
                                               onChange={e => setNewCompany({...newCompany, name: e.target.value})}/>
                                    </div>
                                    <div className={styles.companyBoxRow}>
                                        <label className={styles.formLabel}>ИНН</label>
                                        <input className={styles.formInput} value={newCompany.inn}
                                               onChange={e => setNewCompany({...newCompany, inn: e.target.value})}/>
                                    </div>
                                    <button className={styles.companyBoxButton} onClick={handleAddNewCompany}>добавить
                                    </button>
                                </div>
                            ) : null}
                        <div className={styles.formRow}>
                            <label className={styles.formLabel}>Цвет</label>
                            <Select
                                className={styles.formInput}
                                options={colors}
                                onChange={value => handleChangeField('ID_Color', value)}
                                value={watch('ID_Color') ?? ''}
                                suffixIcon={
                                    <div className={styles.selectedColor}
                                         style={{backgroundColor: `${colorsView[watch('ID_Color') ?? '0']}`}}/>
                                }
                                optionRender={(option) => (
                                    <div className={styles.formOption}>
                                        <span className={styles.formOptionLabel}>{option.label}</span>
                                        <span className={styles.formOptionView}
                                              style={{backgroundColor: `${colorsView[option.value as string]}`}}/>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={styles.formDabbleRow}>
                            <Controller
                                control={control}
                                name={'NumOrder'}
                                render={({field, fieldState}) => (
                                    <div className={classNames(styles.formCol, styles.paddingT, styles.w90)}>
                                        <label className={styles.formLabel}>Счёт</label>
                                        <input className={styles.formInput}
                                               value={watch('NumOrder')}
                                               onChange={e => {
                                                   handleChangeField('NumOrder', e.target.value);
                                                   //setValue('NumOrder', e.target.value)
                                               }}/>
                                    </div>
                                )}/>
                            <Controller
                                control={control}
                                name={'DateOrder'}
                                render={({field, fieldState}) => (
                                    <div className={classNames(styles.formCol, styles.paddingT, styles.w90)}>
                                        <label className={styles.formLabel}>от</label>
                                        <DatePicker
                                            className={classNames(styles.formInput, styles.selectDate)}
                                            placeholder={''}
                                            suffixIcon={null}
                                            value={watch('DateOrder') ? dayjs(watch('DateOrder')) : undefined}
                                            onChange={
                                                (date, dateString) =>
                                                    handleChangeField('DateOrder', dateString as string)
                                                // setValue('DateOrder', dateString as string)
                                            }
                                        />
                                    </div>
                                )}/>
                        </div>
                        <div className={styles.formDabbleRow}>
                            <Controller
                                control={control}
                                name={'SumApp'}
                                render={({field, fieldState}) => (
                                    <div className={classNames(styles.formCol)}>
                                        <label className={styles.formLabel}>Сумма</label>
                                        <input
                                            //type={'number'}
                                            inputMode={'numeric'}
                                            className={styles.formInput}
                                            value={watch('SumApp')}
                                            onChange={e => {
                                                handleChangeField('SumApp', e.target.value);
                                                // setValue('SumApp', e.target.value)
                                            }}/>
                                    </div>
                                )}/>
                            <Controller
                                control={control}
                                name={'DateWant'}
                                render={({field, fieldState}) => (
                                    <div className={classNames(styles.formCol)}>
                                        <label className={styles.formLabel}>до</label>
                                        <DatePicker
                                            className={classNames(styles.formInput, styles.selectDate)}
                                            placeholder={''}
                                            suffixIcon={null}
                                            value={watch('DateWant') ? dayjs(watch('DateWant')) : undefined}
                                            onChange={(date, dateString) =>
                                                handleChangeField('DateWant', dateString as string)
                                                //setValue('DateWant', dateString as string)
                                            }
                                        />
                                    </div>
                                )}/>
                        </div>
                        <Controller
                            control={control}
                            name={'Rem'}
                            render={({field, fieldState}) => (
                                <div className={styles.formTextareaWrapper}>
                                    <label className={styles.formLabel}>Назначение</label>
                                    <textarea rows={3} className={styles.formTextarea} value={watch('Rem')}
                                              onChange={e => {
                                                  handleChangeField('Rem', e.target.value);
                                                  //setValue('Rem', e.target.value)
                                              }}/>
                                </div>
                            )}/>
                        <div className={styles.fileList}>
                            <ReactFileReader
                                fileTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                                multiple={false}
                                base64
                                handleFiles={(files: File[]) => {
                                    handleFiles(files);
                                }}>
                                <div className={styles.wrapReaderButton}>
                                    <button
                                        className={styles.fileReaderButton}
                                        disabled={waitingFile !== null}
                                        onClick={
                                            async (e) => {
                                                await sessionStorage.setItem('isFile', 'OK');
                                            }
                                        }>
                                        {isLoading ?
                                            <Spin indicator={<LoadingOutlined style={{fontSize: 14}} spin/>}/>
                                            : <ImageLayout
                                                src={plusIcon}
                                                imageWidth={24}
                                                imageHeight={24}
                                                containerWidth={24}
                                                containerHeight={24}/>}
                                    </button>
                                    <span className={styles.minText}>Доб. файл</span>
                                </div>
                            </ReactFileReader>
                            {viewFiles.length > 0
                                ? <>
                                    {viewFiles.map((file, index) => (
                                        <div key={index} className={styles.wrapReaderButton}
                                             onClick={() => handleShowFile(file)}>
                                            <button key={`${index}`}
                                                    className={styles.fileReaderButton}>
                                                <ImageLayout
                                                    src={photoIcon}
                                                    imageWidth={36}
                                                    imageHeight={36}
                                                    containerWidth={36}
                                                    containerHeight={36}/>
                                            </button>
                                            {file && 'NameOrig' in file
                                                ? <span className={styles.minText}>{file?.NameOrig}</span>
                                                : <span className={styles.minText}>{file?.name}</span>}

                                        </div>
                                    ))}
                                </>
                                : null}
                            {showFile ?
                                <div className={styles.modalFile}>
                                    <span
                                        className={styles.close}
                                        onClick={handleCloseFile}
                                    />
                                    <div className={styles.modalFileContent}>
                                        <RenderAttachment
                                            currentFile={currentFile}
                                            pageNumber={numPages}
                                            setNumPages={setNumPages}
                                        />
                                    </div>
                                </div> : null}
                        </div>
                        <div className={styles.bottomWrapper}>
                            <Button
                                text={'Сохранить'}
                                textStyleType={'semiBold'}
                                style={{
                                    color: mainColor,
                                    fontSize: '16px'
                                }}
                                onClick={submit}
                            />
                        </div>
                    </>
                ) : null}
            <Modal isOpen={isOpen}>
                <Success
                    title={item?.ID ? 'Успешно изменено' : 'Новая Заявка'}
                    text={item?.ID ? 'Новые данные по заявке' : 'По Вашему обращению создана заявка'}
                    onConfirm={handleConfirm}
                    numberTask={financialItem?.ID ?? 0}/>
            </Modal>
        </>
    );
};
