import React, {useEffect, useState} from 'react';
import styles from '../style.module.scss';
import {Button} from '../../../fields/button';
import {mainColor} from '../../../utils/constants';
import {Textarea} from '../../../fields/textarea';
import {QuizReqItem, UploadFileResponse} from '../store/types';
import ReactFileReader from 'react-file-reader';
import {ImageLayout} from '../../../layouts';

const plusIcon = require('../../../assets/images/plus.png');
const photoIcon = require('../../../assets/images/photo.png');

interface RenderTextCaseProps {
    list: string[];
    answer: QuizReqItem | undefined;
    files: UploadFileResponse[];
    handleSelect: (item: string, rem: string, type: string) => void;
    handleFiles: (file: File) => void;
}

export const RenderTextCase = ({list, answer, files, handleSelect, handleFiles}: RenderTextCaseProps) => {
    const [valueAnswer, setValueAnswer] = useState<string>('');
    const [rem, setRem] = useState<string>('');
    const [selectButton, setSelectButton] = useState<string>('');

    useEffect(() => {
        list.length === 0
            ? answer?.value ? setValueAnswer(String(answer?.value)) : setValueAnswer('')
            : answer?.value ? setSelectButton(String(answer?.value)) : setSelectButton('');

        answer?.rem ? setRem(answer.rem) : setRem('');
    }, [answer]);

    return (
        <div className={styles.variableList}>
            <p className={styles.count}>Ответ:</p>
            <div className={styles.flex}>

                {list.length > 0 ? list.map((button, idx) => (
                    <div key={idx} style={{width: `calc(100% / ${list.length} - 5px)`}}>
                        <Button
                            text={button}
                            textStyleType={'regular'}
                            style={{
                                fontSize: '14px',
                                border: `1px solid ${mainColor}`,
                                borderRadius: '12px',
                                backgroundColor: answer?.value === button ? 'rgb(233, 190, 199)' : '#ffffff'
                            }}
                            onClick={() => {
                                setSelectButton(button);
                                handleSelect(button, rem, 'buttons');
                            }}
                        />
                    </div>
                )) : (
                    <Textarea
                        value={valueAnswer}
                        onChange={value => setValueAnswer(value)}
                        onBlur={(value) => {
                            handleSelect(value, rem, 'text');
                        }}
                    />
                )}
            </div>
            <div className={styles.remTextarea}>
                <label className={styles.label}>комментировать ответ и приложить файл</label>
                <Textarea
                    value={rem}
                    placeholder={'Комментарий'}
                    onChange={value => setRem(value)}
                    onBlur={(value) => {
                        list.length > 0
                            ? handleSelect(selectButton, value, 'text')
                            : handleSelect(valueAnswer, value, 'text');
                    }}
                />
            </div>
            <div className={styles.files}>
                <ReactFileReader
                    fileTypes={['.png', '.jpg', '.jpeg', '.pdf']}
                    multiple={false}
                    handleFiles={(files: File) => {
                        handleFiles(files);
                    }}>
                    <div className={styles.wrapReaderButton}>
                        <button
                            disabled={!answer?.value && !answer?.rem}
                            className={styles.fileReaderButton}
                            onClick={
                                async (e) => {
                                    await sessionStorage.setItem('isFile', 'OK');
                                }
                            }
                        >
                            <ImageLayout
                                src={plusIcon}
                                imageWidth={24}
                                imageHeight={24}
                                containerWidth={24}
                                containerHeight={24}
                            />
                        </button>
                        <span className={styles.minText}>Доб. файл</span>
                    </div>
                </ReactFileReader>

                {files.length > 0
                    ? <div className={styles.list}>
                        {files.map((file, index) => (
                            <div className={styles.wrapReaderButton}>
                                <button key={`${index}-${file.fileName}`} className={styles.fileReaderButton}>
                                    <ImageLayout src={photoIcon} imageWidth={36} imageHeight={36} containerWidth={36}
                                                 containerHeight={36}/>
                                </button>
                                <span className={styles.minText}>{file.fileName}</span>
                            </div>
                        ))}
                    </div>
                    : null}
            </div>
        </div>
    );
};
