import {api} from '../../../store/api';
import {CallItem, CallResponse} from './types';

const callApi = api.injectEndpoints({
    endpoints: build => ({
        getCallCount: build.query<CallResponse, { params: string }>({
            query: ({params}) => ({
                url: `/crm/p_PhoneCall_qnt?${params}`,
                method: 'GET'
            }),
            transformResponse: ({result, resultType, resultStatus}) => {
                return {result, resultType, resultStatus};
            }
        }),
        getCall: build.query<CallItem[], { params: string }>({
            query: ({params}) => ({
                url: `/crm/p_PhoneCall?${params}`,
                method: 'POST'
            }),
            transformResponse: ({listPhoneCalls}) => {
                return listPhoneCalls;
            }
        })
    }), overrideExisting: true
});

export const {useGetCallCountQuery, useGetCallQuery} = callApi;
