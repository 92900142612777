import React, {useEffect, useMemo, useState} from 'react';
import {
    useGetFilterAuthorQuery,
    useGetFilterCompaniesQuery,
    useGetFilterObjMutation
} from './store/financial.filter.api';
import {useAppDispatch} from '../../hooks/app.hooks';
import styles from '../../screens/taskList/style.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import {DatePicker, Input} from 'antd';
import {CloseCircleOutlined, SearchOutlined} from '@ant-design/icons';
import {setClearFinancialFilter, setFinancialFilter} from './store/financial.filter.slice';
import {Filter} from './components';
import {FinancialFilterItemType, IFinancialFilter} from './store/type';
import {fromJsonToUrlencoded} from '../../helpers';
import {rangePresets} from '../taskFilter/TaskFilter';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale('ru');

const {RangePicker} = DatePicker;

interface TaskFilterProps {
    useInputSearch: boolean;
    applyFilter: (isInit: boolean) => void;
    keys: IFinancialFilter[];
    onSearch?: (text: string) => void;
}

export const FinancialFilter = ({applyFilter, useInputSearch, keys, onSearch}: TaskFilterProps) => {
    const dispatch = useAppDispatch();
    const [getFinancialFilter, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});
    const {data: companies} = useGetFilterCompaniesQuery();
    const {data: authors} = useGetFilterAuthorQuery();

    const [isCollapsed, setCollapse] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    useEffect(() => {
        getFinancialFilter({body: fromJsonToUrlencoded({data: {ShowComp: 'NULL'}})});
    }, []);

    useEffect(() => {
        if (keys.some(i => i.type === 'Search')) {
            const text = keys.find(i => i.type === 'Search')?.value as string;
            setText(text);
        }
    }, [keys]);

    const handleSetFilter = (value: string, type: FinancialFilterItemType | 'index') => {
        if (keys.some(key => key.type === type)) {
            const newKeys = keys.map(key => key.type === type ? {...key, value} : key);
            dispatch(setFinancialFilter(newKeys));
        } else {
            dispatch(setFinancialFilter([...keys, {type, value}]));
        }
    };

    const handleChangeDate = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );

        setCollapse(true);

        const newKeys = keys.map(key => {
            if (key.type === 'WantStart') return {...key, value: formattedDates[0]};
            if (key.type === 'WantFinish') return {...key, value: formattedDates[1]};
            return key;
        });
        dispatch(setFinancialFilter(newKeys));
    };

    const handleSearch = (value: string) => {
        setText(value);
    };

    const clearFilter = (type: 'search' | 'other') => {
        if (type === 'search') {
            const newKeys = keys.filter(k => k.type !== 'Search');
            dispatch(setFinancialFilter(newKeys));
            setText('');
            applyFilter(false);
        } else {
            dispatch(setClearFinancialFilter());
            setCollapse(false);
            applyFilter(true);
        }
        setCollapse(false);
    };

    const dateStart = keys.find(k => k.type === 'WantStart') as IFinancialFilter;
    const dateFinish = keys.find(k => k.type === 'WantFinish') as IFinancialFilter;
    const selectFieldFilter = useMemo(() => keys.filter(k => !['WantStart', 'WantFinish'].includes(k.type)), [keys]);

    return (
        <div>
            <div className={styles.dataPickerWrapper}>
                <div className={styles.dataPickerWrapperData}>
                    <RangePicker
                        dropdownClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format="D MMM, YYYY"
                        presets={rangePresets}
                        value={[dayjs(dateStart.value as string, 'YYYY.MM.DD'), dayjs(dateFinish.value as string, 'YYYY.MM.DD')]}
                        onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                        separator="-"
                        allowClear={false}
                    />
                </div>
                <button
                    className={styles.dataPickerWrapperButton}
                    onClick={() => setCollapse(!isCollapsed)}
                >
                    {isCollapsed ? 'Скрыть' : 'Фильтр'}
                    {selectFieldFilter.length > 0 && !isCollapsed
                        ? <span
                            className={styles.dataPickerWrapperCount}>{selectFieldFilter.length}</span>
                        : null}
                </button>
            </div>
            <Filter
                defaultFilter={keys}
                isCollapsed={isCollapsed}
                optObjects={objects?.listObject ?? []}
                optCompanies={companies?.list ?? []}
                optAuthors={authors?.list ?? []}
                handleSelectObj={value => handleSetFilter(value, 'FObjects')}
                handleSelectKontras={value => handleSetFilter(value, 'FKontras')}
                handleSelectPayer={value => handleSetFilter(value, 'Payer')}
                handleSelectAuthors={value => handleSetFilter(value, 'FAuthors')}
                applyFilter={() => {
                    applyFilter(false);
                    setCollapse(false);
                }}
                clearFilter={() => {
                    clearFilter('other');
                }}
            />
            {useInputSearch ? <div className={styles.search}>
                <div className={styles.searchInput}>
                    <Input
                        placeholder="введите текст поиска"
                        className={styles.input}
                        value={text}
                        autoFocus={false}
                        onBlur={() => {
                            if (text === '') {
                                clearFilter('search');
                            }
                        }}
                        onChange={(event) => handleSearch(event.target.value)}
                        suffix={
                            <div onClick={() => clearFilter('search')}>
                                {text === '' ? <SearchOutlined/> : <CloseCircleOutlined/>}
                            </div>
                        }
                    />
                </div>
                <button
                    className={styles.searchButton}
                    disabled={text.length === 0}
                    onClick={() => onSearch ? onSearch(text) : null}
                >{'Поиск'}
                </button>
            </div> : null}
        </div>
    );
};

// ?showMode=fromMe&WantStart=2024-05-27&WantFinish=2024-06-04&FAuthors=511

// ?showMode=fromMe&WantStart=2024-05-27&WantFinish=2024-06-10&FAuthors=511
