import React, {CSSProperties, HTMLAttributes, ReactNode} from 'react';
import styles from './style.module.scss';
import classNames from "classnames";

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    text: string;
    textStyleType: 'semiBold' | 'regular';
    disabled?: boolean;
    style: CSSProperties;
    iconDirection?: 'left' | 'right';
    renderIcon?: () => ReactNode;
}

export const Button = ({
                           text,
                           textStyleType,
                           disabled = false,
                           style = {},
                           renderIcon,
                           iconDirection,
                           onClick
                       }: ButtonProps) => {
    return (
        <div className={styles.wrapper}>
            {iconDirection === 'left' && renderIcon ? renderIcon() : null}
            <button
                style={style}
                disabled={disabled}
                className={
                    classNames(styles.button, textStyleType === 'semiBold' ? styles.buttonBold : styles.buttonRegular)
                }
                onClick={onClick}>{text}</button>
            {iconDirection === 'right' && renderIcon ? renderIcon() : null}
        </div>
    );
};
