import {createSlice} from '@reduxjs/toolkit';
import {QuicklyTaskForm, TaskSwitcherState} from './types';

export const defaultQuicklyTask: QuicklyTaskForm = {
    Name: '',
    Rem: ''
};

const initialState: TaskSwitcherState = {
    currentTab: 0,
    createdTask: null,
    quicklyTask: {
        Name: '',
        Rem: ''
    }
};

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        switchTab: (state, {payload}) => {
            state.currentTab = payload;
        },
        setCreatedTask: (state, {payload}) => {
            state.createdTask = payload;
        },
        setQuicklyTask: (state, {payload}) => {
            if (!state.quicklyTask) {
                state.quicklyTask = {Name: '', Rem: ''};
            }
            state.quicklyTask.Name = payload?.Name ?? '';
            state.quicklyTask.Rem = payload?.Rem ?? '';
        }
    }
});

export const {switchTab, setCreatedTask, setQuicklyTask} = taskSlice.actions;

export default taskSlice.reducer;
