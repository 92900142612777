import {createSlice} from "@reduxjs/toolkit";
import {FilterSlice, IFilter} from "./type";
import dayjs from "dayjs";

const today = dayjs();
const monthAgo = dayjs().subtract(1, 'month');

export const dateKeys: IFilter[] = [
    {type: 'Start', value: monthAgo.format('YYYY-MM-DD')},
    {type: 'Finish', value: today.format('YYYY-MM-DD')},
];
export const defaultDate: IFilter[] = [
    {type: 'Start', value: monthAgo.format('YYYY-MM-DD')},
    {type: 'Finish', value: today.format('YYYY-MM-DD')},
];

const initialState: FilterSlice = {
    keys: dateKeys,
    activeIndicator: false
};
const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setFilter: (state, {payload}) => {
            state.keys = payload;
        },
        setClearFilter: (state) => {
            state.keys = dateKeys;
            state.activeIndicator = false;
        },
        setActiveIndicator: (state, {payload}) => {
            state.activeIndicator = payload;
        }
    }
});

export const {setFilter, setClearFilter, setActiveIndicator} = filterSlice.actions;

export default filterSlice.reducer;
