import React from 'react';
import styles from '../style.module.scss';
import {ImageLayout} from '../../../layouts';
import {Button} from '../../../fields/button';
import {useAppSelector} from '../../../hooks/app.hooks';
import {themColors} from '../../../hooks/useChangeTheme';

const iconSuccess = require('../../../assets/images/face-success.png');

interface SuccessProps {
    onConfirm: () => void;
    percent: string;
    KontraName: string;
}

export const SuccessReport = ({onConfirm, percent, KontraName}: SuccessProps) => {
    const {theme} = useAppSelector(state => state.app);
    return (
        <>
            <p className={styles.modalText}>{`Аттестация сотрудника ${KontraName}`}</p>
            <p className={styles.modalText}>{`сохранена успешно`}</p>
            <ImageLayout
                src={iconSuccess}
                imageWidth={64}
                imageHeight={64}
                containerWidth={120}
                containerHeight={120}/>
            <p className={styles.modalText} style={{marginBottom: '10px'}}>Заполнено: {percent}</p>
            <div className={styles.modalButtons}>
                <Button
                    text={'Закрыть'}
                    textStyleType={'regular'}
                    onClick={onConfirm}
                    style={{
                        border: `1px solid ${themColors[theme].color}`,
                        borderRadius: '12px',
                        fontSize: '14px',
                        height: '44px'
                    }}/>
            </div>
        </>
    );
};
