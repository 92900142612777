import {createSlice} from '@reduxjs/toolkit';
import {MonitoringStore} from './types';
import {IFilter} from '../../../components/taskFilter/store/type';
import dayjs from 'dayjs';
import {monitoringApi} from './monitoring.api';

const today = dayjs();
const dayAgo = dayjs().subtract(1, 'day');

export const initDateKeys: IFilter[] = [
    {type: 'Start', value: dayAgo.format('YYYY-MM-DD')},
    {type: 'Finish', value: today.format('YYYY-MM-DD')},
];

const initialState: MonitoringStore = {
    filter: initDateKeys,
    pointsOrders: [],
};
const monitoringSlice = createSlice({
    name: 'monitoring',
    initialState,
    reducers: {
        setFilter: (state, {payload}) => {
            state.filter = payload;
        },
        setClearFilter: (state) => {
            state.filter = initDateKeys;
        },
        changePointOrders: (state, {payload}) => {
            state.pointsOrders = payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            monitoringApi.endpoints.getPointAll.matchFulfilled,
            (state, {payload}) => {
                state.pointsOrders = payload;
            }
        );
    }
});

export const {setFilter, setClearFilter, changePointOrders} = monitoringSlice.actions;

export default monitoringSlice.reducer;
