import React from 'react';

export const NavigationIcon = ({color = '#000000'}: { color: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M13.1825 1.63868C13.0333 1.25372 12.6629 1 12.2501 1C11.8372 1 11.4668 1.25372 11.3176 1.63868L3.56761 21.6387C3.41389 22.0354 3.52799 22.4859 3.852 22.7616C4.17601 23.0373 4.63899 23.0778 5.00596 22.8626L12.2501 18.6139L19.4941 22.8626C19.8611 23.0778 20.3241 23.0373 20.6481 22.7616C20.9721 22.4859 21.0862 22.0354 20.9325 21.6387L13.1825 1.63868Z"
                fill={color} fillOpacity="0.85"/>
        </svg>
    );
};
