import {DateType} from "../../../store/types";

export interface QuantityFinancial {
    mode: 'notPaid' | 'partiallyPaid' | 'paid' | 'fromMe' | 'other' | 'all' | 'notConfirmed' | 'waitingMe' | 'confirmed';
    quan: number;
}

export interface FinancialMenuState {
    quantity: QuantityFinancial[];
    createdFinancial: IFormData;
}

export interface ResponseFinancialList {
    listFinApp: any[]
}

export type Vote = {
    name: string;
    value: number;
}

export interface Financial {
    ID: number;
    num: number;
    ID_Color: number;
    ID_PayState: number;
    PayState: string;
    ID_Obj: number;
    ObjName: string;
    ID_User: number;
    UserName: string;
    ID_Kontra: number;
    KontraINN: string;
    KontraName: string;
    Kontra: string;
    Name: string;
    Rem: string;
    SumApp: number;
    DateWant: DateType,
    DateWant_str: string;
    Payer: string;
    SumPlat: number;
    DatePlat: null,
    DatePlat_str: string;
    NotPaid: number;
    flagPaid: number;
    ID_Plan: number;
    BudgetName: string;
    DateBudget: DateType,
    DateBudget_str: string;
    NumOrder: string;
    DateOrder: DateType,
    DateOrder_str: string;
    Cnt100: number;
    Cnt200: number;
    DateApp: DateType,
    DateApp_str: string;
    UI: number;
    DI: DateType,
    DI_str: string;
    UU: number;
    DU: DateType,
    DU_str: string;
    votes: Vote[]
}


export interface IColor {
    ID: 0,
    Name: "Б",
    Color: null,
    Ord: null,
    Ord2: 1,
    WHE: "ID_Color is null",
    fullName: "Белый",
    colorWeb: null
}

export interface IFormData {
    ID?: string;
    ID_Obj?: string;
    ID_Color?: string;
    Payer?: string;
    Name?: string;
    ID_Kontra?: string;
    DateWant?: string; //Желаемая дата оплаты (оплатить до)
    SumApp?: string;
    NumOrder?: string;
    DateOrder?: string; //Дата счета (счет от)
    Rem?: string; //Описание за что выставлена фин заявка
    idForm?: string;
}

export const defaultFormData: IFormData = {
    ID: '',
    ID_Obj: '',
    ID_Color: '',
    Payer: '',
    Name: '',
    ID_Kontra: '',
    DateWant: '',
    SumApp: '',
    NumOrder: '',
    DateOrder: '',
    Rem: '',
    idForm: '',
};

export interface FinancialFile {
    ID: number;
    NameCurr: string;
    NameOrig: string;
    NameDraft: string;
    Rem: string;
    IsActive: number;
    Role: string;
    url: string;
}

export interface TableHeadBudget {
    title: string;
    visible: boolean;
}

export type TableBodyBudget = any;

