import React, {ReactNode} from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';

interface ModalProps {
    children: ReactNode;
    isOpen: boolean;
    full?: boolean;
}

export const Modal = ({children, isOpen, full = false}: ModalProps) => {
    if (!isOpen) {
        return <></>;
    }
    return (
        <div className={styles.modalWrapper}>
            <div className={full ? classNames(styles.modal, styles.modalFull) : styles.modal}>
                <div className={full ? classNames(styles.content, styles.contentFull) : styles.content}>
                    {children}
                </div>
            </div>
        </div>
    );
};
