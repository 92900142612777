import {api} from '../../../store/api';
import {OrderInfo} from './types';

export const orderApi = api.injectEndpoints({
    endpoints: build => ({
        getOrderInfo: build.query<OrderInfo, { ID_Obj: number, orderId: string }>({
            query: ({ID_Obj, orderId}) => ({
                url: `/orderHooks/orderInfo.php?ID_Obj=${ID_Obj}&orderId=${orderId}`,
                method: 'GET'
            })
        })
    }), overrideExisting: true
});

export const {
    useLazyGetOrderInfoQuery
} = orderApi;
