import {api} from "../../../store/api";
import {TreeItem} from "./types";
import {mapTaskMenu} from "../../../entitys";

export const taskTypeApi = api.injectEndpoints({
    endpoints: build => ({
        getTaskMenu: build.query<TreeItem[], { id_con: string }>({
            query: ({id_con}) => ({
                url: '/helpDesk/p_AppSect.php',
                method: 'GET',
                headers: {
                    id_con
                }
            }),
            transformResponse: ({treeList, status}) => {
                return mapTaskMenu({treeList});
            }
        }),
        getTaskCount: build.mutation<{ status: boolean, quantity: number }, { id_con: string, path: string }>({
            query: ({id_con, path}) => ({
                url: path,
                method: 'GET',
                headers: {
                    id_con
                },
                keepUnusedDataFor: 5,
            }),
            transformResponse: ({quantity}) => {
                return quantity;
            }
        })
    }),
    overrideExisting: true
});

export const {useGetTaskMenuQuery, useGetTaskCountMutation} = taskTypeApi;
