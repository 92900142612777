import {api} from "../../../store/api";
import {IUser} from "./types";

const profileApi = api.injectEndpoints({
    endpoints: build => ({
        getProfile: build.query<{information: IUser}, {idUser: number}>({
            query: ({idUser}) => ({
                url: `/profile/userInformation.php?idUser=${idUser}`,
                method: 'GET'
            })
        }),
    }), overrideExisting: true
});

export const {useGetProfileQuery} = profileApi;
