import {api} from '../../../store/api';
import {CostPriceList, ReportResponse, RevenueList, WriteOffList} from './types';


export const reportApi = api.injectEndpoints({
    endpoints: build => ({
        // скорость 1 - быстро
        getRevenue: build.query<RevenueList, { params: string }>({
            query: ({params}) => ({
                url: `/reports/mainValuesRevenue.php?${params}`,
                method: 'GET',
            }),
            transformResponse: ({result}) => {
                return result;
            }
        }),
        // скорость 2 - медленнее
        getWriteOff: build.query<WriteOffList, { params: string }>({
            query: ({params}) => ({
                url: `/reports/mainValuesWriteOff.php?${params}`,
                method: 'GET',
            }),
            transformResponse: ({result}) => {
                return result;
            }
        }),
        // скорость 3 - медленно
        getCostPrice: build.query<CostPriceList, { params: string }>({
            query: ({params}) => ({
                url: `/reports/mainValuesCostPrice.php?${params}`,
                method: 'GET',
            }),
            transformResponse: ({result}) => {
                return result;
            }
        }),

        getReportPdf: build.query<any, { holding: string }>({
            query: ({holding}) => ({
                url: `/pdfReports/today.php?answerType=1&holding=${holding}`,
                method: 'GET'
            })
        }),
        getReportChart: build.query<any, { holding: string }>({
            query: ({holding}) => ({
                url: `/pdfReports/today.php?answerType=2&holding=${holding}`,
                method: 'GET'
            })
        }),
        getAllReport: build.query<ReportResponse, { group: string, Start: string, Finish: string }>({
            query: ({group, Finish, Start}) => ({
                url: `/reports/dashboardData.php?group=${group}&Finish=${Finish}&Start=${Start}`
            }),
            transformResponse: ({data, filters}) => {
                return {data, filters};
            },
        })
    }),
    overrideExisting: true
});

export const {
    useGetRevenueQuery,
    useGetWriteOffQuery,
    useGetCostPriceQuery,

    useLazyGetRevenueQuery,
    useLazyGetWriteOffQuery,
    useLazyGetCostPriceQuery,

    useGetReportPdfQuery,
    useLazyGetReportPdfQuery,
    useGetReportChartQuery,
    useGetAllReportQuery
} = reportApi;
