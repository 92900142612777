import {
    BaseQueryFn,
    createApi,
    EndpointBuilder,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import {MiddlewareUploadFileResponse, ResponseStatuses, Status} from './types';
import {getGlobalAbortController} from './abortController';

export const BASE_URL: string = 'https://apifoodbox.ru/apiSite';

const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'same-origin',
    prepareHeaders: (headers, {getState}) => {
        const state = getState() as any;

        if (state?.auth?.user?.id_con) {
            headers.set('id_con', `${state?.auth?.user.id_con}`);
        }
        return headers;
    },
    fetchFn: async (input, init) => {
        try {
            return await fetch(input, {
                ...init,
                signal: getGlobalAbortController().signal,
            });
        } catch (error: any) {
            if (error.name === 'AbortError') {
                // console.log('Fetch aborted');
                // Возможно, вы захотите выполнить дополнительные действия при прерывании запроса
            } else {
                //   console.error('Fetch error:', error);
            }
            throw error;
        }
    },
});
//
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        // const state = api.getState() as any;
    }

    if (result.error && result.error.data) {
    }

    return result;
};

export const apiPush = createApi({
    reducerPath: 'api_push',
    baseQuery: fetchBaseQuery({baseUrl: 'https://api.ifbx.ru/api/v1'}),
    endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
        subscribe: build.mutation<{ message: string }, { newSubscription: PushSubscription, id_con: string }>({
            query: ({newSubscription, id_con}) => ({
                url: '/subscribe',
                method: 'POST',
                body: {newSubscription, id_con}
            })
        })
    })
});

export const {useSubscribeMutation} = apiPush;

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    refetchOnFocus: true,
    endpoints: build => ({
        getTaskStatuses: build.mutation<ResponseStatuses, void>({
            query: () => ({
                url: '/helpDesk/p_AppState.php',
                method: 'GET'
            }),
            transformResponse: ({status, list}): { status: boolean, list: Status[] } => {
                return {
                    status, list
                };
            }
        }),
        middlewareUploadFiles: build.mutation<MiddlewareUploadFileResponse, { path: string; body: FormData }>({
            query: ({path, body}) => ({
                url: path,
                method: 'POST',
                body
            })
        })
    })
});

export const {useGetTaskStatusesMutation, useMiddlewareUploadFilesMutation} = api;
