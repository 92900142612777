import React from 'react';
import {Task} from "../store/types";
import styles from '../style.module.scss';
import {checkDeadlineTask, formatDate} from "../../../helpers";
import classNames from "classnames";

interface TaskItemProps {
    item: Task;
    handleClick: (item: Task) => void
}

export const TaskItem = ({item, handleClick}: TaskItemProps) => {

    const date = new Date(item.DateApp.date.replace(' ', 'T'));

    return (
        <div
            className={styles.card}
            style={{borderColor: item.IsNotViewed ? '#613BE7' : '#DCE1EF',}}
            onClick={() => handleClick(item)}
        >
            <div className={styles.header}>
                <div className={styles.flex}>
                    <span className={styles.secondText}>№ {item.ID}</span>
                </div>
                <div className={styles.flex}>
                    <i className={classNames(styles.descriptionIcon, 'far fa-calendar-alt')}/>
                    <span className={styles.secondText}>{formatDate({date, type: 'DD.MM.YY'})}</span>
                </div>
            </div>
            <div className={classNames(styles.description)}>
                <div className={styles.descriptionItem}>
                    <i className={classNames(styles.descriptionIcon, 'far fa-lightbulb')}/>
                    <span className={styles.secondText}>{item.SectName}</span>
                </div>
                <div className={classNames(styles.descriptionItem)}>
                    <i className={classNames(styles.descriptionIcon, 'far fa-user')}/>
                    <span className={classNames(styles.secondText)}>{item.AuthorName}</span>
                    <span className={styles.descriptionSeparator}>{`->`}</span>
                    <span className={styles.secondText}>{item.SolverName}</span>
                </div>
            </div>

            <h1 className={styles.title}>{item.Name}</h1>
            <p className={classNames(styles.text)}>{item.Rem}</p>
            <div className={styles.footer}>
                <div className={styles.object}>
                    <i className={classNames(styles.descriptionIcon, 'fas fa-university')}/>
                    <span className={classNames(styles.secondText)}>{item.ObjName}</span>
                </div>
                <div>
                    <i className={classNames(styles.iClass, styles.iClassMr10, 'fas fa-bell')}
                       style={checkDeadlineTask({date: item.DateApp.date})}/>
                </div>
                <div className={styles.status}>
                    <span className={styles.statusText}>{item.StateName}</span>
                </div>
            </div>
        </div>
    );
};
