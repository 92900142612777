export const formattedDate = (currentDate: Date) => {

    let year = currentDate.getFullYear();
    let month: string | number = currentDate.getMonth() + 1;
    month = month < 10 ? `0${month}` : `${month}`;
    let day: string | number = currentDate.getDate();
    day = day < 10 ? '0' + day : day;

    return year + '-' + month + '-' + day;
};
