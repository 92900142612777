import {useAppDispatch} from './app.hooks';
import {setTheme} from '../store/app.slice';

export type ThemeVariable = 'sber' | 'gazprom' | 'spartak' | 'main'
export const themColors = {
    sber: {
        text: '#354235',
        color: '#4ca45c'
    },
    gazprom: {
        text: '#001821',
        color: '#0078c2'
    },
    spartak: {
        text: '#3d0101',
        color: '#CD0000'
    },
    main: {
        text: '#0D101C',
        color: '#C32B46'
    }
};

interface UseChangeTheme {
    setCssVariable: (name: string, value: string) => void;
    chaneTheme: (themeVariable: ThemeVariable) => void;
}

export const useChangeTheme = (): UseChangeTheme => {
    const dispatch = useAppDispatch();
    const root = document.documentElement;
    const setCssVariable = (name: string, value: string) => {
        root.style.setProperty(name, value);
    };

    const chaneTheme = (themeVariable: ThemeVariable) => {
        switch (themeVariable) {
            case 'sber':
                root.style.setProperty('--text-color', themColors.sber.text);
                root.style.setProperty('--main-color', themColors.sber.color);
                break;
            case 'spartak':
                root.style.setProperty('--text-color', themColors.spartak.text);
                root.style.setProperty('--main-color', themColors.spartak.color);
                break;
            case 'gazprom':
                root.style.setProperty('--text-color', themColors.gazprom.text);
                root.style.setProperty('--main-color', themColors.gazprom.color);
                break;
            default:
                root.style.setProperty('--text-color', themColors.main.text);
                root.style.setProperty('--main-color', themColors.main.color);
                break;
        }
        dispatch(setTheme(themeVariable));
    };

    return {setCssVariable, chaneTheme};
};
