import {mapResponsePhotoReader} from './mapResponsePhotoReader';

export {
    mapResponsePhotoReader
};

export const invoiceNumber = (text: string): string | null => {
    const invoiceNumberPattern = /№ (\d+)/;
    const invoiceNumberMatch = text.match(invoiceNumberPattern);
    return invoiceNumberMatch ? invoiceNumberMatch[1] : null;
};

export const invoiceDate = (text: string): string | null => {
    const datePattern = /от (\d{1,2} [а-яё]+ \d{4}|\d{4}\.\d{2}\.\d{2}|\d{2}\.\d{2}\.\d{2}|\d{2}\.\d{2}\.\d{4})/i;
    const dateMatch = text.match(datePattern);
    return dateMatch ? dateMatch[1] : null;
};
