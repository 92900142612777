const questions = require('../assets/images/home/questions.png');
const tasks = require('../assets/images/home/tasks.png');
const reports = require('../assets/images/home/reports.png');
const purchase = require('../assets/images/home/purchase.png');
const info = require('../assets/images/home/info.png');
const profile = require('../assets/images/home/profile.png');

export interface ButtonOpt {
    src: string;
    name: string;
    id: number;
    path: string;
}

export const routesConfig: ButtonOpt[] = [
    {src: questions, id: 1, name: 'Опросы', path: '/questions'},
    {src: tasks, id: 2, name: 'Задачи', path: '/'},
    {src: reports, id: 3, name: 'Отчеты', path: '/'},
    {src: purchase, id: 4, name: 'Закупка', path: '/'},
    {src: info, id: 5, name: 'Информация', path: '/'},
    {src: profile, id: 6, name: 'Профиль', path: '/profile'},
];
