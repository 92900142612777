import {api} from "../../../store/api";
import {Message} from "./types";
import {CONTENT_TYPE_URLENCODED} from "../../../utils/constants";

export const chatApi = api.injectEndpoints({
    endpoints: build => ({
        getMessages: build.query<{ listMessage: Message[] }, { body: string }>({
            query: ({body}) => ({
                url: '/helpDesk/p_AppNote.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({listMessage}) => {
                return {listMessage};
            },
            keepUnusedDataFor: 0
        }),
        sendMessage: build.mutation<void, { id_con?: string, body: FormData }>({
            query: ({body}) => ({
                url: '/helpDesk/r_AppNote.php',
                method: 'POST',
                body,
            })
        }),
    }),
    overrideExisting: true
});

export const {
    useSendMessageMutation,
    useGetMessagesQuery,
    useLazyGetMessagesQuery,
} = chatApi;
