import React from 'react';

export const NotificationIcon = ({color = '#000000'}: { color: string }) => {
    return (
        <svg version="1.0" width="26.000000pt" height="20.000000pt" viewBox="0 0 36.000000 30.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)"
               fill={color} stroke="none">
                <path d="M127 247 c-18 -14 -30 -38 -37 -67 -5 -25 -12 -57 -16 -72 -6 -25 -3
-27 32 -34 22 -4 55 -8 74 -8 19 0 52 4 74 8 35 7 38 9 32 34 -4 15 -11 47
-15 71 -15 80 -87 114 -144 68z"/>
                <path d="M145 40 c3 -5 19 -10 35 -10 16 0 32 5 35 10 4 6 -10 10 -35 10 -25
0 -39 -4 -35 -10z"/>
            </g>
        </svg>
    );
};
