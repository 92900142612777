import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './style.module.scss';
import {Button} from '../../fields/button';
import {mainColor} from '../../utils/constants';
import {Input} from '../../fields/input';
import {TextareaControl} from '../../fields/textarea';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {
    useActionDeviceCheckMutation,
    useActionDeviceMutation,
    useUploadDeviceFileMutation
} from './store/inventory.api';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {IInventory} from './store/types';
import {changeCreateStep, defaultInventoryForm, setInventoryForm} from './store/inventory.slice';
import {DeviseState} from './components';
import {fromJsonToUrlencoded} from '../../helpers';
import {UploadFiles} from '../../components/uploadFiles';
import {FileFromBack} from '../quiz/store/types';
import {toast} from 'react-toastify';
import {Scanner} from '@yudiel/react-qr-scanner';
import {scannerFormats} from './ScannerPage';

const schema = yup.object().shape({
    Type: yup.mixed().required().default('Insert'),
    ID: yup.mixed(),
    ID_Device: yup.mixed(),
    DevType: yup.mixed().required('Обязательно заполнить'),
    Name: yup.mixed().required('Обязательно заполнить'),
    Code: yup.mixed().required('Обязательно заполнить'),
    Code1S: yup.mixed(),
    Rem: yup.mixed(),
    BalCost: yup.mixed(),
});

export const InventoryCreateForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [actionDevice, {isLoading: loadingMiddle}] = useActionDeviceMutation();
    const [actionDeviceCheck] = useActionDeviceCheckMutation();
    const [uploadFile, {isLoading: loadingFile}] = useUploadDeviceFileMutation();

    const {currentObj, inventoryForm, createStep} = useAppSelector(state => state.inventory);

    const {setValue, watch, control, handleSubmit, clearErrors, reset} = useForm<any>({
        mode: 'onChange',
        defaultValues: inventoryForm,
        resolver: yupResolver(schema)
    });

    const [openScan, setOpenScan] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData | null>(null);
    const [backFiles, setFiles] = useState<FileFromBack[]>([]);
    const [location, setLocation] = useState({latitude: 0, longitude: 0});


    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handleSuccessPosition, handleErrorPosition);
        }

        return () => {

            reset();
        };
    }, []);


    const handleOnScan = (result: any) => {
        if (result) {
            const value = result[0].rawValue;
            setValue('Code', value);
            setOpenScan(false);

        }
    };

    const handleSuccessPosition = (position: GeolocationPosition) => {
        const {latitude, longitude} = position.coords;
        setLocation({latitude, longitude});
    };

    const handleErrorPosition = (error: any) => {

    };

    const handleBack = () => {
        reset();
        dispatch(setInventoryForm(defaultInventoryForm));
        dispatch(changeCreateStep(1));
        navigate(-1);
    };

    const handleSetValue = (field: keyof IInventory, value: string) => {
        clearErrors(field);
        setValue(field, value.length === 0 ? undefined : value);

        dispatch(setInventoryForm({...inventoryForm, [field]: value}));
    };

    const onSubmitMiddle = async (values: IInventory) => {
        const initSave = {
            Type: 'Insert',
            DevType: values.DevType,
            Name: values.Name,
            Code: values.Code,
            Code1S: values.Code1S,
            Rem: values.Rem,
            BalCost: values.BalCost,
        };
        actionDevice({
            body: fromJsonToUrlencoded({data: initSave})
        })
            .unwrap()
            .then(({status, info}) => {
                if (status) {
                    setValue('ID_Device', info.ID);
                    dispatch(changeCreateStep(2));
                }
            });
    };

    const onSubmit = async (values: IInventory) => {
        const finalitySave = {
            Type: 'Insert',
            ID_Device: values.ID_Device,
            ID_Obj: currentObj?.id,
            State: values.State,
            EstimCost: values.BalCost,
            Cnt: 1,
            Latitude: location.latitude,
            Longitude: location.longitude
        };

        if (formData) {
            formData.append('ID_Tab', String(values.ID_Device));
            uploadFile({formData}).unwrap().then(() => {
                sendData(values, finalitySave);
            });
        } else {
            sendData(values, finalitySave);
        }
    };

    const sendData = (values: IInventory, finalitySave: any) => {
        actionDeviceCheck({body: fromJsonToUrlencoded({data: finalitySave})})
            .unwrap()
            .then(() => {
                toast.info(`Устройство ${values.Name}, успешно добавлено.`, {
                    onClose: () => handleBack()
                });
            })
            .catch((error) => {
                toast.error(error.message);
            });
    };

    return (
        <MainLayout>
            <Header
                breadCrumbs={watch('ID')
                    ? `Редактировать устройство в ${currentObj?.name}`
                    : `Добавить устройство в ${currentObj?.name}`}
            />
            <BackButton
                text={'Назад'}
                onClick={handleBack}
            />
            {openScan
                ? (<>
                    <div className={styles.scannerWrapper}>
                        <Scanner
                            classNames={{
                                container: 'scanner-frame'
                            }}
                            formats={scannerFormats}
                            onScan={(barcode) => handleOnScan(barcode)}
                        />
                    </div>
                    <div className={styles.inventoryFormScanBack}>
                        <Button
                            text={'Заполнить форму'}
                            textStyleType={'regular'}
                            style={{border: `1px solid ${mainColor}`, borderRadius: '8px'}}
                            onClick={(event) => {
                                event.preventDefault();
                                setOpenScan(false);
                            }}
                        />
                    </div>
                </>) : (
                    <form className={styles.inventoryForm}>
                        <div className={styles.inventoryFormBox}>
                            <label className={styles.inventoryFormLabel}>Тип устройства</label>
                            <Input
                                type={'text'}
                                fieldName={'DevType'}
                                control={control}
                                defaultValue={watch('DevType')}
                                onChange={value => handleSetValue('DevType', String(value))}/>
                        </div>
                        <div className={styles.inventoryFormBox}>
                            <label className={styles.inventoryFormLabel}>Название устройства (модель)</label>
                            <Input
                                type={'text'}
                                fieldName={'Name'}
                                control={control}
                                defaultValue={watch('Name')}
                                onChange={value => handleSetValue('Name', String(value))}/>
                        </div>
                        <div className={styles.inventoryFormBox}>
                            <label className={styles.inventoryFormLabel}>Штрихкод</label>
                            <div className={styles.inventoryFormRow}>
                                <div className={styles.inventoryFormScan}>
                                    <Input
                                        type={'text'}
                                        fieldName={'Code'}
                                        control={control}
                                        defaultValue={watch('Code')}
                                        onChange={value => handleSetValue('Code', String(value))}
                                    />
                                </div>
                                <div className={styles.inventoryFormScan}>
                                    <Button
                                        text={'Сканировать'}
                                        textStyleType={'regular'}
                                        style={{border: `1px solid ${mainColor}`, borderRadius: '8px'}}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setOpenScan(true);
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className={styles.inventoryFormBox}>
                            <label className={styles.inventoryFormLabel}>Код 1С</label>
                            <Input
                                type={'text'}
                                fieldName={'Code1S'}
                                control={control}
                                defaultValue={watch('Code1S')}
                                onChange={value => handleSetValue('Code1S', String(value))}/>
                        </div>
                        <div className={styles.inventoryFormBox}>
                            <label className={styles.inventoryFormLabel}>Примечание</label>
                            <TextareaControl
                                fieldName={'Rem'}
                                control={control}
                                defaultValue={watch('Rem')}
                                onChange={value => handleSetValue('Rem', String(value))}/>
                        </div>
                        <div className={styles.inventoryFormBox}>
                            <label className={styles.inventoryFormLabel}>Балансовая стоимость</label>
                            <Input
                                type={'number'}
                                fieldName={'BalCost'}
                                control={control}
                                defaultValue={watch('BalCost')}
                                onChange={value => handleSetValue('BalCost', String(value))}/>
                        </div>
                        {createStep === 2
                            ? (
                                <>
                                    <div className={styles.inventoryFormBox}>
                                        <UploadFiles
                                            isLoading={loadingFile}
                                            appendObj={null}
                                            viewFiles={backFiles}
                                            handleBase64={baseFile => {
                                                setFiles([...backFiles, baseFile]);
                                            }}
                                            handleCallback={setFormData}/>
                                    </div>
                                    <DeviseState state={watch('State')} onChange={state => setValue('State', state)}/>
                                </>)
                            : null}
                        <div className={styles.inventoryFormBox}>
                            {loadingMiddle ?
                                <Spin indicator={<LoadingOutlined spin style={{color: '#fff'}}/>} size="large"
                                      style={{height: '54px'}}/> :
                                <Button
                                    text={'Сохранить'}
                                    textStyleType={'semiBold'}
                                    style={{
                                        border: `1px solid ${mainColor}`,
                                        borderRadius: '8px',
                                        width: '100%',
                                        height: '54px'
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        createStep === 2
                                            ? handleSubmit(onSubmit)()
                                            : handleSubmit(onSubmitMiddle)();
                                    }}
                                />}
                        </div>
                    </form>
                )}
        </MainLayout>
    );
};
