import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {useLocation, useNavigate} from 'react-router-dom';
import {BackButton} from '../../components/backButton';
import styles from './style.module.scss';
import {SwitcherTab} from '../../components/switcherTab';
import {fromJsonToUrlencoded} from '../../helpers';
import {HeaderText, Success} from './components';
import {useGetPermissionTaskQuery, useGetSignaturesMutation, useLazyGetTaskByIdQuery,} from './store/task.api';
import {ITask, Signature} from './store/types';
import {Modal} from '../../components/modal';
import dayjs from 'dayjs';
import {Conditions, FormTask, Signatures} from './tabs';
import {ChatRoom} from '../../components/chat';
import {IPermission} from '../../store/types';
import AppLoader from '../../components/appLoader';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {setCreatedTask, switchTab} from './store/task.slice';
import {toast} from 'react-toastify';

export const excludeStatusForChangeTask: number[] = [3, 4, 7];

const buttonTabs = ['Задача', 'Условия', 'Подписи', 'Чат'];

export const TaskCreate = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {state} = useLocation();

    const {data: permission} = useGetPermissionTaskQuery({ID_App: state?.ID_App}, {
        skip: !state?.ID_App
    });

    const [getTaskById, {data: task, isLoading}] = useLazyGetTaskByIdQuery();
    const [getSignatures, {data: signatures}] = useGetSignaturesMutation();
    const {currentTab} = useAppSelector(state => state.taskSwitch);
    const [isOpen, setOpen] = useState<boolean>(false);

    const [loaderChangeSignature, setLoaderChangeSignature] = useState<boolean>(false);

    useEffect(() => {
        if (state?.ID_App) {
            getTaskById({ID_App: state.ID_App});
        }
    }, []);

    useEffect(() => {
        if (task && !state?.claim && !state?.isNew) {
            getSignatures({body: fromJsonToUrlencoded({data: {ID_App: task.ID, ID_Sect: task.ID_Sect}})});
        }
    }, [currentTab]);

    useEffect(() => {
        if (task === null) {
            toast.error('Задачи с таким номером не существует.');
            dispatch(setCreatedTask(null));
            navigate(-1);
        }
    }, [task]);

    const handleConfirm = () => {
        setOpen(false);
    };

    const handleRefetch = (ID: number) => {
        getTaskById({ID_App: state.ID_App ?? ID});
    };

    const renderTab = () => {
        switch (currentTab) {
            case 2:
                return (
                    <Signatures
                        isEdit={!excludeStatusForChangeTask.includes(Number(task?.ID_State))}
                        signatures={signatures?.listVotes as Signature[]}
                        isLoading={loaderChangeSignature}
                        ID_Sect={String(task?.ID_Sect)}
                        ID_Obj={String(task?.ID_Obj)}
                        ID_App={String(task?.ID)}
                        onChange={() => {
                            setLoaderChangeSignature(true);
                            getSignatures({
                                body: fromJsonToUrlencoded({
                                    data: {
                                        ID_App: String(task?.ID),
                                        ID_Sect: String(task?.ID_Sect)
                                    }
                                })
                            })
                                .unwrap()
                                .finally(() => setLoaderChangeSignature(false));
                        }}
                    />);
            case 1:
                return <Conditions task={task as ITask}
                                   isEdit={!excludeStatusForChangeTask.includes(Number(task?.ID_State))}/>;
            case 3:
                return <ChatRoom ID_App={String(task?.ID)}/>;
            default:
                return <FormTask
                    task={task as ITask}
                    handleChangeStateModal={setOpen}
                    permission={permission as IPermission}
                    refetchTask={handleRefetch}
                />;
        }
    };

    return (
        <MainLayout>
            <Header
                innerText={!state?.isNew ?
                    <HeaderText
                        ID_App={state.ID_App}
                        date={
                            state?.date
                                ? dayjs(state.date).format('D MMM YY')
                                : dayjs(task?.DI_str, 'DD.MM.YYYY').format('D MMM YY')
                        }
                        AuthorName={state.AuthorName}/>
                    : <span>Новая задача</span>}
                breadCrumbs={!state ? 'Новая задача' : undefined}
            />
            <BackButton
                text={'Назад'}
                onClick={() => {
                    dispatch(setCreatedTask(null));
                    navigate(-1);
                }}/>
            <div className={styles.form}>
                {state && !state?.claim && task
                    ? <SwitcherTab
                        pageType={'task'}
                        buttons={buttonTabs}
                        isActive={currentTab}
                        skip={permission}
                        onClick={index => {
                            dispatch(switchTab(index));
                        }}
                    /> : null}
                {isLoading ? <AppLoader isFull={false}/> : renderTab()}

            </div>
            <Modal isOpen={isOpen}>
                <Success
                    title={state ? 'Успешно изменено' : 'Новая задача'}
                    text={state ? 'Новые данные в задаче' : 'По Вашему обращению создана задача'}
                    onConfirm={handleConfirm}
                    numberTask={task?.ID ?? 0}/>
            </Modal>
        </MainLayout>
    );
};
