import React, {useEffect, useRef, useState} from 'react';
import {Button} from '../../fields/button';
import {MainLayout} from '../../layouts';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import styles from './style.module.scss';
import {useAppDispatch} from '../../hooks/app.hooks';
import {setTemporaryDataFile} from './store/camera.slice';
import {useUploadFileMutation} from '../quiz/store/quiz.api';
import {generateRandomId} from '../../helpers/generateRandomId';
import Webcam from 'react-webcam';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const videoConstraints = {
    facingMode: FACING_MODE_USER
};

export const Camera = () => {
    const navigate = useNavigate();
    const {answerId} = useParams<{ answerId: string }>();
    const dispatch = useAppDispatch();
    const {state} = useLocation();
    const [uploadFile] = useUploadFileMutation();
    const webcamRef = useRef<any>(null);
    const [fileBase64, setSource] = useState<string>('');

    const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

    const handleClick = React.useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_USER
                    ? FACING_MODE_ENVIRONMENT
                    : FACING_MODE_USER
        );
    }, []);

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false
        })
            .then(function (stream) {
                // Доступ к камере разрешен, можно использовать поток видео
            })
            .catch(function (error) {
                console.error('Ошибка getUserMedia: ', error);
                // Обработка ошибки
            });
    }, []);

    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef?.current?.getScreenshot() as string;
            setSource(imageSrc);
        },
        [webcamRef]
    );

    const handleSave = () => {
        const fileName: string = `${generateRandomId(10)}_${answerId}.jpeg`;
        const file = {
            fileName,
            fileBase64
        };
        uploadFile({
            file
        }).unwrap().then(async (result) => {
            await sessionStorage.removeItem('isFile');
            if (result.status) {
                dispatch(setTemporaryDataFile(result));
                navigate(state.backPath, {state: {...state}});
            }
        });
    };

    return (
        <MainLayout>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.viewContent}>
                {
                    fileBase64?.length
                        ? (
                            <>
                                <img src={fileBase64} alt="send attachment" className={styles.image}/>
                                <div className={styles.screenshotButton}>
                                    <Button
                                        text={'Подтвердить'}
                                        textStyleType={'regular'}
                                        style={{}}
                                        onClick={handleSave}/>
                                </div>
                            </>
                        ) : (
                            <>
                                <>
                                    <Webcam
                                        ref={webcamRef}
                                        audio={false}
                                        width={window.innerWidth}
                                        height={window.innerHeight}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={{
                                            ...videoConstraints,
                                            facingMode
                                        }}
                                        mirrored={false}
                                    />
                                    <div className={styles.screenshotButton}>
                                        <Button
                                            text={facingMode === FACING_MODE_USER ? 'Выбрать заднюю камеру' : 'Выбрать фронтальную камеру'}
                                            textStyleType={'regular'}
                                            style={{}}
                                            onClick={handleClick}/>
                                        <div style={{margin: '5px 0px'}}/>
                                        <Button
                                            text={'Сделать фото'}
                                            textStyleType={'regular'}
                                            style={{}}
                                            onClick={capture}/>
                                    </div>

                                </>
                                {/*{*/}
                                {/*    cameraStatus === 'READY'*/}
                                {/*        ? (*/}
                                {/*            <>*/}
                                {/*                <Webcam*/}
                                {/*                    ref={webcamRef}*/}
                                {/*                    audio={false}*/}
                                {/*                    width={300}*/}
                                {/*                    height={300}*/}
                                {/*                    screenshotFormat="image/jpeg"*/}
                                {/*                    // videoConstraints={videoConstraints}*/}
                                {/*                    style={{*/}
                                {/*                        backgroundColor: 'pink'*/}
                                {/*                    }}*/}
                                {/*                    mirrored={false}*/}
                                {/*                />*/}
                                {/*                <div className={styles.screenshotButton}>*/}
                                {/*                    <Button*/}
                                {/*                        text={'Сделать фото'}*/}
                                {/*                        textStyleType={'regular'}*/}
                                {/*                        style={{}}*/}
                                {/*                        onClick={capture}/>*/}
                                {/*                </div>*/}
                                {/*            </>*/}
                                {/*        ) : (*/}
                                {/*            <p>Перейдите в настройки браузера<br/>и разрешите доступ к камере.</p>*/}
                                {/*        )*/}
                                {/*}*/}


                            </>
                        )
                }
            </div>
        </MainLayout>
    );
};
