import {Option} from "../../../store/types";

export type FilterItemType =
    'FAuthors'
    | 'FPayStates'
    | 'FObjects'
    | 'FCompanies'
    | 'Search'
    | 'WantStart'
    | 'WantFinish';

export type FinancialFilterItemType =
    'FAuthors'
    | 'FPayStates'
    | 'FObjects'
    | 'FKontras'
    | 'Search'
    | 'WantStart'
    | 'WantFinish'
    | 'Payer';

export const FinancialFilterItemReq: any = {
    FAuthors: 'FAuthors',
    FPayStates: 'FPayStates',
    FObjects: 'FObjects',
    FKontras: 'FKontras',
    Payer: 'Payer',
    WantStart: 'WantStart',
    WantFinish: 'WantFinish',
    Search: 'Search'
};

export interface IFinancialFilter {
    type: FinancialFilterItemType | 'index',
    value: string
};

export interface FinancialFilterSlice {
    keys: IFinancialFilter[];
    activeIndicator: boolean;
    company: Option[]
};

export type ListIdName = { id: number, name: string };

export interface TreeNode {
    title: string;
    value: string;
    key: string;
    children?: TreeNode[];
}

export interface CompanyReqItem {
    id: number;
    name: string;
    inn: string;
    specName: string;
}
