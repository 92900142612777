import React, {HTMLAttributes} from 'react';
import styles from './style.module.scss';
import {ImageLayout} from "../../layouts";
import classNames from "classnames";

interface ButtonCardProps extends HTMLAttributes<HTMLDivElement> {
    name: string;
    src?: string;
    type: string;
    iconClass: string;
    iconWidth?: number;
    iconHeight?: number;
    onClick: () => void;
}

const ButtonCard = ({name, src, type, iconClass, iconHeight, iconWidth, onClick, ...rest}: ButtonCardProps) => {
    return (
        <div
            {...rest}
            className={rest.className ? classNames(rest.className, styles.card) : styles.card}
            onClick={onClick}
        > {
            type !== 'fontawesome' && src && iconHeight && iconWidth ? (
                <ImageLayout
                    src={src}
                    imageWidth={iconWidth}
                    imageHeight={iconHeight}
                    containerWidth={iconWidth}
                    containerHeight={iconHeight}
                />
            ) : <i className={classNames(styles.iconAwesome, `${iconClass}`)}/>
        }

            <p className={styles.text}>{name}</p>
        </div>
    );
};

export default ButtonCard;
