interface FormatDateProps {
    date: Date;
    type?: 'YYYY-MM-DD' | 'DD.MM.YY';
}

export const formatDate = ({date, type}: FormatDateProps) => {
    if (type && type === 'YYYY-MM-DD') {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);

        return year + '-' + month + '-' + day;
    }

    if (type && type === 'DD.MM.YY') {
        const year = date.getFullYear().toString().substr(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);

        return day + '.' + month + '.' + year;
    }

    return date
        .toLocaleDateString('ru', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        })
        .replace(/г\.$/, '');
};
