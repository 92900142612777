import React from 'react';
import styles from '../style.module.scss';

interface HeaderTextProps {
    ID_App: string;
    date: string;
    AuthorName: string;
}

export const HeaderText = ({ID_App, AuthorName, date} :HeaderTextProps) => {
    return (
        <div className={styles.headerText}>
            <span className={styles.headerSpan}>№ {ID_App} от {date}</span>
            <span className={styles.headerSpan}>Автор {AuthorName}</span>
        </div>
    );
};
