export const extractValueFromString = (inputString: string, key: string) => {
    const urlParts = inputString.split("?");
    if (urlParts.length < 2) {
        return null;
    }

    const queryParams = urlParts[1].split("&");
    for (let i = 0; i < queryParams.length; i++) {
        const pair = queryParams[i].split("=");
        if (pair[0] === key) {
            return decodeURIComponent(pair[1].replace(/\+/g, " "));
        }
    }

    return null;
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
