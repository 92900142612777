export const updateArray = (inputObject: any, array: any, type: string) => {
    let updateArray = [...array];
    const index = array.findIndex((item: any) => item.id === inputObject.id);

    if (index !== -1) {
        // Если объект с таким же id найден, заменяем его или удаляем
        array[index].value === inputObject.value && type === 'buttons'
            ? updateArray.splice(index, 1)
            : updateArray.splice(index, 1, inputObject);
    } else {
        // Если объект с таким же id не найден, добавляем в конец массива
        updateArray.push(inputObject);
    }

    return updateArray;
};
