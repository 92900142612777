import {api} from "../../../store/api";
import {CONTENT_TYPE_URLENCODED} from "../../../utils/constants";
import {IInventory, InventoryItem} from "./types";

export const inventoryApi = api.injectEndpoints({
    endpoints: build => ({
        getDeviceList: build.query<{ listDevices: InventoryItem[] }, { params: string }>({
            query: ({params}) => ({
                url: `/storage/p_DeviceList.php?${params}`,
                method: 'GET',
                headers: {
                    ...CONTENT_TYPE_URLENCODED
                }
            }),
            transformResponse: ({listDevices}) => {
                return {listDevices};
            },
            keepUnusedDataFor: 0
        }),
        actionDevice: build.mutation<{ info: IInventory, status: boolean }, { body: string }>({
            query: ({body}) => ({
                url: '/storage/r_Device.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED
                }
            }),
            transformResponse: ({info, status}) => {
                return {info, status};
            }
        }),
        actionDeviceCheck: build.mutation<{ info: IInventory, status: boolean }, { body: string }>({
            query: ({body}) => ({
                url: '/storage/r_DeviceCheck.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED
                }
            }),
            transformResponse: ({info, status}) => {
                return {info, status};
            }
        }),
        uploadDeviceFile: build.mutation<any, { formData: FormData }>({
            query: ({formData}) => ({
                url: '/storage/fileAdd.php',
                method: 'POST',
                body: formData
            })
        }),
        getDeviceFiles: build.query<any, { ID_Tab: string }>({
            query: ({ID_Tab}) => ({
                url: `/storage/files.php?ID_Tab=${ID_Tab}`,
                method: 'GET'
            })
        })
    }), overrideExisting: true
});

export const {
    useGetDeviceListQuery,
    useLazyGetDeviceListQuery,
    useActionDeviceMutation,
    useActionDeviceCheckMutation,
    useUploadDeviceFileMutation,
    useLazyGetDeviceFilesQuery
} = inventoryApi;
