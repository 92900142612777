import React, {ReactNode} from 'react';
import {useCollapse} from "react-collapsed";
import {ArrowDrop, NoteBook} from "../../../assets/svg";
import styles from '../style.module.scss';
import classNames from "classnames";

interface CustomCollapseProps {
    children: ReactNode;
    title: string;
}

export const CustomCollapse = ({children, title}: CustomCollapseProps) => {
    const {getCollapseProps, getToggleProps, isExpanded} = useCollapse();

    return (
        <div className={styles.collapse}>
            <div {...getToggleProps()} className={styles.collapseButton}>
                <NoteBook/>
                <p className={styles.collapseName}>{title}</p>
                <span
                    className={isExpanded ? classNames(styles.animIcon, styles.animIconOpen) : styles.animIcon}><ArrowDrop/></span>
            </div>
            <div {...getCollapseProps()}>
                <div>{children}</div>
            </div>
        </div>
    );
};
