import React, {useState} from 'react';
import styles from './style.module.scss';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {ImageLayout} from '../../layouts';
import ReactFileReader from 'react-file-reader';
import {FileFromBack} from '../../screens/quiz/store/types';

const plusIcon = require('../../assets/images/plus.png');

interface UploadFilesProps {
    isLoading: boolean;
    multiple?: boolean;
    appendObj: { [key: string]: string } | null;
    viewFiles: FileFromBack[];
    handleCallback: (formData: FormData) => void;
    handleBase64: (baseFile: FileFromBack) => void;
}

export const UploadFiles = (
    {
        isLoading,
        handleCallback,
        handleBase64,
        multiple = false,
        appendObj,
        viewFiles
    }: UploadFilesProps
) => {
    const [url, setUrl] = useState<string>('');
    const [showFile, setShowFile] = useState<boolean>(false);

    const handleFiles = (files: any) => {
        const {fileList, base64} = files;
        const formData = new FormData();

        if (appendObj !== null) {
            for (const key in appendObj) {
                formData.set(key, appendObj[key]);
            }
        }

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            formData.append('load_files[]', file, file.name);
            const baseFile: FileFromBack = {
                id: String(i + 1),
                fileName: file.name,
                origName: file.name,
                base64
            };
            handleBase64(baseFile);
        }

        handleCallback(formData);
    };


    return (
        <div className={styles.box}>
            <ReactFileReader
                fileTypes={['.png', '.jpg', '.jpeg']}
                multipleFiles={false}
                base64
                handleFiles={(files: any) => {
                    handleFiles(files);
                }}>
                <div className={styles.wrapReaderButton}>
                    <button className={styles.fileReaderButton} onClick={async (e) => {
                        e.preventDefault();
                        await sessionStorage.setItem('isFile', 'OK');
                    }}>
                        {isLoading ?
                            <Spin indicator={<LoadingOutlined style={{fontSize: 14}} spin/>}/>
                            : <ImageLayout
                                src={plusIcon}
                                imageWidth={24}
                                imageHeight={24}
                                containerWidth={24}
                                containerHeight={24}/>}
                    </button>
                    <span className={styles.minText}>Доб. файл</span>
                </div>
            </ReactFileReader>
            {viewFiles.length > 0
                ? <div className={styles.list}>
                    {viewFiles.map((file, index) => (
                        <div key={index} className={styles.wrapReaderButton} onClick={() => {
                            file?.url
                                ? setUrl(file.url)
                                : setUrl(file.base64 as string);

                            setShowFile(true);
                        }}>
                            <span key={`${index}-${file}`} className={styles.fileReaderButton}>
                                <ImageLayout
                                    src={file?.url ? file.url : file.base64 as string}
                                    imageWidth={36}
                                    imageHeight={36}
                                    containerWidth={36}
                                    containerHeight={36}/>
                            </span>
                            <span className={styles.minText}>{file.origName}</span>
                        </div>
                    ))}
                </div>
                : null}
            {showFile ?
                <div className={styles.modalFile}>
                    <span
                        className={styles.close}
                        onClick={() => {
                            setUrl('');
                            setShowFile(false);
                        }}
                    />
                    <div className={styles.modalFileContent}>
                        <img className={styles.showImage} src={url} alt={'attachment'}/>
                    </div>

                </div> : null}
        </div>
    );
};
