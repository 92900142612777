import {api} from '../../../store/api';

const testApi = api.injectEndpoints({
    endpoints: build => ({
        getFileData: build.mutation<any, { fileValue: string }>({
            query: ({fileValue}) => ({
                url: `/testFunction/getFiles.php?file=${fileValue}`,
                method: 'GET',
                responseHandler: (response) => response.blob()
            })
        })
    }), overrideExisting: true
});

export const {
    useGetFileDataMutation
} = testApi;
