import React, {useState} from 'react';
import {MainLayout, QuestionVariable} from '../../layouts';
import Header from '../../components/header';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {getObjectByIdForm} from '../../helpers';
import {BackButton} from '../../components/backButton';
import Footer from '../../components/footer';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {ListMenu} from '../home/store/types';
import {Modal} from '../../components/modal';
import {Button} from '../../fields/button';
import {mainColor} from '../../utils/constants';
import {clearAnswers} from '../quiz/store/quiz.slice';
import styles from './style.module.scss';
import {typeFlow} from '../../store/types';
import {setChangeReportIndex} from '../report/store/report.slice';
import {QuizLocationState} from '../quiz/Quiz';
import {defaultSortColumn, setSortColumn} from '../tableView/store/table.slice';

export const CheckTypesScreen = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {idForm} = useParams<{ idForm: string }>();
    const {answers, currentLocation} = useAppSelector(state => state.quiz);
    const {key} = useAppSelector(state => state.table.sortColumn);
    const {data} = useGetAllQuestionsQuery();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [current, setCurrent] = useState<ListMenu | null>(null);

    const viewButtons = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm)) as ListMenu;
    const parentName = viewButtons?.name;

    const handlePress = async (item: ListMenu) => {

        const reportsFlow = [typeFlow.mainValuesCostPriceProc, typeFlow.mainValuesCostPrice, typeFlow.mainValuesRevenue, typeFlow.mainValuesWriteOff];
        if (reportsFlow.includes(item.typeMob)) {
            const initIndexCase = [typeFlow.mainValuesWriteOff, typeFlow.mainValuesCostPrice];
            dispatch(setChangeReportIndex(initIndexCase.includes(item.typeMob) ? 1 : 0));

            return navigate(`/report?typeMob=${item.typeMob}`);
        }

        if (item.typeMob === typeFlow.FotoRepList) {
            return navigate(`/select-location/${item.idForm}?from=${item?.typeMob}`, {
                state: {idParent: item.idParent, path: item.file}
            });
        }

        if (item.typeMob === typeFlow.FotoRepNew) {
            return navigate(`/select-location/${item.idForm}?from=${item?.typeMob}`, {
                state: {idParent: item.idParent, path: item.file}
            });
        }

        if (item.typeMob === typeFlow.SCTGList) {
            return navigate('/quiz-list');
        }

        if (item.typeMob === typeFlow.DPTGList) {
            return navigate('/quiz-list');
        }

        if (item.typeMob === typeFlow.botRepV) {
            if (item.file !== null) {
                const filePath = item.file.replace('answerType=1', 'answerType=2');
                return navigate('/view-table', {
                    state: {
                        path: `/pdfReports/${filePath}`,
                        screenViewName: `${parentName} - ${item.name}`
                    }
                });
            }
            return;
        }

        if (item?.file?.includes('listQuestions.php')) {
            // кейс с неоконченным опросом
            if (answers.length > 0) {
                setIsOpen(true);
                setCurrent(item);
            } else {
                const parent = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(item.idParent));
                if (item.typeMob === typeFlow.LearnChecksNoLoc) {
                    return navigate(`/select-location/${item.idForm}?from=${item.typeMob}`, {
                        state: {
                            path: item.file,
                            isEmployee: true
                        } as QuizLocationState
                    });

                }
                console.log('this case', item);
                return navigate(`/select-location/${item.idForm}?from=${parent?.name}`, {
                    state: {idParent: item.idParent, path: item.file}
                });
            }
        }

        if (item?.file?.includes('pdf')) {
            return navigate('/view-pdf', {
                state: {path: item.file}
            });
        }
    };

    const onCansel = () => {
        dispatch(clearAnswers());
        setIsOpen(false);
    };
    const onConfirm = () => {
        if (current) {
            setIsOpen(false);
            const parent = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(current.idParent));
            navigate(`/quiz`, {
                state: {
                    idObj: currentLocation?.id,
                    parentName: parent?.name,
                    path: current.file,
                    locationName: currentLocation?.name,
                    isEmployee: false
                } as QuizLocationState
            });
        }
    };

    const handleBack = () => {
        if (key !== '') {
            dispatch(setSortColumn(defaultSortColumn));
        }
        navigate(-1);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={parentName}/>
            <BackButton text={`Назад`} onClick={handleBack}/>
            <QuestionVariable
                renderData={viewButtons?.children ?? []}
                handlePress={handlePress}
            />
            <Footer/>
            <Modal isOpen={isOpen}>
                <div className={styles.modal}>
                    <p className={styles.modalTitle}>У вас есть незаконченный опрос</p>
                    <p className={styles.modalText}>Нажимая кнопку "Нет" вы удалите все данные незаконченного
                        опроса.</p>
                    <p className={styles.modalQuestion}>Продолжить?</p>
                    <div className={styles.modalButtons}>
                        <div className={styles.modalButtonsBox}>
                            <Button
                                text={'Нет'}
                                textStyleType={'regular'}
                                onClick={onCansel}
                                style={{
                                    border: `1px solid ${mainColor}`,
                                    borderRadius: '12px',
                                    fontSize: '14px',
                                    height: '44px'
                                }}
                            />
                        </div>
                        <div className={styles.modalButtonsBox}>
                            <Button
                                text={'Да'}
                                textStyleType={'regular'}
                                onClick={onConfirm}
                                disabled={false}
                                style={{
                                    border: `1px solid ${mainColor}`,
                                    borderRadius: '12px',
                                    fontSize: '14px',
                                    height: '44px'
                                }}/>
                        </div>
                    </div>
                </div>
            </Modal>
        </MainLayout>
    );
};
