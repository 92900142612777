import React from 'react';
import styles from '../style.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import {DatePicker, TimeRangePickerProps} from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import {UnmountClosed} from 'react-collapse';
import {SelectFormRow} from '../../../components/selectFormRow';
import {IActionFilter} from '../store/types';
import {Option} from '../../../store/types';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale('ru');

const {RangePicker} = DatePicker;
const today = dayjs();

const rangePresets: TimeRangePickerProps['presets'] = [
    {label: 'Эта нед', value: [dayjs().startOf('week'), dayjs().endOf('week')]},
    {label: 'Пред нед', value: [dayjs().add(-7, 'd'), dayjs()]},
    {label: 'Этот мес', value: [dayjs().startOf('month'), dayjs().endOf('month')]},
    {label: 'Пред мес', value: [dayjs().add(-14, 'd'), dayjs()]},
    {label: 'Этот кварт', value: [today.startOf('quarter'), today.endOf('quarter')]},
    {label: 'Пред кварт', value: [dayjs().add(-3, 'm'), dayjs()]},
];

interface FilterProps {
    valueDate: [string, string];
    objects: Option[];
    isCollapsed: boolean;
    handleSelectObj: (value: string) => void;
    handleSelectDate: (dateString: [string, string]) => void;
    applyFilter: () => void;
    clearFilter: () => void;
    setIsCollapsed: (state: boolean) => void;
    keys: IActionFilter[];
}

export const Filter = (
    {
        valueDate,
        handleSelectDate,
        handleSelectObj,
        keys,
        objects,
        applyFilter,
        clearFilter,
        isCollapsed,
        setIsCollapsed
    }: FilterProps
) => {


    const defaultObj = keys.find(i => i.type === 'ID_Obj')?.value ?? '';

    return (
        <>
            <div className={styles.filterWrapper}>
                <RangePicker
                    dropdownClassName="custom-range-picker-dropdown"
                    className={styles.inputRange}
                    locale={ru_RU}
                    format="YYYY-MM-DD"
                    value={[dayjs(valueDate[0] as string, 'YYYY.MM.DD'), dayjs(valueDate[1] as string, 'YYYY.MM.DD')]}
                    presets={rangePresets}
                    onChange={(dates, dateStrings) => handleSelectDate(dateStrings)}
                    separator="-"
                    allowClear={true}
                />
                <button
                    className={styles.filterWrapperButton}
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    {isCollapsed ? 'Скрыть' : 'Фильтр'}

                </button>
            </div>
            <UnmountClosed isOpened={isCollapsed}>
                <div className={styles.filter}>
                    <div className={styles.filterCollapse}>
                        <SelectFormRow
                            options={objects}
                            label={'Объекты'}
                            disabled={false}
                            isTreeOptions={false}
                            defaultValue={defaultObj as string}
                            handleSelect={handleSelectObj}
                        />
                        <div className={styles.filterButtons}>
                            <button
                                className={styles.filterButtonsButton}
                                onClick={clearFilter}
                            >Очистить фильтр
                            </button>
                            <button
                                className={styles.filterButtonsButton}
                                onClick={applyFilter}
                            >Применить фильтр
                            </button>
                        </div>
                    </div>

                </div>
            </UnmountClosed>
        </>
    );
};
