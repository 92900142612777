import {createSlice} from '@reduxjs/toolkit';
import {ThemeVariable} from '../hooks/useChangeTheme';

export interface AppState {
    breadCrumbs: string[];
    theme: ThemeVariable;
}

const initialState: AppState = {
    breadCrumbs: [],
    theme: 'main'
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setBreadCrumbs: (state, {payload}) => {
            if (state.breadCrumbs.includes(payload)) {
                state.breadCrumbs = state.breadCrumbs.filter(i => i !== payload);
            } else {
                state.breadCrumbs = [...state.breadCrumbs, payload];
            }
        },
        setTheme: (state, {payload}) => {
            state.theme = payload;
        }
    }
});

export default appSlice.reducer;

export const {setBreadCrumbs, setTheme} = appSlice.actions;
