import React from 'react';
import styles from './style.module.scss';

export const CardSkeleton = () => {
    return (
        <div className={styles.card}>
            <div className={styles.titleWrap}>
                <div className={styles.title}/>
            </div>
            <div className={styles.bodyWrap}>
                <div className={styles.body}/>
            </div>
        </div>
    );
};
