import React from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';

const buttonDeviceState = ['Отличное', 'Хорошее', 'Среднее', 'Плохое', 'Прочее'].reverse();

const trimByCount = (text: string, countLetter: number) => {
    return text.substring(0, countLetter);
};

interface DeviseStateProps {
    onChange?: (state: string) => void;
    state: string;
}

export const DeviseState = ({onChange, state}: DeviseStateProps) => {

    return (
        <div className={styles.state}>
            {buttonDeviceState.map((button, idx) =>
                <button
                    key={button}
                    className={state === button
                        ? classNames(styles.stateButton, styles.stateButtonActive)
                        : styles.stateButton
                    }
                    onClick={(event) => {
                        event.preventDefault();
                        onChange && onChange(button);
                    }}>{trimByCount(button, idx < 3 ? 4 : 3)}</button>
            )}
        </div>
    );
};
