import React, {useEffect, useState} from 'react';
import {Location, useLocation, useNavigate, useParams} from 'react-router-dom';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {getObjectByIdForm} from '../../helpers';
import {ListMenu} from '../home/store/types';
import {ListButtons} from '../../components/listButtons';
import {useAppSelector} from '../../hooks/app.hooks';
import {
    useCheckByCameraMutation,
    useCheckByQrMutation,
    useLazyGetCredentialQuery,
    useSaveCredentialMutation
} from './store/api.timeControl';
import {authenticateFaceId, registerFaceId} from '../../authFunc';
import {toast} from 'react-toastify';
import {Modal} from '../../components/modal';
import styles from '../checkTypes/style.module.scss';
import {Button} from '../../fields/button';
import {mainColor} from '../../utils/constants';
import {TimeControlStateLocation} from './store/types';
import {ScanContainer} from './components';

export const TimeControl = () => {
    const navigate = useNavigate();
    const location: Location = useLocation();
    const {state} = location as Location & { state: TimeControlStateLocation };
    const {idForm} = useParams<{ idForm: string }>();
    const {data} = useGetAllQuestionsQuery();
    const [getCredential, refetch] = useLazyGetCredentialQuery();
    const [saveCredential] = useSaveCredentialMutation();
    const {name} = useAppSelector(state => state.auth.user);
    const buttonVariable = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    const [open, setOpen] = useState<boolean>(false);
    const [base64, setBase64] = useState<string | null>(null);
    const [isScan, setIsScan] = useState<boolean>(false);
    const [checkByCamera, {isLoading}] = useCheckByCameraMutation();
    const [checkByQr, {isLoading: loadQr}] = useCheckByQrMutation();

    useEffect(() => {
        if (state && state.FObjects) {
            const isPhoto = state.checkType === 'CheckInFoto' || state.checkType === 'CheckOutFoto';
            if (isPhoto) {
                checkByCamera({FObjects: Number(state.FObjects), checkType: state.checkType === 'CheckInFoto' ? 1 : 2})
                    .unwrap()
                    .then(({message, image, avatar, score}) => {
                        if (message) {
                            toast.error(message, {
                                autoClose: false
                            });
                        }
                        if (image) {
                            setOpen(true);
                            setBase64(image);
                        }
                    });
            } else {
                setIsScan(true);
            }

        }
    }, []);

    const handleClick = async (item: ListMenu) => {
        if (item.typeMob === 'CheckInFoto' || item.typeMob === 'CheckOutFoto') {
            getCredential()
                .unwrap()
                .then(async ({FaceID, FingerPrint}) => {
                    if (FaceID) {
                        await authenticateFaceId({base64encodedCredentialId: FaceID});
                    } else if (FingerPrint) {
                        await authenticateFaceId({base64encodedCredentialId: FingerPrint});
                    } else {
                        const {credentialId} = await registerFaceId({name: 'Task manager', displayName: name});
                        if (credentialId) {
                            await saveCredential({
                                body: {
                                    FaceID: credentialId,
                                    FingerPrint: credentialId
                                }
                            }).unwrap().then(() => getCredential());
                        }
                    }

                }).catch(err => {
                toast.error(JSON.stringify(err, null, 2), {
                    autoClose: false
                });
            }).finally(() => navigate(`/select-location/${idForm}?from=TimeControl`, {state: {checkType: item.typeMob}}));
        }
        if (item.typeMob === 'CheckInQR' || item.typeMob === 'CheckOutQR') {
            navigate(`/select-location/${idForm}?from=TimeControl`, {state: {checkType: item.typeMob}});
        }
    };

    const handleCheckByQrCode = (value: string) => {
        setIsScan(false);
        checkByQr(
            {FObjects: Number(state.FObjects), checkType: state.checkType === 'CheckInFoto' ? 1 : 2, qrContent: value}
        ).unwrap().then(({status, message}) => {
            status ? toast.info('Отметили') : toast.error(message, {autoClose: false});
        });
    };

    return (
        <>
            <ListButtons
                title={'Учет времени'}
                isLoading={isLoading || loadQr}
                buttons={buttonVariable?.children ?? []}
                handleClick={handleClick}/>
            <Modal isOpen={isScan} full={false}>
                <ScanContainer
                    onCansel={() => setIsScan(false)}
                    onScan={handleCheckByQrCode}
                />
            </Modal>
            <Modal isOpen={open}>
                <div className={styles.screenShot}>
                    <img src={base64 as string} alt={'screenshot'}/>
                    <div className={styles.modalButtons}>
                        <div className={styles.modalButtonsBox}>
                            <Button
                                text={'Это я'}
                                textStyleType={'regular'}
                                onClick={() => {
                                    setOpen(false);
                                    setBase64(null);
                                }}
                                disabled={false}
                                style={{
                                    border: `1px solid ${mainColor}`,
                                    borderRadius: '12px',
                                    fontSize: '14px',
                                    height: '44px'
                                }}/>
                        </div>
                        <div className={styles.modalButtonsBox}>
                            <Button
                                text={'Это не я'}
                                textStyleType={'regular'}
                                onClick={() => {
                                    setOpen(false);
                                    setBase64(null);
                                }}
                                disabled={false}
                                style={{
                                    border: `1px solid ${mainColor}`,
                                    borderRadius: '12px',
                                    fontSize: '14px',
                                    height: '44px'
                                }}/>
                        </div>
                    </div>

                </div>
            </Modal>
        </>
    );
};
