import React from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './style.module.scss';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {useGetEmployeeQuery} from './store/employee.api';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import AppLoader from '../../components/appLoader';
import {Employee} from './store/types';
import {setEmployee} from './store/employee.slice';
import {QuizLocationState} from '../quiz/Quiz';

export const SelectEmployee = () => {
    const navigate = useNavigate();
    const location: Location = useLocation();
    const {state} = location as Location & { state: QuizLocationState };
    const dispatch = useAppDispatch();
    const {currentLocation} = useAppSelector(state => state.quiz);
    const {data, isLoading} = useGetEmployeeQuery({idObj: Number(currentLocation?.id)}, {skip: !currentLocation});

    const handleSelect = (item: Employee) => {
        dispatch(setEmployee(item));
        navigate('/quiz', {state: {isEmployee: true, path: state.path} as QuizLocationState});
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Выбор сотрудника'}/>
            <BackButton text={`Назад`} onClick={() => navigate(-1)}/>
            <div className={styles.container}>
                <h2 className={styles.title}>Выбор сотрудника:</h2>
                <p className={styles.text}>выберите сотрудника для аттестации</p>
                {
                    isLoading
                        ? <AppLoader isFull={false}/>
                        : (
                            <div className={styles.list}>
                                {data?.map((item: Employee) => (
                                    <div
                                        key={item.ID_Kontra}
                                        className={styles.employee}
                                        onClick={() => handleSelect(item)}
                                    >
                                        <span className={styles.employeeName}>{item.KontraName}</span>
                                        <span className={styles.employeeJobtitle}>{item.StaffName}</span>
                                    </div>
                                ))}
                            </div>
                        )
                }
            </div>
        </MainLayout>
    );
};
