import React, {useState} from 'react';
import styles from './style.module.scss';
import {Spin} from "antd";
import {CloseCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import {ImageLayout} from "../../layouts";
import dayjs from "dayjs";
import {FileFromBack} from "../../screens/quiz/store/types";
import classNames from "classnames";

const plusIcon = require('../../assets/images/plus.png');

type Image = { fileBase64: string, fileName: string }

interface CustomReaderProps {
    loadingFile: boolean;
    handleSelect: (image: Image) => void;
    viewFiles: FileFromBack[];
    handleRemove: (file: FileFromBack) => void;
}

export const CustomReader = ({handleSelect, viewFiles, loadingFile, handleRemove}: CustomReaderProps) => {
    const [url, setUrl] = useState<string>('');
    const [showFile, setShowFile] = useState<boolean>(false);

    const timestamp = dayjs().unix();


    const handleCapture = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                handleSelect({fileBase64: reader.result as string, fileName: String(timestamp)});
            };
            reader.readAsDataURL(file);
        }
    };


    return (
        <div className={styles.flex}>

            <div className={styles.wrapReaderButton}>
                <label htmlFor={'reader'} className={styles.fileReaderButton} onClick={async (e) => {
                    await sessionStorage.setItem('isFile', 'OK');
                }}>
                    {loadingFile ?
                        <Spin indicator={<LoadingOutlined style={{fontSize: 14}} spin/>}/>
                        : <ImageLayout
                            src={plusIcon}
                            imageWidth={24}
                            imageHeight={24}
                            containerWidth={24}
                            containerHeight={24}/>}
                </label>
                <span className={styles.minText}>Доб. файл</span>
                <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    id={'reader'}
                    //className={styles.fileReaderButton}
                    style={{display: 'none'}}
                    onChange={handleCapture}
                />
            </div>
            {viewFiles.length > 0
                ? <div className={styles.list}>
                    {viewFiles.map((file, index) => (
                        <div key={index} className={styles.wrapReaderButton} onClick={() => {
                            file?.url
                                ? setUrl(file.url)
                                : setUrl(file.base64 as string);
                            setShowFile(true);
                        }}>
                            <span key={`${index}-${file}`} className={
                                file.loadStatus === 'ready'
                                    ? styles.fileReaderButton
                                    : file.loadStatus === 'loaded'
                                        ? classNames(styles.fileReaderButton, styles.fileReaderButtonLoad)
                                        : classNames(styles.fileReaderButton, styles.fileReaderButtonReject)
                            }>
                                {file.loadStatus === 'ready'
                                    ? (<span className={styles.fileReaderButtonClose}
                                             onClick={(event) => {
                                                 event.stopPropagation();
                                                 handleRemove(file);
                                             }}>
                                            <CloseCircleOutlined style={{color: 'red'}}/>
                                        </span>)
                                    : null}
                                <ImageLayout
                                    src={file?.url ? file.url : file.base64 as string}
                                    imageWidth={36}
                                    imageHeight={36}
                                    containerWidth={36}
                                    containerHeight={36}/>
                            </span>
                            <span className={styles.minText}>{file.origName}</span>
                        </div>
                    ))}
                </div>
                : null}
            {showFile ?
                <div className={styles.modalFile}>
                    <span
                        className={styles.close}
                        onClick={() => {
                            setUrl('');
                            setShowFile(false);
                        }}
                    />
                    <div className={styles.modalFileContent}>
                        <img className={styles.showImage} src={url} alt={'attachment'}/>
                    </div>

                </div> : null}
        </div>
    );
};
