import {Line, Word} from "tesseract.js";
import {invoiceDate, invoiceNumber} from "./index";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ru';
import {FieldPathValue, SetValueConfig} from "react-hook-form";
import {IFormData} from "../state/types";

dayjs.extend(customParseFormat);
dayjs.locale('ru');

const formats: string[] = [
    'D MMMM YYYY',
    'DD MMMM YYYY',
    'DD MM YYYY',
    'DD MMMM YY',
    'DD MM YY',
    'YYYY.MM.DD',
    'YY.MM.DD',
    'YYYY.DD.MM',
    'YY.DD.MM',
    'DD.MM.YYYY',
    'DD.MM.YY',
];

const patternPay: RegExp = /на оплату/i;

function joinAndCleanWords(words: string[]) {
    return words
        .join(' ')
        .replace(/[",]/g, '')
        .trim();
}

function splitArrayBySeparator(array: string[], separator: string): string[][] {
    const result = [];
    let currentPart: string[] = [];

    array.forEach(item => {
        if (item === separator) {
            if (currentPart.length > 0) {
                result.push(currentPart);
                currentPart = [];
            }
        } else {
            currentPart.push(item);
        }
    });

    if (currentPart.length > 0) {
        result.push(currentPart);
    }

    return result;
}

function findFirstNumberAfterKeyword(array: Word[]) {
    let foundItogo = false;

    for (let i = 0; i < array.length; i++) {
        const text = array[i].text;

        if (foundItogo) {
            // Если уже найдено "итого:", ищем число
            const regexNumber = /[\d\s,.]+/;
            const match = text.match(regexNumber);

            if (match) {
                const cleanedNumber = match[0]; //.replace(/[,\s]/g, '');
                if (cleanedNumber !== "") {
                    return cleanedNumber; // Возвращаем первое найденное число и завершаем функцию
                }
            }
        } else {
            // Ищем "итого:" в поле text
            if (text.toLowerCase().includes("всего") || text.toLowerCase().includes("итого") || text.toLowerCase().includes("того")) {
                foundItogo = true;
            }
        }
    }

    return null; // Возвращаем null, если не найдено ни одного числа после "итого:"
}

function extractTexts(objects: Word[]): string[] {

    const keywords: string[] = ['000', '090', 'ООО', 'АО', 'ИП', 'Поставщик:', 'Покупатель:'];
    const separator: string = 'separator';
    let result: string[] = [];
    let collecting = false;

    for (let obj of objects) {
        if (collecting) {
            if (obj.text === 'ИНН' || obj.text === 'ИНН:') {
                collecting = false;
                result.push(separator);
                continue;
            } else {
                result.push(obj.text.replace('—', ''));
            }
        }

        if (keywords.includes(obj.text)) {
            collecting = true;
        }
    }

    const names = splitArrayBySeparator(result, separator);

    return names.map(i => joinAndCleanWords(i));

}

interface MapResponsePhotoReaderProps {
    words: Word[];
    lines: Line[];
    setValue: (name: any, value: FieldPathValue<IFormData, any>, options?: SetValueConfig) => void
}

export const mapResponsePhotoReader = (
    {
        words,
        lines,
        setValue
    }: MapResponsePhotoReaderProps
) => {
    const names = extractTexts(words);
    const commonSum = findFirstNumberAfterKeyword(words);

    const pay: string | undefined = lines.find(line => line.text.match(patternPay))?.text;

    if (names.length > 0) {
        const Payer = names[1];
        const Name = names[0] ?? '';
        setValue('Payer', Payer);
        setValue('Name', Name);
    }

    if (commonSum) {
        setValue('SumApp', commonSum);
    }

    if (pay !== undefined) {
        const NumOrder = invoiceNumber(pay);
        const DateOrder = invoiceDate(pay);
        if (NumOrder) {
            setValue('NumOrder', NumOrder);
        }
        if (DateOrder) {
            let dateObject;
            for (let format of formats) {
                const date = DateOrder.trim();
                dateObject = dayjs(date, format);
                if (dateObject.isValid()) {
                    break;
                }
            }
            setValue('DateOrder', dateObject?.format());
        }

    }
};
/*
В 000 "ТЕХПОИСК, ИНН 7706420881, КПП 77230100, 109…1, Москаа г Иловайская ул, дом № 10, строение 1,\n
Поставщик 090 \"Робот икс технолоджи\", ИНН 3906396406, КПП 390601001, 236006, Калининградская обл, Калининград 5\n
Постовщик — ИП КОЖЕВНИКОВ И.Ю. ИНН 505015460645, 141102, Московеная обл, г Щёлково,\n
покумтель 000 "КОРА ГРУПП", ИНН ЭТОт217671, КПП Т7ОЛО0ОН\n
Поставщик: — ИП Иванов Анатолий Иванович, ИНН: 773576240338, адрес: 125009, г Москва,\n
Клиент: ООО "РАССВЕТ-19", ИНН: 7704466898, КПП: 770401001, адрес: 119019, ГОРОД.\n'
Покупатель — ООО ЛАГУНА, ИНН 7714037338, КПП 77755…01, 119361, Москва г, ‚ ТУЛЬСКАЯ М. ул, дом № 4,\n
Поставщик ООО"ВАСИЛЕК", ИНН 7722737733, КПП 773303001, 109052, Москва г ДОБРЫНИНСКАЯул, дом №\n
Тост — ИП КОЖЕВНИКОВ И. Ю- ИНН 505460645. 41102, Московская обл. Г ЩёЮо,\n\n
гочуатоль 900 "КОРА ГРУП", ИНН Э7ОТ21767% КРМ УОЛОООЙ\n\n
Поставщик: 299 "Тест", ИНН 7717656818, КПП 7722010…а г, вн.тер.г. муниципальный округ Южнопортовый,\n
Покупатель: ООО "АРЕНА", ИНН 9705131922, КПП 77050…35, Город Москва, вн.тер. г. Муниципальный Округ\n
*/

