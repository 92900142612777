import {api} from "../../../store/api";
import {Option} from "../../../store/types";
import {FilterObject} from "../../../screens/taskList/store/types";
import {CONTENT_TYPE_URLENCODED} from "../../../utils/constants";
import {CompanyReqItem, ListIdName} from "./type";

export const financialFilterApi = api.injectEndpoints({
    endpoints: build => ({
        getFilterObj: build.mutation<{ listObject: Option[] }, { body: string }>({
            query: ({body}) => ({
                url: '/listData/p_Obj_new.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED
                }
            }),
            transformResponse: ({listObject}) => {
                return {
                    listObject: (Object.values(listObject) as FilterObject[]).map((i) => ({
                        label: i.name,
                        value: String(i.id)
                    }))
                };
            }
        }),
        getFilterCompanies: build.query<{ list: Option[] }, void>({
            query: () => ({
                url: '/listData/p_KontraList.php',
                method: 'POST',
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({list}) => {
                const listItems = list.map((i: any) => ({
                    label: `${i.name} ${i.inn}`,
                    value: String(i.id)
                }));
                return {
                    list: [{label: 'Новый', value: 'new-item'}, ...listItems]
                };
            },
        }),
        addCompanies: build.mutation<{ kontra: CompanyReqItem }, { body: { Type: string, Name: string, INN: string } }>({
            query: ({body}) => ({
                url: 'operation/actionKontra.php',
                method: 'POST',
                body
            })
        }),
        getFilterAuthor: build.query<{ list: Option[] }, void>({
            query: () => ({
                url: '/helpDesk/p_App_listAuthor.php',
                method: 'POST',
            }),
            transformResponse: ({list}) => {
                return {list: (Object.values(list) as ListIdName[]).map((i) => ({label: i.name, value: String(i.id)}))};
            }
        }),
    }),
    overrideExisting: true
});

export const {

    useGetFilterObjMutation,
    useGetFilterCompaniesQuery,
    useGetFilterAuthorQuery,
    useAddCompaniesMutation,
    useLazyGetFilterCompaniesQuery
} = financialFilterApi;
