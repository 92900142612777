import {removeEmptyValues} from "./removeEmptyValues";
import {convertDateForRequest} from "./convertDateForRequest";

interface FromJsonToUrlencodedProps {
    data: any;
}

const typeDateProperty = ['Start', 'Finish'];
const excludeKeys = ['ThemeKey', 'SearchText'];

export const fromJsonToUrlencoded = (
    {
        data,
    }: FromJsonToUrlencodedProps): string => {
    const formBody: any[] = [];
    for (const property in removeEmptyValues(data)) {
        if (!excludeKeys.includes(property)) {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(
                typeDateProperty.includes(property)
                    ? convertDateForRequest(data[property])
                    : data[property],
            );
            formBody.push(encodedKey + '=' + encodedValue);
        }
    }
    return formBody.join('&') as string;
};
