import React, {useState} from 'react';
import {useAppSelector} from "../../hooks/app.hooks";
import {useGetTaskMenuQuery} from "./store/taskType.api";
import AppLoader from "../../components/appLoader";
import {MainLayout} from "../../layouts";
import Header from "../../components/header";
import {useLocation, useNavigate} from "react-router-dom";
import {extractValueFromString} from "../../helpers";
import SimpleButton from "../../components/simpleButton";
import {ChildItem, TreeItem} from "./store/types";
import Footer from "../../components/footer";
import {BackButton} from "../../components/backButton";
import styles from './style.module.scss';

export const QuicklyTaskTypes = () => {
    const {search, state} = useLocation();
    const navigate = useNavigate();
    const {id_con} = useAppSelector(state => state.auth.user);
    const {data: menu, isLoading} = useGetTaskMenuQuery({id_con}, {skip: id_con === ''});

    const [childItem, setChildItem] = useState<ChildItem[]>([]);

    const fromPage = extractValueFromString(search, 'from');

    const handleSelect = (item: TreeItem | ChildItem) => {
        if ('childs' in item) {
            item.childs.length > 0
                ? setChildItem(item.childs)
                : navigate(`/task-quickly-create/${item.id}?from=${item.name}`, {
                    state
                });
        } else {
            navigate(`/task-quickly-create/${item.id}?from=${item.name}`, {
                state
            });
        }
    };

    const handleBack = () => {
        childItem.length > 0
            ? setChildItem([])
            : navigate(-1);
    };

    if (isLoading) {
        return <AppLoader isFull={true}/>;
    }

    return (
        <MainLayout>
            <Header breadCrumbs={`Задачи - ${String(fromPage).toUpperCase()}`}/>
            <BackButton text={'Назад к выбору объекта'} onClick={handleBack}/>
            <div className={styles.list}>
                {childItem.length > 0
                    ? childItem.map(c =>
                        <SimpleButton
                            column={2}
                            handler={() => handleSelect(c)}
                            key={c.id}>
                            <span>{c.name}</span>
                        </SimpleButton>)
                    : menu?.map(m => (
                        <SimpleButton
                            column={2}
                            handler={() => handleSelect(m)} key={m.id}>
                            <span>{m.name}</span>
                        </SimpleButton>
                    ))}
            </div>

            <Footer/>
        </MainLayout>
    );
};
