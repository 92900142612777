import React, {useEffect, useMemo, useState} from 'react';
import {useGetFilterAuthorQuery, useGetFilterSolverQuery, useGetFilterThemeQuery} from './store/filter.api';
import {useAppDispatch} from '../../hooks/app.hooks';
import styles from '../../screens/taskList/style.module.scss';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import {DatePicker, Input, TimeRangePickerProps} from 'antd';
import {CloseCircleOutlined, SearchOutlined} from '@ant-design/icons';
import {defaultDate, setClearFilter, setFilter} from './store/filter.slice';
import {Filter} from './components';
import {FilterItemType, IFilter} from './store/type';
import {useGetFilterObjMutation} from '../financialFilter/store/financial.filter.api';
import {fromJsonToUrlencoded} from '../../helpers';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.locale('ru');

const {RangePicker} = DatePicker;
const today = dayjs();

function isNotDefault(obj: IFilter[], defaultDates: IFilter[]) {
    // Проверка на совпадение дат
    for (let i = 0; i < defaultDates.length; i++) {
        const defaultDate = defaultDates[i];
        const objDate = obj.find(item => item.type === defaultDate.type);

        if (!objDate || !dayjs(defaultDate.value as string).isSame(objDate.value as string)) {
            return true;
        }
    }
    return false;
}

export const rangePresets: TimeRangePickerProps['presets'] = [
    {label: 'Эта нед', value: [dayjs().startOf('week'), dayjs().endOf('week')]},
    {label: 'Пред нед', value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().startOf('week').add(-1, 'd')]},
    {label: 'Этот мес', value: [dayjs().startOf('month'), dayjs().endOf('month')]},
    {label: 'Пред мес', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().startOf('month').add(-1, 'd')]},
    {label: 'Этот кварт', value: [dayjs().startOf('quarter'), today.endOf('quarter')]},
    {
        label: 'Пред кварт',
        value: [dayjs().subtract(1, 'quarter').startOf('quarter'), dayjs().startOf('quarter').add(-1, 'd')]
    },
];

interface TaskFilterProps {
    useInputSearch: boolean;
    applyFilter: (isInit: boolean) => void;
    keys: IFilter[];
    onSearch?: (text: string) => void;
}

export const TaskFilter = ({applyFilter, useInputSearch, keys, onSearch}: TaskFilterProps) => {
    const dispatch = useAppDispatch();
    const [getFilter, {data: objects}] = useGetFilterObjMutation({fixedCacheKey: 'objectFilter'});
    const {data: themes} = useGetFilterThemeQuery();
    const {data: solvers} = useGetFilterSolverQuery();
    const {data: authors} = useGetFilterAuthorQuery();

    const [isCollapsed, setCollapse] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    useEffect(() => {
        getFilter({body: fromJsonToUrlencoded({data: {ShowComp: 'NULL', flagSHID: false}})});
    }, []);

    useEffect(() => {
        if (keys.some(i => i.type === 'Search')) {
            const text = keys.find(i => i.type === 'Search')?.value as string;
            setText(text);
        }
    }, [keys]);

    const handleSetFilter = (value: string, type: FilterItemType | 'index') => {
        if (keys.some(key => key.type === type)) {
            const newKeys = keys.map(key => key.type === type ? {...key, value} : key);
            dispatch(setFilter(newKeys));
        } else {
            dispatch(setFilter([...keys, {type, value}]));
        }
    };

    const handleChangeDate = (dateStrings: [string, string]) => {
        const formattedDates = dateStrings.map(date =>
            (dayjs(date, 'D MMM, YYYY').format('YYYY-MM-DD'))
        );

        setCollapse(true);

        const newKeys = keys.map(key => {
            if (key.type === 'Start') return {...key, value: formattedDates[0]};
            if (key.type === 'Finish') return {...key, value: formattedDates[1]};
            return key;
        });
        dispatch(setFilter(newKeys));
    };

    const handleSearch = (value: string) => {
        setText(value);
    };

    const clearFilter = (type: 'search' | 'other') => {
        if (type === 'search') {
            const newKeys = keys.filter(k => k.type !== 'Search');
            dispatch(setFilter(newKeys));
            setText('');
            applyFilter(false);
        } else {
            if (keys.some(i => i.type === 'Search' && i.value.length > 0)) {
                setText('');
            }
            dispatch(setClearFilter());
            setCollapse(false);
            applyFilter(true);
        }
        setCollapse(false);
    };

    const dateStart = keys.find(k => k.type === 'Start') as IFilter;
    const dateFinish = keys.find(k => k.type === 'Finish') as IFilter;
    const selectFieldFilter = useMemo(() => keys.filter(k => !['Start', 'Finish'].includes(k.type)), [keys]);
    const selectFieldData = useMemo(() => keys.filter(k => ['Start', 'Finish'].includes(k.type)), [keys]);

    return (
        <div>
            <div className={styles.dataPickerWrapper}>
                <div className={styles.dataPickerWrapperData}>
                    {isNotDefault(selectFieldData, defaultDate) ?
                        <span className={styles.dataPickerWrapperTooltip}>Применен фильтр даты</span> : null}
                    <RangePicker
                        dropdownClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format="D MMM, YYYY"
                        presets={rangePresets}
                        value={[dayjs(dateStart.value as string, 'YYYY.MM.DD'), dayjs(dateFinish.value as string, 'YYYY.MM.DD')]}
                        onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                        separator="-"
                        allowClear={false}
                    />
                </div>
                <button
                    className={styles.dataPickerWrapperButton}
                    onClick={() => setCollapse(!isCollapsed)}
                >
                    {isCollapsed ? 'Скрыть' : 'Фильтр'}
                    {selectFieldFilter.length > 0 && !isCollapsed
                        ? <span
                            className={styles.dataPickerWrapperCount}>{selectFieldFilter.length}</span>
                        : null}
                </button>
            </div>
            <Filter
                defaultFilter={keys}
                isCollapsed={isCollapsed}
                optObjects={objects?.listObject ?? []}
                optThemes={themes?.treeList ?? []}
                optSolver={solvers?.list ?? []}
                optAuthors={authors?.list ?? []}
                handleSelectObj={value => handleSetFilter(value, 'ID_Obj')}
                handleSelectSolver={value => handleSetFilter(value, 'ID_Solver')}
                handleSelectAuthors={value => handleSetFilter(value, 'ID_Author')}
                handleSelectTheme={value => handleSetFilter(value, 'ID_Sect')}
                applyFilter={() => {
                    applyFilter(false);
                    setCollapse(false);
                }}
                clearFilter={() => {
                    clearFilter('other');
                }}
            />
            {useInputSearch ? <div className={styles.search}>
                <div className={styles.searchInput}>
                    <Input
                        placeholder="введите текст поиска"
                        className={styles.input}
                        value={text}
                        autoFocus={false}
                        onBlur={() => {
                            if (text === '') {
                                clearFilter('search');
                            }
                        }}
                        onChange={(event) => handleSearch(event.target.value)}
                        suffix={
                            <div onClick={() => clearFilter('search')}>
                                {text === '' ? <SearchOutlined/> : <CloseCircleOutlined/>}
                            </div>
                        }
                    />
                </div>
                <button
                    className={styles.searchButton}
                    disabled={text.length === 0}
                    onClick={() => onSearch ? onSearch(text) : null}
                >{'Поиск'}
                </button>
            </div> : null}
        </div>
    );
};
