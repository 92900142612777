import React, {useState} from 'react';
import {Placemark, Polygon} from '@pbe/react-yandex-maps';
import {ObjectPoint} from '../store/types';
import styles from '../styles.module.scss';

const iconLocation = require('../../../assets/images/map/location.png');
const iconMapMarkBlue = require('../../../assets/images/map/mapMarkBlue.png');
const iconMapMarkGrB = require('../../../assets/images/map/mapMarkGrB.png');
const iconMapMarkGrR = require('../../../assets/images/map/mapMarkGrR.png');


const getIcon = (obj: ObjectPoint) => {
    if (obj.ParentCode === 'Corp') {
        return obj.dateFinish === null ? iconLocation : iconMapMarkGrR;
    } else {
        return obj.dateFinish === null ? iconMapMarkBlue : iconMapMarkGrB;
    }
};

interface RenderObjPointProps {
    item: ObjectPoint;
}


const CustomBalloon = ({item, onClose}: { item: ObjectPoint; onClose: () => void }) => {
    return (
        <div className={styles.balloon}>
            <button onClick={onClose} className={styles.balloonClose}>×</button>
            <h5 className={styles.balloonStrong}>{item.name}</h5>
            <h5 className={styles.balloonStrong}>{item.ParentName}</h5>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonText}>{item.address}</p>
            <div className={styles.balloonSeparator}/>
            <p className={styles.balloonStrong}>{item.ParentCode}</p>
            <div className={styles.balloonSeparator}/>
            {item.dateFinish !== null
                ? <>
                    <p className={styles.balloonStrong}>Закрыт: {item.dateFinish}</p>
                    <div className={styles.balloonSeparator}/>
                </>
                : null}
            <p className={styles.balloonText}>{item.phone}</p>
        </div>
    );
};

export const RenderObjPoint = (
    {
        item
    }: RenderObjPointProps
) => {
    const boundary = item.boundary.map(coord => [+coord[1], +coord[0]]);
    const [selectItem, setSelectItem] = useState<ObjectPoint | null>(null);
    return (
        <>
            <Placemark
                key={item.id}
                geometry={[item.lat, item.lon]}
                options={{
                    iconLayout: 'default#image',
                    iconImageHref: getIcon(item),
                    iconOffset: /*selectedObjId === item.id ? [-10, -20] : */ [0, 0],
                    iconImageSize: /*selectedObjId === item.id ? [50, 50] : */ [30, 30],
                }}
                onClick={() => {
                    setSelectItem(item);
                }}
            />
            <Polygon
                geometry={[boundary]}
                options={{
                    fillColor: 'rgba(0,140,252,0.21)',
                    strokeColor: '#008CFCFF',
                    opacity: 1,
                    strokeWidth: 2,
                    strokeStyle: 'shortdash',
                }}
            />
            {selectItem ? (
                <div className={styles.balloonWrapper}>
                    <CustomBalloon item={item} onClose={() => setSelectItem(null)}/>
                </div>
            ) : null}
        </>
    );
};
