import {toast} from 'react-toastify';
import {FaceIdProps} from './types';

// Функция для декодирования Base64url
function base64urlToUint8Array(base64url: string) {
    const base64 = base64url
        .replace(/-/g, '+')
        .replace(/_/g, '/');
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

export const authenticateFaceId = async (
    {
        base64encodedCredentialId
    }: FaceIdProps
) => {
    try {
        // Получите сохраненный base64encodedCredentialId с сервера
        // const base64encodedCredentialId = 'полученный_с_сервера_base64encodedCredentialId';

        // if (credentialId) {
        const publicKey: any = {
            challenge: new Uint8Array([79, 252, 83, 72, 214, 7, 89, 26]),
            rpId: 'ifbx.ru',
            allowCredentials: [{
                type: 'public-key',
                id: base64urlToUint8Array(base64encodedCredentialId as string)
            }],
            userVerification: 'required',
        };


        const credential = await navigator.credentials.get({publicKey});


        if (credential) {
            toast.info(JSON.stringify(credential, null, 2), {
                autoClose: false,
                position: 'bottom-center'
            });
        }

    } catch (err) {
        console.error('Ошибка аутентификации с Face ID', err);
    }
};
