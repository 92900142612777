export function formatNumber(number: number) {
    // Преобразуем число в строку с двумя десятичными знаками
    let numStr = Number.isInteger(number) ? String(number) : number.toFixed(2);

    // Разделяем число на целую и дробную части
    let [integerPart, decimalPart] = Number.isInteger(number) ? [numStr, undefined] : numStr.split('.');

    // Разбиваем целую часть на тысячи
    let parts = [];
    while (integerPart.length > 3) {
        parts.unshift(integerPart.slice(-3));
        integerPart = integerPart.slice(0, -3);
    }
    if (integerPart) {
        parts.unshift(integerPart);
    }

    // Объединяем части с разделителем и добавляем дробную часть
    return decimalPart ? parts.join(' ') + '.' + decimalPart : parts.join(' ');
}
