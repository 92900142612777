import React, {HTMLAttributes} from 'react';
import styles from "./style.module.scss";

interface PercentInputProps extends HTMLAttributes<HTMLInputElement> {

}

export const PercentInput = ({...props}: PercentInputProps) => {
    return (
        <div className={styles.wrapper}>
            <input
                {...props}
                type={'number'}
                className={styles.input}
            />
            <span className={styles.percentIcon}>%</span>
        </div>
    );
};
