import React, {useEffect, useRef} from 'react';
import {Status} from "../../../store/types";
import styles from '../style.module.scss';
import classNames from "classnames";

interface StatusesProps {
    items: Status[];
    currentStatus: number;
    disabled: boolean;
    handleClick: (item: Status) => void;
}

export const Statuses = ({items, disabled, currentStatus, handleClick}: StatusesProps) => {
    const listRef = useRef<HTMLDivElement>(null);
    const WIDTH_FRAME: number = 100 * items.length;

    useEffect(() => {
        if (items.length > 0) {
            const index = items.findIndex(i => i?.id === currentStatus);
            const offset = (index * 100) - 35;

            listRef?.current?.scrollTo({
                left: offset,
                behavior: 'smooth'
            });
        }
    }, [items]);
 
    return (
        <div className={styles.statusesWrap}>
            <div ref={listRef} className={styles.statuses}>
                <div className={styles.statusesList} style={{width: `${WIDTH_FRAME}px`}}>
                    {items.map(item => (
                        <div key={item?.id}
                             className={item?.id === currentStatus
                                 ? classNames(styles.statusesItem, styles.statusesItemActive)
                                 : styles.statusesItem
                             }
                             onClick={() => {
                                 if (!disabled) {
                                     //setStatus(item);
                                     handleClick(item);
                                 }
                             }}>
                            {item?.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
