import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import styles from './style.module.scss';
import Footer from '../../components/footer';
import {Button} from '../../fields/button';
import {ModalContent} from '../../components/modalTypes';
import {Modal} from '../../components/modal';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {logout} from '../auth/store/auth.slice';
import classNames from 'classnames';
import {useChangeTheme} from '../../hooks';
import {Select} from 'antd';
import {ThemeVariable} from '../../hooks/useChangeTheme';

// function urlBase64ToUint8Array(base64String: any) {
//     const padding = '='.repeat((4 - base64String.length % 4) % 4);
//     const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
//     const rawData = window.atob(base64);
//     return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
// }

// function subscribeToPush(id_con: string) {
//     if ('serviceWorker' in navigator && 'PushManager' in window) {
//         navigator.serviceWorker.ready.then(async (registration) => {
//
//             const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUSH_PUBLIC_KEY);
//             const newSubscription = await registration.pushManager.subscribe({
//                 userVisibleOnly: true,
//                 applicationServerKey: convertedVapidKey,
//             });
//
//
//         });
//     }
// }

const options = [
    {value: 'main', label: 'Дефолтный'},
    {value: 'spartak', label: 'Спартак'},
    {value: 'gazprom', label: 'Газпром'},
    {value: 'sber', label: 'Сбер'},
];

export const SettingsScreen = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {theme} = useAppSelector(state => state.app);
    const {chaneTheme} = useChangeTheme();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [waitingServiceWorker, setWaitingServiceWorker] = useState<any>(null);

    const handleChangeTheme = (value: ThemeVariable) => {
        chaneTheme(value);
    };

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
                registration.addEventListener('updatefound', () => {
                    const newWorker = registration.installing;
                    newWorker &&
                    newWorker.addEventListener('statechange', () => {
                        if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                            setWaitingServiceWorker(newWorker);
                        }
                    });
                });
            });
        }
    }, []);

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
    };

    const handleReload = async () => {
        if (waitingServiceWorker) {
            // Сообщаем сервисному воркеру пропустить ожидание, если он существует
            waitingServiceWorker.postMessage({type: 'SKIP_WAITING'});
        }

        // Перезагружаем страницу независимо от состояния service worker
        window.location.reload();
    };


    return (
        <MainLayout>
            <Header breadCrumbs={'Настройки'}/>
            <BackButton text={'Назад в главное меню'} onClick={() => navigate('/')}/>

            <div className={classNames(styles.page)}>
                <div className={styles.selectWrap}>
                    <label className={styles.selectLabel}>Выбор темы</label>
                    <Select
                        className={styles.select}
                        options={options}
                        value={theme}
                        onSelect={(value) => handleChangeTheme(value)}/>
                </div>

                <div className={styles.buttons}>
                    <div className={styles.buttonsItem}>
                        <Button
                            text={'Обновить'}
                            textStyleType={'semiBold'}
                            style={{height: '64px', padding: '0 40px'}}
                            onClick={handleReload}
                        />
                    </div>
                    <div className={styles.buttonsItem}>
                        <Button
                            text={'Выход из приложения'}
                            textStyleType={'semiBold'}
                            style={{height: '64px', padding: '0 40px'}}
                            onClick={() => setOpen(true)}
                        />
                    </div>
                </div>

                <p className={styles.version}>Версия сборки: {process.env.REACT_APP_BUILD_VERSION}</p>
            </div>
            <Footer/>
            <Modal isOpen={isOpen}>
                <ModalContent
                    title={'Выход из приложения'}
                    text={'Хотите выйти?'}
                    type={'failed'}
                    onConfirmDisable={false}
                    onCansel={() => setOpen(false)}
                    onConfirm={handleLogout}
                />
            </Modal>
        </MainLayout>
    );
};
