import React, {HTMLAttributes} from 'react';

interface SplitStringWithLineBreakProps extends HTMLAttributes<HTMLDivElement> {
    text: string;
}

export const SplitStringWithLineBreak = ({text, ...props}: SplitStringWithLineBreakProps) => {
    return (
        <div {...props}>
            {text.split(',').map((part, index) => (
                <React.Fragment key={index}>
                    {part}
                    {index < text.split(',').length - 1 && <br/>}
                </React.Fragment>
            ))}
        </div>
    );
};
