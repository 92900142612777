import React from 'react';

export const IncomingCall = ({color = '#000000'}: { color: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.97631 6.58949C4.81086 6.09914 5.16235 5.55017 5.78981 5.55017L8.17699 5.55025L9.32818 7.62399L8.2191 9.8422C8.12626 10.0279 8.09742 10.239 8.13705 10.4428L9.06086 10.2631C8.13705 10.4428 8.13729 10.444 8.13729 10.444L8.13756 10.4454L8.1382 10.4486L8.13987 10.4568L8.14485 10.4805C8.14893 10.4994 8.15456 10.5245 8.16191 10.5553C8.17661 10.6169 8.19828 10.7015 8.2284 10.8051C8.28853 11.0119 8.38304 11.2969 8.52436 11.6274C8.80536 12.2847 9.28162 13.1458 10.062 13.9262C10.8424 14.7066 11.7024 15.1816 12.359 15.4614C12.6891 15.6021 12.9736 15.6959 13.1802 15.7555C13.2836 15.7854 13.3681 15.8068 13.4296 15.8214C13.4604 15.8287 13.4855 15.8342 13.5044 15.8382L13.5281 15.8432L13.5364 15.8448L13.5396 15.8455L13.541 15.8457L13.5422 15.846C13.7453 15.8849 13.9555 15.8559 14.1404 15.7635L16.3583 14.6545L18.438 15.8108V18.1983C18.438 18.8258 17.8889 19.1772 17.3987 19.0118C15.0522 18.2201 11.5602 16.7555 9.39644 14.5916C7.23259 12.4278 5.76803 8.93589 4.97631 6.58949Z"
                fill={color}/>
            <path
                d="M15.5293 3.2942C15.5293 2.77441 15.1079 2.35303 14.5881 2.35303C14.0684 2.35303 13.647 2.77441 13.647 3.2942V9.41185C13.647 9.65275 13.7375 9.87251 13.8863 10.039C13.8974 10.0514 13.9088 10.0635 13.9206 10.0753C14.008 10.1633 14.1087 10.2305 14.2164 10.2768C14.3305 10.3258 14.4561 10.353 14.5881 10.353H20.7058C21.2256 10.353 21.647 9.93165 21.647 9.41185C21.647 8.89205 21.2256 8.47067 20.7058 8.47067H16.9249L21.3581 4.20793C21.7328 3.84765 21.7445 3.25185 21.3842 2.87716C21.024 2.50247 20.4281 2.49079 20.0535 2.85106L15.5293 7.20119V3.2942Z"
                fill="black" fillOpacity="0.85"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.0618 10.3658C10.1048 10.5036 10.1675 10.6827 10.255 10.8876C10.471 11.3928 10.8281 12.0304 11.3928 12.5952C11.9575 13.1599 12.5936 13.5154 13.0968 13.7298C13.3006 13.8167 13.4787 13.8787 13.6158 13.9213L15.5163 12.971C16.0722 12.693 16.7296 12.7074 17.2728 13.0094L19.3524 14.1656C19.9498 14.4977 20.3202 15.1275 20.3202 15.8109V18.1984C20.3202 20.0026 18.6104 21.4075 16.7967 20.7954C14.4109 19.9904 10.5573 18.4148 8.06531 15.9228C5.57326 13.4308 3.99763 9.57714 3.19263 7.19139C2.58065 5.37765 3.98548 3.66791 5.78969 3.66791H8.17684C8.8607 3.66791 9.49066 4.03883 9.82255 4.63656L10.9738 6.71042C11.2753 7.25341 11.2895 7.91025 11.0117 8.46588L10.0618 10.3658ZM5.78969 5.55026C5.16222 5.55026 4.81074 6.09923 4.97619 6.58958C5.76791 8.93598 7.23247 12.4279 9.39632 14.5917C11.5601 16.7556 15.0521 18.2202 17.3985 19.0119C17.8888 19.1773 18.4378 18.8259 18.4378 18.1984V15.8109L16.3581 14.6546L14.1403 15.7635C13.9554 15.856 13.7451 15.885 13.5421 15.8461L13.5409 15.8458L13.5395 15.8456L13.5363 15.8449L13.528 15.8433L13.5043 15.8383C13.4854 15.8343 13.4603 15.8287 13.4295 15.8215C13.368 15.8069 13.2835 15.7855 13.18 15.7556C12.9735 15.696 12.6889 15.6022 12.3588 15.4615C11.7023 15.1817 10.8423 14.7067 10.0618 13.9263C9.2815 13.1459 8.80524 12.2848 8.52423 11.6275C8.38292 11.297 8.28841 11.012 8.22828 10.8052C8.19815 10.7015 8.17649 10.617 8.16179 10.5554C8.15444 10.5246 8.14881 10.4995 8.14473 10.4806L8.13975 10.4569L8.13808 10.4487L8.13744 10.4455L8.13717 10.4441C8.13717 10.4441 8.13693 10.4429 9.06079 10.2632L8.13693 10.4429C8.0973 10.2391 8.12614 10.028 8.21898 9.84229L9.32806 7.62408L8.17687 5.55033L5.78969 5.55026Z"
                  fill="black" fillOpacity="0.85"/>
        </svg>

    );
};
