import React from 'react';
import {UnmountClosed} from 'react-collapse';
import styles from '../style.module.scss';
import {Option} from '../../../store/types';
import {IFilter, TreeNode} from '../store/type';
import {SelectFormRow} from '../../selectFormRow';

interface FilterProps {
    defaultFilter: IFilter[];
    isCollapsed: boolean;
    optObjects: Option[];
    optSolver: Option[];
    optAuthors: Option[];
    optThemes: TreeNode[];
    handleSelectObj: (value: string) => void;
    handleSelectSolver: (value: string) => void;
    handleSelectAuthors: (value: string) => void;
    handleSelectTheme: (value: string) => void;
    applyFilter: () => void;
    clearFilter: () => void;
}

export const Filter = (
    {
        defaultFilter,
        isCollapsed,
        optObjects,
        optThemes,
        optSolver,
        optAuthors,
        handleSelectObj,
        handleSelectSolver,
        handleSelectAuthors,
        handleSelectTheme,
        applyFilter,
        clearFilter
    }: FilterProps) => {

    const defaultObj = defaultFilter.find(i => i.type === 'ID_Obj')?.value ?? '';
    const defaultThemes = defaultFilter.find(i => i.type === 'ID_Sect')?.value ?? '';
    const defaultSolver = defaultFilter.find(i => i.type === 'ID_Solver')?.value ?? '';
    const defaultAuthor = defaultFilter.find(i => i.type === 'ID_Author')?.value ?? '';

    return (
        <UnmountClosed isOpened={isCollapsed}>
            <div className={styles.filterWrapper}>
                <div className={styles.filterCollapse}>
                    <SelectFormRow
                        options={optObjects}
                        label={'Объекты'}
                        disabled={false}
                        isTreeOptions={false}
                        defaultValue={defaultObj as string}
                        handleSelect={handleSelectObj}
                    />
                    <SelectFormRow
                        options={optThemes}
                        label={'Темы'}
                        disabled={false}
                        isTreeOptions={true}
                        defaultValue={defaultThemes as string}
                        handleSelect={handleSelectTheme}
                    />
                    <SelectFormRow
                        options={optSolver}
                        label={'Отв.'}
                        disabled={false}
                        isTreeOptions={false}
                        defaultValue={defaultSolver as string}
                        handleSelect={handleSelectSolver}
                    />
                    <SelectFormRow
                        options={optAuthors}
                        label={'Автор'}
                        disabled={false}
                        isTreeOptions={false}
                        defaultValue={defaultAuthor as string}
                        handleSelect={handleSelectAuthors}
                    />
                    <div className={styles.filterButtons}>
                        <button
                            className={styles.filterButtonsButton}
                            onClick={clearFilter}
                        >Очистить фильтр
                        </button>
                        <button
                            className={styles.filterButtonsButton}
                            onClick={applyFilter}
                        >Применить фильтр
                        </button>
                    </div>
                </div>
            </div>

        </UnmountClosed>
    );
};
