export const mainColor = '#C32B46';
export const greyColor = '#c4c4c4';
export const disabledColor = '#ececec';
export const iconRuble = '₽';

export const APP_VERSION = '0.0.1';

export const CONTENT_TYPE_URLENCODED: { [key: string]: string } = {
    'Content-Type': 'application/x-www-form-urlencoded'
};
export const CONTENT_TYPE_MULTIPART: { [key: string]: string } = {
    'Content-Type': 'multipart/form-data;'
};
export const CONTENT_TYPE_JSON: { [key: string]: string } = {
    'Content-Type': 'application/json'
};

export const validState = ['Отличное', 'Хорошее', 'Среднее', 'Плохое'];

export const colorsView: any = {
    '0': '#FFFFFF',
    '1': '#FFB0FF',
    '2': '#FFB0B0',
    '3': '#FFFFB0',
    '4': '#B0FFB0',
    '5': '#B0FFFF',
    '6': '#B0E0FF',
    '7': '#B0B0FF'
};
