import React, {ReactNode} from 'react';
import styles from '../style.module.scss';
import {QuizItem} from '../store/types';

interface RenderQuestionProps {
    children: ReactNode;
    question: QuizItem;
    count: string;
}

export const RenderQuestion = ({children, question, count}: RenderQuestionProps) => {
    return (
        <>
            <p className={styles.count}>{count}</p>
            <div className={styles.wrapperText}>
                <p className={styles.text}>{question?.question}</p>
            </div>
            {children}
        </>
    );
};
