import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import {Chart} from './store/types';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

export const createChartRange = (chartId: string, items: Chart[], isPlan: boolean) => {
    let root = am5.Root.new(chartId);

    root.setThemes([
        am5themes_Animated.new(root)
    ]);

    if (root._logo) {
        root._logo.dispose();
    }

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingLeft: 0,
        paddingRight: 0,
        layout: root.verticalLayout,
        width: am5.percent(100)
    }));

    let data = items.map(i => {
        const full = i.value + Math.floor(Math.random() * 501);
        return {...i, plannedValue: full - i.value};
    });

    let xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minorGridEnabled: true
    });

    xRenderer.grid.template.setAll({
        visible: false,
        location: 1
    });
    xRenderer.labels.template.set('forceHidden', true);
    xRenderer.ticks.template.set('forceHidden', true);

    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: 'name',
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
    }));

    xAxis.data.setAll(data);

    let yRenderer = am5xy.AxisRendererY.new(root, {
        strokeOpacity: 0.1
    });

    yRenderer.labels.template.set('forceHidden', true);
    yRenderer.ticks.template.set('forceHidden', true);

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer
    }));

    function makeSeries(name: string, fieldName: string, stacked: boolean) {
        let series = chart.series.push(am5xy.ColumnSeries.new(root, {
            stacked: stacked,
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: 'name'
        }));

        series.columns.template.setAll({
            width: am5.percent(90),
        });
        series.data.setAll(data);

        series.appear();

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    fill: root.interfaceColors.get('alternativeText'),
                    populateText: true
                })
            });
        });

        series.columns.template.setAll({
            tooltipY: 0,
            shadowOpacity: 0.1,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
            shadowBlur: 1,
            strokeWidth: 1,
            stroke: am5.color(0x1E851E),
            shadowColor: fieldName === 'value' ? am5.color(0x87E687) : am5.color(0xEEEEEE),
            fill: fieldName === 'value' ? am5.color(0x87E687) : am5.color(0xEEEEEE),
            cornerRadiusTL: fieldName === 'value' ? 0 : 4,
            cornerRadiusTR: fieldName === 'value' ? 0 : 4,
        });
    }

    makeSeries('Value', 'value', false);
    isPlan && makeSeries('PlannedValue', 'plannedValue', true);

    return root;
};
