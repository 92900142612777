import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import {ListMenu} from '../home/store/types';
import {getObjectByIdForm} from '../../helpers';
import {useLazyGetFinancialListQuery} from './state/financial.api';
import {FinancialItem} from './components';
import styles from './style.module.scss';
import {FinancialFilter} from '../../components/financialFilter';
import {
    dateKeys,
    setClearFinancialFilter,
    setFinancialFilter
} from '../../components/financialFilter/store/financial.filter.slice';
import {ScrollUp} from '../../components/scrollUp';
import {CARD_HEIGHT, parseQueryString} from '../taskList/helpers';
import Footer from '../../components/footer';
import {FinancialFilterItemReq} from '../../components/financialFilter/store/type';
import {Financial} from './state/types';

export interface FinancialState {
    ID_App: string;
    ID_Obj: string;
    idForm?: string;
}

export const FinancialList = () => {
    const navigate = useNavigate();
    const {idForm} = useParams<{ idForm: string }>();
    const dispatch = useAppDispatch();
    let [searchParams, setSearchParams] = useSearchParams();

    const {keys} = useAppSelector(state => state.financialFilter);
    const {data} = useGetAllQuestionsQuery();
    const [getFinancialLIst, {data: list}] = useLazyGetFinancialListQuery();

    let cardVariable: ListMenu | null = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));
    const idParent = cardVariable !== null ? cardVariable.idParent : null;

    useEffect(() => {
        if (cardVariable) {
            const params = keys.map(key => `${FinancialFilterItemReq[key.type]}=${key.value}`).join('&');
            getList(params);
        }
    }, [cardVariable]);

    useEffect(() => {
        const {currentIndex} = parseQueryString(searchParams);

        if (currentIndex > 0) {
            const mainContainer = document.getElementById('main-container');
            if (mainContainer) {
                setTimeout(() => {
                    mainContainer.scrollTo({
                        top: currentIndex * CARD_HEIGHT,
                        behavior: 'smooth'
                    });
                    setSearchParams(new URLSearchParams());
                }, 500);
            }
        }
    }, []);


    function getList(params: string) {
        getFinancialLIst({path: `${cardVariable?.file}&${params}`});
    }

    const applyFilter = (isInit: boolean) => {
        if (isInit) {
            dispatch(setClearFinancialFilter());
        }

        const params = isInit
            ? dateKeys.map(key => `${FinancialFilterItemReq[key.type]}=${key.value}`).join('&')
            : keys.map(key => `${FinancialFilterItemReq[key.type]}=${key.value}`).join('&');

        getList(params);
    };

    const onSearch = (text: string) => {
        if (keys.some(k => k.type === 'Search')) {
            const newKeys = keys.map(k => k.type === 'Search' ? {...k, value: text} : k);
            dispatch(setFinancialFilter(newKeys));
            const params = newKeys.map(key => `${FinancialFilterItemReq[key.type]}=${key.value}`).join('&');
            getList(params);
        } else {
            dispatch(setFinancialFilter([...keys, {type: 'Search', value: text}]));
            const params = [...keys, {
                type: 'Search',
                value: text
            }].map(key => `${FinancialFilterItemReq[key.type]}=${key.value}`).join('&');
            getList(params);
        }
    };

    const handleSetIndex = (value: string, type: 'index') => {
        const updatedParams = new URLSearchParams(searchParams);
        if (updatedParams.has(type)) {
            updatedParams.delete(type);
        }
        updatedParams.append(type, value);
        setSearchParams(updatedParams);
    };

    const handleClick = (item: Financial) => {
        const currentIndex = list?.listFinApp.findIndex(i => i.ID === item.ID);
        handleSetIndex(String(currentIndex), 'index');

        const state: FinancialState = {
            ID_App: String(item.ID),
            ID_Obj: String(item.ID_Obj),
            idForm: idForm
        };

        navigate('/financial-create', {state});
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Фин. заявки'}/>
            <BackButton text={'Назад'} onClick={() => navigate(`/financial-menu/${idParent}`)}/>
            <FinancialFilter
                keys={keys}
                useInputSearch={true}
                applyFilter={applyFilter}
                onSearch={text => onSearch(text)}
            />
            <div className={styles.cardList}>
                {list?.listFinApp.map(listItem => {
                    return (
                        <FinancialItem
                            key={listItem.ID}
                            item={listItem}
                            handleClick={handleClick}/>
                    );
                })}
            </div>
            <ScrollUp/>
            <Footer/>
        </MainLayout>
    );
};
