import React from 'react';
import styles from './style.module.scss';
import {MainLayout} from "../../layouts";

const AppLoader = ({isFull}: { isFull: boolean }) => {
    if (isFull) {
        return (
            <MainLayout>
                <div className={styles.container}>
                    <p className={styles.loaderText}>идет загрузка...</p>
                </div>
            </MainLayout>
        );
    }

    return <div className={styles.container}>
        <p className={styles.loaderText}>идет загрузка...</p>
    </div>;
};

export default AppLoader;
