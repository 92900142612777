import React from 'react';
import {useControls} from 'react-zoom-pan-pinch';
import styles from '../style.module.scss';
import {
    ClearOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    ShareAltOutlined,
    SyncOutlined
} from '@ant-design/icons';

interface ControlProps {
    handleShare: () => void;
    loader: boolean;
    positionIndex: number;
    setPosIndex: (index: number) => void;
}

export const Controls = ({handleShare, loader, positionIndex, setPosIndex}: ControlProps) => {

    const {zoomIn, zoomOut, resetTransform, setTransform} = useControls();

    const increment = () => {
        setPosIndex(positionIndex + 1);
        zoomIn();
    };

    const decrement = () => {
        zoomOut();
        setPosIndex(positionIndex > 0 ? positionIndex - 1 : 0);
    };
    const clear = () => {
        resetTransform();
        setPosIndex(0);
        setTransform(0, 0, 1);
    };
    return (
        <div className={styles.pdfControls}>
            <button className={styles.pdfControlsButton} onClick={() => handleShare()}>
                {loader ? <SyncOutlined spin/> : <ShareAltOutlined/>}
            </button>
            <button className={styles.pdfControlsButton} onClick={increment}>
                <PlusCircleOutlined/>
            </button>
            <button className={styles.pdfControlsButton} onClick={decrement}>
                <MinusCircleOutlined/>
            </button>
            <button className={styles.pdfControlsButton} onClick={clear}>
                <ClearOutlined/>
            </button>
        </div>
    );
};
