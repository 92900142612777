import React from 'react';
import styles from '../style.module.scss';
import {formatDate, formatNumber, isDaysLeft} from '../../../helpers';
import classNames from 'classnames';
import {Financial} from '../state/types';
import {colorsView, iconRuble} from '../../../utils/constants';

interface TaskItemProps {
    item: Financial;
    handleClick: (item: Financial) => void;
}

export const FinancialItem = ({item, handleClick}: TaskItemProps) => {

    const date = new Date(item.DateApp.date.replace(' ', 'T'));

    return (
        <div
            className={styles.card}
            onClick={() => handleClick(item)}
        >
            <div className={styles.header}>
                <div className={styles.flex}>
                    <span className={styles.secondText}>№ {item.ID}</span>
                </div>
                <div className={styles.flex}>
                    <i className={classNames(styles.descriptionIcon, 'far fa-calendar-alt')}/>
                    <span className={styles.secondText}>{formatDate({date, type: 'DD.MM.YY'})}</span>
                </div>
            </div>
            <div className={classNames(styles.description)}>
                <div className={classNames(styles.descriptionItem)}>
                    <i className={classNames(styles.descriptionIcon, 'far fa-user')}/>
                    <span className={classNames(styles.secondText)}>{item.UserName}</span>
                </div>
                <div className={classNames(styles.descriptionItem)}>
                    <i className={classNames(styles.descriptionIcon, 'far fa-user')}/>
                    <span className={classNames(styles.secondText)}>{item.Payer}</span>
                    <span className={styles.descriptionSeparator}>{`->`}</span>
                    <span className={styles.secondText}>{item.Kontra}</span>
                </div>

            </div>

            <h1 className={styles.title}>{item.Rem}</h1>
            <div className={classNames(styles.textWrap)}>
                <p className={classNames(styles.text, styles.textSum)}>{formatNumber(item.SumApp)} {iconRuble}</p>
                <p className={classNames(styles.text)}>оплатить до: {item.DateWant.date.split(' ')[0]}</p>
            </div>
            <div className={styles.footer}>
                <div className={styles.object}>
                    <i className={classNames(styles.descriptionIcon, 'fas fa-university')}/>
                    <span className={classNames(styles.secondText)}>{item.ObjName}</span>
                </div>
                <div
                    className={classNames(styles.status, styles.statusMr)}
                    style={{backgroundColor: colorsView[item.ID_Color]}}>
                    <span className={styles.statusText}/>
                </div>
                <div>
                    <i className={
                        isDaysLeft(item.DateWant.date.split(' ')[0], 2) && item.flagPaid === 1
                            ? classNames(styles.iClass, styles.iClassAnimate, styles.iClassMr10, 'fas fa-bell')
                            : classNames(styles.iClass, styles.iClassMr10, 'fas fa-bell')
                    }
                    />
                </div>
                <div className={styles.status}>
                    <span className={styles.statusText}>{item.PayState}</span>
                </div>
            </div>
        </div>
    );
};
