import React, {useId, useLayoutEffect} from 'react';
import {createCircleChart} from "../createCircleChart";
import {OptionChart} from "../store/types";

interface ChartProps {
    chart: OptionChart[];
    handleClickByLegend: (category: string, value: string) => void;
}

export const Chart = ({chart, handleClickByLegend}: ChartProps) => {
    const chartId = useId();

    useLayoutEffect(() => {

        const root = createCircleChart(chartId, chart, handleClickByLegend);

        return () => {
            root.dispose();
        };
    }, [chart]);

    return <div id={chartId} style={{width: "100%", height: "400px", marginTop: '20px'}}/>;
};
