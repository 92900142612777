import React, {useMemo, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {TransformComponent, TransformWrapper, useControls,} from 'react-zoom-pan-pinch';
import {useGetPdfQuery, useLazyGetPdfQuery} from '../quiz/store/quiz.api';
import {useLocation, useNavigate} from 'react-router-dom';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './style.module.scss';
import AppLoader from '../../components/appLoader';
import {Pagination} from './components';
import {
    ClearOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    ShareAltOutlined,
    SyncOutlined
} from '@ant-design/icons';
import {useOrientation} from '../../hooks';
import {toast} from 'react-toastify';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


interface ControlProps {
    handleShare: () => void;
    loader: boolean;
    positionIndex: number;
    setPosIndex: (index: number) => void;
}

const Controls = ({handleShare, loader, positionIndex, setPosIndex}: ControlProps) => {

    const {zoomIn, zoomOut, resetTransform, setTransform} = useControls();

    const increment = () => {
        setPosIndex(positionIndex + 1);
        zoomIn();
    };

    const decrement = () => {
        zoomOut();
        setPosIndex(positionIndex > 0 ? positionIndex - 1 : 0);
    };
    const clear = () => {
        resetTransform();
        setPosIndex(0);
        setTransform(0, 0, 1);
    };
    return (
        <div className={styles.pdfControls}>
            <button className={styles.pdfControlsButton} onClick={() => handleShare()}>
                {loader ? <SyncOutlined spin/> : <ShareAltOutlined/>}
            </button>
            <button className={styles.pdfControlsButton} onClick={increment}>
                <PlusCircleOutlined/>
            </button>
            <button className={styles.pdfControlsButton} onClick={decrement}>
                <MinusCircleOutlined/>
            </button>
            <button className={styles.pdfControlsButton} onClick={clear}>
                <ClearOutlined/>
            </button>
        </div>
    );
};

export const PdfView = () => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const {orientation} = useOrientation();
    const {data, isLoading} = useGetPdfQuery({path: state?.path}, {
        skip: !state?.path
    });
    const [getFile, {isFetching}] = useLazyGetPdfQuery();
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [positionIndex, setPosIndex] = useState<number>(0);
    const file = useMemo(() => {
        return isLoading && !data ? null : {url: data?.url as string};
    }, [isLoading, data]);

    function onDocumentLoadSuccess({numPages}: { numPages: number }): void {
        setNumPages(numPages);
    }

    const renderPage = useMemo(() => (
        <Page
            pageNumber={pageNumber}
            canvasBackground={'#ffffff'}
            width={window.innerWidth - 40}
            height={window.innerHeight}
        />
    ), [orientation]);

    const handleShare = async () => {
        try {
            const path = state?.path.replace('answerType=1', 'answerType=10');

            const data = await getFile({path}).unwrap();
            const blob = new Blob([data], {type: 'application/pdf'});
            const file = new File([blob], `${state?.screenViewName || 'default'}.pdf`, {type: 'application/pdf'});

            if (navigator.share) {
                try {
                    await navigator.share({
                        title: state?.screenViewName || 'Document',
                        files: [file]
                    });
                } catch (error) {
                    toast.error(`Sharing failed: ${JSON.stringify(error, null, 2)}`);
                }
            } else {
                toast.error('Ваш браузер не поддерживает API Share');
            }
        } catch (error: any) {
            toast.error(`Error: ${JSON.stringify(error, null, 2) || 'An unexpected error occurred'}`);
        }
    };

    return (
        <MainLayout>
            <Header breadCrumbs={state?.screenViewName ? state?.screenViewName : 'ПДФ'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.container}>
                <TransformWrapper
                    disabled={false}
                >
                    <>
                        <Controls
                            handleShare={handleShare}
                            loader={isFetching}
                            positionIndex={positionIndex}
                            setPosIndex={setPosIndex}
                        />
                        <TransformComponent wrapperClass={styles.transformWrap}>
                            {isLoading
                                ? <AppLoader isFull={false}/>
                                : (
                                    <>
                                        {file
                                            ? <Document
                                                file={file}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                                renderMode={'canvas'}
                                                loading={'Загружаем ПДФ'}
                                                className={styles.doc}
                                            >
                                                {renderPage}
                                            </Document> : null}
                                        {numPages > 1 ? <Pagination
                                            totalPages={numPages}
                                            onPageChange={(page) => setPageNumber(page)}
                                        /> : null}
                                    </>
                                )}
                        </TransformComponent>
                    </>
                </TransformWrapper>
            </div>
        </MainLayout>
    );
};
