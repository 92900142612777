import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import styles from './style.module.scss';
import {MainLayout} from "../../layouts";
import {BackButton} from "../../components/backButton";
import Header from "../../components/header";

export const EmptyPage = () => {
    const navigate = useNavigate();
    const {state} = useLocation();

    return (
        <MainLayout>
            <Header breadCrumbs={'Страница в разработке'} />
            <BackButton text={'Назад'} onClick={() => navigate(-1)} />
            <div className={styles.content}>
                <p>{state.text}</p>
            </div>
        </MainLayout>
    );
};
