import React from 'react';
import styles from '../style.module.scss';
import {ImageLayout} from '../../../layouts';
import {Button} from '../../../fields/button';
import {mainColor} from '../../../utils/constants';

const iconFail = require('../../../assets/images/face-fail.png');

interface SuccessProps {
    onConfirm: () => void;
    onCancel: () => void;
}

export const ErrorReport = ({onConfirm, onCancel}: SuccessProps) => {

    return (
        <>
            <p className={styles.modalText}>{'В ответили не на все вопросы'}</p>
            <p className={styles.modalText}>{'Для того чтобы аттестация прошла необходимо заполнить все вопросы.'}</p>
            <ImageLayout
                src={iconFail}
                imageWidth={64}
                imageHeight={64}
                containerWidth={120}
                containerHeight={120}/>

            <div className={styles.modalButtons}>
                <Button
                    text={'Выйти'}
                    textStyleType={'regular'}
                    onClick={onCancel}
                    style={{
                        border: `1px solid ${mainColor}`,
                        borderRadius: '12px',
                        fontSize: '14px',
                        height: '44px',
                        marginRight: '3px'
                    }}/>
                <Button
                    text={'Продолжить'}
                    textStyleType={'regular'}
                    onClick={onConfirm}
                    style={{
                        border: `1px solid ${mainColor}`,
                        backgroundColor: mainColor,
                        borderRadius: '12px',
                        fontSize: '14px',
                        height: '44px',
                        color: '#ffffff',
                        marginLeft: '3px'
                    }}/>
            </div>
        </>
    );
};
