import React from 'react';
import styles from './style.module.scss';

interface TextareaProps {
    placeholder?: string;
    value: string;
    onChange: (value: string) => void;
    onBlur: (value: string) => void;
}

export const Textarea = ({placeholder, value, onBlur, onChange}: TextareaProps) => {

    return (
        <textarea
            value={value}
            placeholder={placeholder}
            onChange={(event) => onChange(event.target.value)}
            onBlur={(event) => onBlur(event.target.value)}
            className={styles.textarea}/>
    );
};
