import {createSlice} from "@reduxjs/toolkit";
import {TaskMenuState} from "./types";

const initialState: TaskMenuState = {
    quantity: []
};

const taskTypesSlice = createSlice({
    name: 'taskMenu',
    initialState,
    reducers: {
        setQuantity: (state, {payload}) => {
            if (!state.quantity.some(i => i.mode === payload.mode)) {
                state.quantity.push(payload);
            } else {
                state.quantity = state.quantity.map(i => (i.mode === payload.mode ? payload : i));
            }
        },
        clearQuantity: (state) => {
            state.quantity = [];
        }
    }
});

export const {setQuantity, clearQuantity} = taskTypesSlice.actions;
export default taskTypesSlice.reducer;
