import React, {useEffect} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './style.module.scss';
import {Button} from '../../fields/button';
import {mainColor} from '../../utils/constants';
import {Input} from '../../fields/input';
import {TextareaControl} from '../../fields/textarea';
import {Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks/app.hooks';
import {useActionDeviceMutation} from './store/inventory.api';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {IInventory} from './store/types';
import {setInventoryForm} from './store/inventory.slice';
import {fromJsonToUrlencoded} from '../../helpers';
import {toast} from 'react-toastify';

const schema = yup.object().shape({
    Type: yup.mixed().required().default('Insert'),
    ID: yup.mixed(),
    ID_Device: yup.mixed(),
    DevType: yup.mixed().required('Обязательно заполнить'),
    Name: yup.mixed().required('Обязательно заполнить'),
    Code: yup.mixed().required('Обязательно заполнить'),
    Code1S: yup.mixed(),
    Rem: yup.mixed(),
    DevRem: yup.mixed(),
    BalCost: yup.mixed(),
});

export const InventoryViewCard = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [actionDevice, {isLoading: loadingMiddle}] = useActionDeviceMutation();

    const {currentObj, inventoryForm} = useAppSelector(state => state.inventory);

    const {setValue, watch, control, handleSubmit, clearErrors, reset} = useForm<any>({
        mode: 'onChange',
        defaultValues: inventoryForm,
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        return () => reset();
    }, []);


    const handleBack = () => {
        reset();
        // dispatch(setInventoryForm(defaultInventoryForm));
        navigate(-1);
    };

    const handleSetValue = (field: keyof IInventory, value: string) => {
        clearErrors(field);
        setValue(field, value.length === 0 ? undefined : value);

        dispatch(setInventoryForm({...inventoryForm, [field]: value}));
    };

    const onSubmitMiddle = async (values: IInventory) => {
        const initSave = {
            Type: 'Update',
            DevType: values.DevType,
            ID: values.ID_Device,
            Name: values.Name,
            Code: values.Code,
            Code1S: values.Code1S,
            Rem: values.DevRem,
            BalCost: values.BalCost,
        };
        actionDevice({
            body: fromJsonToUrlencoded({data: initSave})
        })
            .unwrap()
            .then(({status, info}) => {
                if (status) {
                    toast.info('Успешно изменено', {
                        onClose: () => handleBack()
                    });
                }
            });
    };


    return (
        <MainLayout>
            <Header
                breadCrumbs={watch('ID')
                    ? `Редактировать устройство в ${currentObj?.name}`
                    : `Добавить устройство в ${currentObj?.name}`}
            />
            <BackButton
                text={'Назад'}
                onClick={handleBack}
            />

            <form className={styles.inventoryForm}>
                <div className={styles.inventoryFormBox}>
                    <label className={styles.inventoryFormLabel}>Тип устройства</label>
                    <Input
                        type={'text'}
                        fieldName={'DevType'}
                        control={control}
                        defaultValue={watch('DevType')}
                        onChange={value => handleSetValue('DevType', String(value))}/>
                </div>
                <div className={styles.inventoryFormBox}>
                    <label className={styles.inventoryFormLabel}>Название устройства (модель)</label>
                    <Input
                        type={'text'}
                        fieldName={'Name'}
                        control={control}
                        defaultValue={watch('Name')}
                        onChange={value => handleSetValue('Name', String(value))}/>
                </div>
                <div className={styles.inventoryFormBox}>
                    <label className={styles.inventoryFormLabel}>Штрихкод</label>
                    <Input
                        type={'text'}
                        fieldName={'Code'}
                        readOnly={true}
                        control={control}
                        defaultValue={watch('Code')}
                        onChange={value => handleSetValue('Code', String(value))}
                    />

                </div>
                <div className={styles.inventoryFormBox}>
                    <label className={styles.inventoryFormLabel}>Код 1С</label>
                    <Input
                        type={'text'}
                        fieldName={'Code1S'}
                        control={control}
                        defaultValue={watch('Code1S')}
                        onChange={value => handleSetValue('Code1S', String(value))}/>
                </div>
                <div className={styles.inventoryFormBox}>
                    <label className={styles.inventoryFormLabel}>Примечание</label>
                    <TextareaControl
                        fieldName={'DevRem'}
                        control={control}
                        defaultValue={watch('DevRem')}
                        onChange={value => handleSetValue('DevRem', String(value))}/>
                </div>
                <div className={styles.inventoryFormBox}>
                    <label className={styles.inventoryFormLabel}>Балансовая стоимость</label>
                    <Input
                        type={'number'}
                        fieldName={'BalCost'}
                        control={control}
                        defaultValue={watch('BalCost')}
                        onChange={value => handleSetValue('BalCost', String(value))}/>
                </div>
                <div className={styles.inventoryFormBox}>
                    {loadingMiddle ?
                        <Spin indicator={<LoadingOutlined spin style={{color: '#fff'}}/>} size="large"
                              style={{height: '54px'}}/> :
                        <Button
                            text={'Сохранить'}
                            textStyleType={'semiBold'}
                            style={{
                                border: `1px solid ${mainColor}`,
                                borderRadius: '8px',
                                width: '100%',
                                height: '54px'
                            }}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit(onSubmitMiddle)();
                            }}
                        />}
                    <div className={'space-10'}/>

                </div>
            </form>
        </MainLayout>
    );
};
