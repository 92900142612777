import {api} from '../../../store/api';
import {ICondition, ITask, ITheme, Signature, TaskFile} from './types';
import {CONTENT_TYPE_URLENCODED} from '../../../utils/constants';
import {IPermission, Option} from '../../../store/types';
import {FileFromBack} from '../../quiz/store/types';
import {ListIdName, TreeNode} from '../../../components/taskFilter/store/type';

export const formatThemes = (originalObject: ITheme[]): TreeNode[] => {
    const transformedArray = [];

    for (const key in originalObject) {
        if (Object.hasOwnProperty.call(originalObject, key)) {
            const item = originalObject[key];
            const transformedItem: TreeNode = {
                title: item.name ?? 'нет названия',
                value: item.id?.toString() ?? 'нет названия',
                key: item.id?.toString() ?? 'нет названия',
            };

            if (item.childs && item.childs.length > 0) {
                transformedItem.children = item.childs.map(child => ({
                    title: child.name,
                    value: child.id.toString(),
                    key: child.id.toString(),
                }));
            }

            transformedArray.push(transformedItem);
        }
    }

    return transformedArray as TreeNode[];
};

export const taskApi = api.injectEndpoints({
    endpoints: build => ({
        actionTask: build.mutation<{ status: boolean, message?: string, info: ITask }, { data: string }>({
            query: ({data}) => ({
                url: `/helpDesk/r_App.php`,
                method: 'POST',
                body: data,
                headers: {
                    ...CONTENT_TYPE_URLENCODED
                }
            })
        }),
        getUserObjects: build.mutation<{ listObject: Option[] }, { body: string }>({
            query: ({body}) => ({
                url: '/listData/p_Obj_new.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({listObject}: { listObject: ListIdName[] }) => {
                return {listObject: Object.values(listObject).map(i => ({label: i.name, value: String(i.id)}))};
            },

        }),
        getThemes: build.mutation<{ treeList: TreeNode[] }, { body: string }>({
            query: ({body}) => ({
                url: '/helpDesk/p_AppSect.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({treeList}) => {
                return {treeList: formatThemes(Object.values(treeList))};
            },
        }),
        getAuthor: build.mutation<any, void>({
            query: () => ({
                url: '/helpDesk/p_App_listAuthor.php',
                method: 'POST',
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({list}) => {
                return list;
            },
        }),
        getSolver: build.mutation<{ list: Option[] }, { body: string }>({
            query: ({body}) => ({
                url: '/helpDesk/p_User.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({list}: { list: ListIdName[] }) => {
                return {list: list.map(i => ({label: i.name, value: String(i.id)}))};
            },
        }),
        getPriority: build.query<{ list: Option[] }, void>({
            query: () => ({
                url: '/helpDesk/p_AppPriority.php',
                method: 'GET'
            }),
            transformResponse: ({list}: { list: ListIdName[] }) => {
                return {list: list.map(i => ({label: i.name, value: String(i.id)}))};
            }
        }),
        getTaskFiles: build.mutation<{ files: FileFromBack[] }, { body: string }>({
            query: ({body}) => ({
                url: '/helpDesk/p_AppNoteFile.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({files}) => {
                return {
                    files: files?.map((f: TaskFile) => ({
                        id: f.ID_File, fileName: f.NameCurr, origName: f.NameOrig, url: f.url
                    })) ?? []
                };
            }
        }),
        getPermissionTask: build.query<IPermission, { ID_App: number }>({
            query: ({ID_App}) => ({
                url: `/helpDesk/p_AppSectPermission.php?ID_App=${ID_App}`,
                method: 'GET'
            }),
            transformResponse: ({permission}) => {
                return permission;
            }
        }),
        getSignatures: build.mutation<{ listVotes: Signature[] }, { body: string }>({
            query: ({body}) => ({
                url: '/helpDesk/p_AppVote.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({listVotes}) => {
                return {listVotes: Object.values(listVotes)};
            }
        }),

        getConditions: build.mutation<{ appFields: ICondition[] }, { body: string }>({
            query: ({body}) => ({
                url: '/helpDesk/p_AppField.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({appFields}) => {
                return {appFields};
            }
        }),
        viewedTask: build.mutation<void, { body: string }>({
            query: ({body}) => ({
                url: '/helpDesk/r_AppOpen.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            })
        }),
        saveConditions: build.mutation<void, { body: FormData }>({
            query: ({body}) => ({
                url: '/helpDesk/r_AppField.php',
                method: 'POST',
                body,
            })
        }),
        getTaskById: build.query<ITask | null, { ID_App: string }>({
            query: ({ID_App}) => ({
                url: `/helpDesk/p_App_listTask.php?FApps=${ID_App}`,
                method: 'GET'
            }),
            transformResponse: ({listTask}) => {
                return listTask[0] ? listTask[0] : null;
            }
        })
    }), overrideExisting: true
});

export const {
    useActionTaskMutation,

    useGetUserObjectsMutation,
    useGetThemesMutation,
    useGetAuthorMutation,
    useGetSolverMutation,
    useGetPriorityQuery,
    useGetTaskFilesMutation,
    useGetPermissionTaskQuery,
    useGetSignaturesMutation,

    useGetConditionsMutation,
    useViewedTaskMutation,
    useSaveConditionsMutation,

    useLazyGetTaskByIdQuery
} = taskApi;
