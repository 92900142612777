import React from 'react';
import styles from '../style.module.scss';

export const QuizLoader = () => {
    return (
        <div className={styles.loaderWrapper}>
            <p className={styles.loaderText}>идет загрузка...</p>
        </div>
    );
};
