import React, {ReactNode} from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';


interface SimpleButtonProps {
    handler: () => void;
    children: ReactNode;
    column: 1 | 2 | 3;
    isActive?: boolean;
}

const SimpleButton = ({children, handler, column, isActive = false}: SimpleButtonProps) => {
    return (
        <button className={isActive ? classNames(styles.qCard, styles.qCardActive) : styles.qCard} style={{
            width: `calc(100% / ${column} - 10px)`
        }} onClick={handler}>
            {children}
        </button>
    );
};

export default SimpleButton;
