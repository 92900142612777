import React from 'react';

export const OutgoingCall = ({color}: { color: string }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.82877 4.02196C8.51254 4.02196 9.14254 4.39281 9.47452 4.99069L10.6258 7.06449C10.9272 7.60757 10.9414 8.26443 10.6636 8.81993L9.71273 10.7201L9.72767 10.766L9.81688 11.0181C9.99604 11.4911 10.2453 11.9648 10.5732 12.4035C10.7176 12.5967 10.8747 12.7792 11.0448 12.9493C11.2128 13.1173 11.3927 13.2724 11.5831 13.4152C12.0219 13.7441 12.4957 13.9936 12.9687 14.1723C13.061 14.2072 13.1455 14.2366 13.2207 14.2611L13.2657 14.2751L15.1682 13.3251C15.6736 13.0724 16.2628 13.0613 16.7739 13.2883L16.9247 13.3635L19.0043 14.5197C19.6017 14.8517 19.9721 15.4815 19.9721 16.1649V18.5524C19.9721 20.4116 18.2122 21.7446 16.4487 21.1495C12.9238 19.9602 9.80116 18.3608 7.71724 16.2768C5.63327 14.1929 4.03392 11.0703 2.84455 7.54543C2.2495 5.78185 3.5825 4.02196 5.44161 4.02196H7.82877ZM7.8288 5.90432H5.44161C4.84631 5.90432 4.45275 6.42391 4.62811 6.94363C5.73258 10.2169 7.21646 13.114 9.04826 14.9458C10.88 16.7776 13.7772 18.2615 17.0505 19.3659C17.5702 19.5413 18.0898 19.1478 18.0898 18.5524V16.1649L16.0101 15.0087L13.7922 16.1176C13.6073 16.2101 13.3971 16.2391 13.194 16.2001C12.4774 16.0627 11.4677 15.6812 10.454 14.9213C10.1936 14.7261 9.94615 14.5127 9.71375 14.2803C9.47856 14.0451 9.26274 13.7944 9.06541 13.5304C8.30942 12.5188 7.92786 11.5116 7.78886 10.7969C7.74922 10.5932 7.77807 10.382 7.8709 10.1963L8.98 7.97811L7.8288 5.90432ZM20.3578 2.70703C20.8405 2.70703 21.2383 3.07036 21.2927 3.53845L21.299 3.64821V9.76585C21.299 10.2857 20.8776 10.707 20.3578 10.707C19.8752 10.707 19.4774 10.3437 19.423 9.87562L19.4167 9.76585L19.4152 6.09403L14.8925 10.4443C14.5179 10.8046 13.922 10.7929 13.5618 10.4182C13.2292 10.0723 13.2136 9.53805 13.5078 9.17454L13.5879 9.08742L18.2652 4.58903L14.2402 4.58938C13.7575 4.58938 13.3597 4.22605 13.3054 3.75797L13.299 3.64821C13.299 3.16554 13.6624 2.76773 14.1304 2.71336L14.2402 2.70703H20.3578Z"
                fill="black" fillOpacity="0.85"/>
            <path
                d="M7.82886 5.9043H5.44168C4.84637 5.9043 4.45282 6.42389 4.62818 6.94361C5.73265 10.2169 7.21652 13.114 9.04833 14.9458C10.8801 16.7776 13.7773 18.2615 17.0506 19.3659C17.5702 19.5413 18.0898 19.1477 18.0898 18.5524V16.1649L16.0101 15.0087L13.7923 16.1176C13.6074 16.21 13.3971 16.239 13.1941 16.2001C12.4774 16.0626 11.4677 15.6812 10.454 14.9212C10.1937 14.726 9.94621 14.5127 9.71381 14.2803C9.47862 14.0451 9.26281 13.7944 9.06548 13.5303C8.30948 12.5188 7.92792 11.5116 7.78892 10.7969C7.74929 10.5932 7.77813 10.382 7.87097 10.1963L8.98007 7.97809L7.82886 5.9043Z"
                fill={color}/>
        </svg>
    );
};
