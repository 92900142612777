import {createSlice} from "@reduxjs/toolkit";
import {quizApi} from "./quiz.api";
import {QuizItem, QuizReqItem} from "./types";
import {LocationItem} from "../../selectLocation/store/types";

export interface QuizState {
    list: QuizItem[];
    answers: QuizReqItem[];
    currentLocation: LocationItem | null;
}

const initialState: QuizState = {
    list: [],
    answers: [],
    currentLocation: null
};

const quizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        clearList: (state) => {
            state.list = [];
        },
        clearAnswers: (state) => {
            state.answers = [];
        },
        setAnswers: (state, {payload}: { payload: QuizReqItem[] }) => {
            state.answers = [...payload];
        },
        setCurrentObjId: (state, {payload}:{payload: LocationItem | null}) => {
            state.currentLocation = payload;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            quizApi.endpoints.getQuiz.matchFulfilled,
            (state, {payload}) => {
                state.list = payload.listQuestions;
            }
        );
    }
});

export const {clearList, setAnswers, clearAnswers, setCurrentObjId} = quizSlice.actions;
export default quizSlice.reducer;
