import {api} from '../../../store/api';
import {ByCourier, ByRangeData, BySource, ByStage, MainData, ObjectPoint, Point} from './types';

const roundByValues = (byCourier: any): any => {
    const roundedCourier = {...byCourier};
    const exclude = ['upt', 'uptm'];
    Object.keys(roundedCourier).forEach((key) => {
        if (exclude.includes(key)) return;

        roundedCourier[key as keyof any] = Math.round(roundedCourier[key as keyof any]);
    });

    return roundedCourier;
};

const roundBySourceValues = (byCourier: BySource): any => {
    const roundedCourier = {...byCourier};
    Object.keys(roundedCourier).forEach((key) => {
        if (!roundedCourier[key as keyof BySource].sum) return;
        roundedCourier[key as keyof BySource].sum = Math.round(roundedCourier[key as keyof BySource].sum);
    });

    return roundedCourier;
};

export const monitoringApi = api.injectEndpoints({
    endpoints: build => ({
        getMainData: build.query<MainData, { params: string }>({
            query: ({params}) => ({
                url: `/reports/keyRevenueIndicatorsToday.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByCourier) => {
                return roundByValues(data);
            }
        }),
        getByRange: build.query<ByRangeData, { params: string }>({
            query: ({params}) => ({
                url: `/reports/keyRevenueIndicators.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByCourier) => {
                return roundByValues(data);
            }
        }),
        getBySource: build.query<BySource, { params: string }>({
            query: ({params}) => ({
                url: `/reports/keyRevenueIndicatorsBySource.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: BySource) => {
                return roundBySourceValues(data);
            }
        }),
        getByCourier: build.query<ByCourier, { params: string }>({
            query: ({params}) => ({
                url: `/reports/revenueAnalysisCouriers.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByCourier) => {
                return roundByValues(data);
            }
        }),
        getByStage: build.query<ByStage, { params: string }>({
            query: ({params}) => ({
                url: `/reports/avgTimeValue.php?${params}`,
                method: 'GET'
            }),
            transformResponse: (data: ByStage) => {
                return roundByValues(data);
            }
        }),
        getObjectInMap: build.query<ObjectPoint[], { params: string }>({
            query: ({params}) => ({
                url: `/listData/listObjectsWithCoordinates.php?${params}`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({list}: { list: ObjectPoint[] }) => {
                return list.filter(i => i.lat !== null && i.lon !== null);
            }
        }),
        getPointAll: build.query<Point[], void>({
            query: () => ({
                url: `/orderHooks/orderMapData.php`,
                method: 'GET'
            }),
            keepUnusedDataFor: 0,
            transformResponse: ({heatmapData}) => {
                return heatmapData;
            }
        })
    }), overrideExisting: true
});

export const {
    useLazyGetMainDataQuery,
    useLazyGetByRangeQuery,
    useLazyGetBySourceQuery,
    useLazyGetByCourierQuery,
    useLazyGetByStageQuery,
    useLazyGetObjectInMapQuery,
    useLazyGetPointAllQuery
} = monitoringApi;
