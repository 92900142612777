import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import {AuthContainer} from './screens/auth';
import {PrivateRouteMain} from './routes';
import {ChecksScreen} from './screens/checks';
import {CheckTypesScreen} from './screens/checkTypes';
import {SelectLocation} from './screens/selectLocation';
import {Quiz, QuizList} from './screens/quiz';
import {SettingsScreen} from './screens/settings';
import {Camera} from './screens/camera';
import {QuicklyTaskTypes, TaskMenu} from './screens/taskTypes';
import {QuicklyTaskCreate, TaskCreate} from './screens/taskCreate';
import {TaskList} from './screens/taskList';
import {PdfView} from './screens/pdfView';
import {InfoType, InfoView} from './screens/info';
import {Home} from './screens/home';
import {Report} from './screens/report';
import {ListSurveys} from './screens/listSurveys';
import {Profile} from './screens/profile';
import {Purchase} from './screens/purchase';
import {Inventory, InventoryCheckForm, InventoryCreateForm, InventoryListPage, ScannerPage} from './screens/inventory';
import {TimeControl} from './screens/timeControl';
import {Courier} from './screens/courier';
import {Marketing} from './screens/marketing';
import {CallCenter} from './screens/callCenter';
import {Products} from './screens/products';
import {ActionList} from './screens/actionList';
import {EmptyPage} from './screens/emptyPage';
import {FinancialCreate, FinancialList, FinancialMenu} from './screens/financial';
import {ViewReport} from './screens/report/ViewReport';
import {PhotoReport, PhotoReportCreate, PhotoReportList} from './screens/photoReport';
import {InventoryViewCard} from './screens/inventory/InventoryViewCard';
import {TestPage} from './screens/test';
import {SelectEmployee} from './screens/selectEmployee';
import {useAppSelector} from './hooks/app.hooks';
import {useSubscribeMutation} from './store/api';
import {toast} from 'react-toastify';
import {useChangeTheme} from './hooks';
import {PhoneCall, PhoneCallCounts} from './screens/phoneCall';
import {TableView} from './screens/tableView';
import {
    Monitoring,
    MonitoringByCourier,
    MonitoringByRange,
    MonitoringBySource,
    MonitoringByStep,
    MonitoringMainData,
    MonitoringMap
} from './screens/monitoring';
import {OrderDetails} from './screens/monitoringOrder';

function urlBase64ToUint8Array(base64String: any) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
}

function App() {
    const {id_con} = useAppSelector(state => state.auth.user);
    const {theme} = useAppSelector(state => state.app);
    const [subscribe] = useSubscribeMutation();

    const {chaneTheme} = useChangeTheme();

    useEffect(() => {
        if (window.location.hostname !== 'localhost') {
            // Запрос разрешения на уведомления
            if ('Notification' in window) {
                setTimeout(() => {
                    if (Notification.permission === 'granted') {
                        navigator.serviceWorker.ready.then(async (registration) => {
                            const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUSH_PUBLIC_KEY);
                            const newSubscription = await registration.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: convertedVapidKey,
                            });
                            return subscribe({newSubscription, id_con});
                        });
                    }

                    Notification.requestPermission().then(permission => {
                        if (permission === 'granted') {
                            navigator.serviceWorker.ready.then(async (registration) => {
                                const convertedVapidKey = urlBase64ToUint8Array(process.env.REACT_APP_PUSH_PUBLIC_KEY);
                                const newSubscription = await registration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: convertedVapidKey,
                                });
                                return subscribe({newSubscription, id_con});
                            });
                        } else {
                            toast.error('Разрешите, в настройках устройства, уведомления для приложения');
                        }
                    });
                }, 5000);
            }
        }
        document.addEventListener('visibilitychange', async function () {

            if (document.visibilityState === 'visible') {
                const isFile = await sessionStorage.getItem('isFile');

                if (isFile === null) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 800);
                }
            }
        });


        const appHeight = () => {
            setTimeout(() => {
                const doc = document.documentElement;
                doc.style.setProperty('--app-height', `${window.innerHeight}px`);
            }, 100);
        };
        window.addEventListener('resize', appHeight);
        appHeight();
        chaneTheme(theme);
    }, []);


    return (
        <div className={'wrapperView'}>
            <Routes>
                <Route path={'/login'} element={<AuthContainer/>}/>
                <Route path={'/'} element={<PrivateRouteMain/>}>
                    <Route path={'/'} element={<Home/>}/>
                    <Route path={'/check/:idForm'} element={<ChecksScreen/>}/>
                    <Route path={'/check-type/:idForm'} element={<CheckTypesScreen/>}/>
                    <Route path={'/select-location/:idForm'} element={<SelectLocation/>}/>
                    <Route path={'/select-employee/:idForm'} element={<SelectEmployee/>}/>
                    <Route path={'/quiz'} element={<Quiz/>}/>
                    <Route path={'/settings'} element={<SettingsScreen/>}/>
                    <Route path={'/camera/:answerId'} element={<Camera/>}/>
                    <Route path={'/task-menu/:idForm'} element={<TaskMenu/>}/>
                    <Route path={'/task-list/:idForm'} element={<TaskList/>}/>
                    <Route path={'/task-create'} element={<TaskCreate/>}/>
                    <Route path={'/quiz-list'} element={<QuizList/>}/>
                    <Route path={'/quickly-task-menu'} element={<QuicklyTaskTypes/>}/>
                    <Route path={'/task-quickly-create/:id'} element={<QuicklyTaskCreate/>}/>
                    <Route path={'/view-pdf'} element={<PdfView/>}/>
                    <Route path={'/view-table'} element={<TableView/>}/>
                    <Route path={'/purchase/:idForm'} element={<Purchase/>}/>
                    <Route path={'/inventory/:idForm'} element={<Inventory/>}/>
                    <Route path={'/inventory-create'} element={<InventoryCreateForm/>}/>
                    <Route path={'/inventory-check'} element={<InventoryCheckForm/>}/>
                    <Route path={'/inventory-view'} element={<InventoryViewCard/>}/>
                    <Route path={'/inventory-list'} element={<InventoryListPage/>}/>
                    <Route path={'/scanner/:idForm'} element={<ScannerPage/>}/>
                    <Route path={'/time-control/:idForm'} element={<TimeControl/>}/>
                    <Route path={'/courier/:idForm'} element={<Courier/>}/>
                    <Route path={'/marketing/:idForm'} element={<Marketing/>}/>
                    <Route path={'/call-center/:idForm'} element={<CallCenter/>}/>
                    <Route path={'/products/:idForm'} element={<Products/>}/>
                    <Route path={'/info-type/:idForm'} element={<InfoType/>}/>
                    <Route path={'/info-view'} element={<InfoView/>}/>
                    <Route path={'/report'} element={<Report/>}/>
                    <Route path={'/report-view'} element={<ViewReport/>}/>
                    <Route path={'/list-surveys'} element={<ListSurveys/>}/>
                    <Route path={'/action-list'} element={<ActionList/>}/>
                    <Route path={'/profile'} element={<Profile/>}/>
                    <Route path={'/financial-menu/:idForm'} element={<FinancialMenu/>}/>
                    <Route path={'/financial-list/:idForm'} element={<FinancialList/>}/>
                    <Route path={'/financial-create'} element={<FinancialCreate/>}/>
                    <Route path={'/photo-report'} element={<PhotoReport/>}/>
                    <Route path={'/photo-report-list'} element={<PhotoReportList/>}/>
                    <Route path={'/photo-report-create'} element={<PhotoReportCreate/>}/>
                    <Route path={'/empty-page'} element={<EmptyPage/>}/>
                    <Route path={'/test-page'} element={<TestPage/>}/>
                    <Route path={'/call-count/:idForm'} element={<PhoneCallCounts/>}/>
                    <Route path={'/calls'} element={<PhoneCall/>}/>
                    <Route path={'/monitoring/:idForm'} element={<Monitoring/>}/>
                    <Route path={'/monitoring-main/:idForm'} element={<MonitoringMainData/>}/>
                    <Route path={'/monitoring-range/:idForm'} element={<MonitoringByRange/>}/>
                    <Route path={'/monitoring-source/:idForm'} element={<MonitoringBySource/>}/>
                    <Route path={'/monitoring-courier/:idForm'} element={<MonitoringByCourier/>}/>
                    <Route path={'/monitoring-step/:idForm'} element={<MonitoringByStep/>}/>
                    <Route path={'/monitoring-map/:idForm'} element={<MonitoringMap/>}/>
                    <Route path={'/order-details'} element={<OrderDetails/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
