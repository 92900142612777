import {api} from "../../../store/api";
import {InfoCreateDoc, PhotoReportDocType, PhotoReportItem} from "./types";
import {CONTENT_TYPE_URLENCODED} from "../../../utils/constants";

const photoReportApi = api.injectEndpoints({
    endpoints: build => ({
        getDocumentsTypes: build.query<PhotoReportDocType[], void>({
            query: () => ({
                url: '/documents/typesPhotoreports.php',
                method: 'GET'
            }),
            transformResponse: ({list}) => {
                return list;
            }
        }),
        getPhotoReports: build.query<PhotoReportItem[], { params: string }>({
            query: ({params}) => ({
                url: `/documents/photoreports.php?${params}`,
                method: 'GET'
            }),
            transformResponse: ({list}) => {
                return list;
            }
        }),
        photoReportFiles: build.mutation<any, { formData: FormData }>({
            query: ({formData}) => ({
                url: '/documents/fileAdd.php',
                method: 'POST',
                body: formData
            })
        }),
        actionsDoc: build.mutation<{ status: boolean, info: InfoCreateDoc }, { body: string }>({
            query: ({body}) => ({
                url: '/documents/r_Doc.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED
                }
            })
        }),
        getDocFiles: build.query<any, { ID_Tab: string }>({
            query: ({ID_Tab}) => ({
                url: `/documents/files.php?ID_Tab=${ID_Tab}`,
                method: 'GET'
            })
        })
    }), overrideExisting: true
});

export const {
    useGetDocumentsTypesQuery,
    useLazyGetPhotoReportsQuery,
    usePhotoReportFilesMutation,
    useActionsDocMutation,
    useLazyGetDocFilesQuery
} = photoReportApi;
