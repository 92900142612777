import React from 'react';
import {MainLayout, QuestionVariable} from "../../layouts";
import Header from "../../components/header";
import {BackButton} from "../../components/backButton";
import {useNavigate, useParams} from "react-router-dom";
import {getObjectByIdForm} from "../../helpers";
import styles from './style.module.scss';
import {useGetAllQuestionsQuery} from "../home/store/questions.api";
import {ListMenu} from '../home/store/types';

export const InfoType = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const navigate = useNavigate();
    const {data} = useGetAllQuestionsQuery();

    const handlePress = (item: ListMenu) => {
        navigate(`/select-location/${idForm}?from=info`, {
            state: {
                idParent: item.idParent
            }
        });
    };

    const viewButtons = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));
    return (
        <MainLayout>
            <Header breadCrumbs={'Информация'}/>
            <BackButton text={'Назад в главное меню'} onClick={() => navigate(-1)}/>
            <div className={styles.list}>
                <QuestionVariable
                    renderData={viewButtons?.children ?? []} handlePress={handlePress} iconHeight={64} iconWidth={64}/>
            </div>
        </MainLayout>
    );
};
