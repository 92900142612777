import {api} from "../../../store/api";
import {Option} from "../../../store/types";
import {FilterObject} from "../../../screens/taskList/store/types";
import {CONTENT_TYPE_URLENCODED} from "../../../utils/constants";
import {formatThemes} from "../../../screens/taskCreate/store/task.api";
import {ListIdName, TreeNode} from "./type";

const filterApi = api.injectEndpoints({
    endpoints: build => ({
        getFilterObj: build.mutation<{ listObject: Option[] }, { body: string }>({
            query: ({body}) => ({
                url: '/listData/p_Obj_new.php',
                method: 'POST',
                body,
                headers: {
                    ...CONTENT_TYPE_URLENCODED
                }
            }),
            transformResponse: ({listObject}) => {
                return {
                    listObject: (Object.values(listObject) as FilterObject[]).map((i) => ({
                        label: i.name,
                        value: String(i.id)
                    }))
                };
            }
        }),
        getFilterTheme: build.query<{ treeList: TreeNode[] }, void>({
            query: () => ({
                url: '/helpDesk/p_AppSect.php',
                method: 'POST',
                headers: {
                    ...CONTENT_TYPE_URLENCODED,
                },
            }),
            transformResponse: ({treeList}) => {
                return {treeList: formatThemes(Object.values(treeList))};
            },
        }),
        getFilterAuthor: build.query<{ list: Option[] }, void>({
            query: () => ({
                url: '/helpDesk/p_App_listAuthor.php',
                method: 'POST'
            }),
            transformResponse: ({list}) => {
                return {list: (Object.values(list) as ListIdName[]).map((i) => ({label: i.name, value: String(i.id)}))};
            }
        }),
        getFilterSolver: build.query<{ list: Option[] }, void>({
            query: () => ({
                url: '/helpDesk/p_App_listSolver.php',
                method: 'POST'
            }),
            transformResponse: ({list}) => {
                return {list: (Object.values(list) as ListIdName[]).map((i) => ({label: i.name, value: String(i.id)}))};
            }
        }),
    }),
    overrideExisting: true
});

export const {
    useGetFilterObjMutation,
    useGetFilterThemeQuery,
    useGetFilterSolverQuery,
    useGetFilterAuthorQuery
} = filterApi;
