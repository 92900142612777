import React, {useState} from 'react';
import styles from './style.module.scss';
import {Input} from '../../fields/input';
import {ImageLayout} from '../../layouts';
import {Button} from '../../fields/button';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {mainColor} from '../../utils/constants';
import CryptoJS from 'crypto-js';
import {Value} from '../../fields/input/Input';
import {useLoginMutation} from './store/auth.api';
import {useNavigate} from 'react-router-dom';
//@eslint-ignore
import {Checkbox, CheckboxProps} from 'antd';

const userIcon = require('../../assets/images/user.png');
const passwordIcon = require('../../assets/images/password.png');
const image = require('../../assets/images/logo.png');

interface FormField {
    login: string;
    pwd: string;
}

const schemaForm = new yup.ObjectSchema<FormField>({
    login: yup.string().required('Это поле обязательно к заполнению'),
    pwd: yup.string().required('Это поле обязательно к заполнению'),
});

export const AuthScreen = () => {
    const navigate = useNavigate();
    const [login, {isLoading}] = useLoginMutation();
    const [isApprove, setApprove] = useState<boolean>(false);
    const {control, setValue, handleSubmit, clearErrors, formState: {errors}} = useForm({
        resolver: yupResolver(schemaForm)
    });

    const onChange: CheckboxProps['onChange'] = (e) => {
        setApprove(e.target.checked);
    };

    const handleChangeInput = (fieldName: keyof FormField, value: Value) => {
        Object.keys(errors).length > 0 && clearErrors();
        setValue(fieldName, String(value));
    };

    const submit = handleSubmit(values => {
        const md5Hash = CryptoJS.MD5(values.pwd).toString();
        const urlencoded = new URLSearchParams();
        urlencoded.append('login', values.login);
        urlencoded.append('pwd', md5Hash);

        login({formData: urlencoded}).unwrap().then((res) => {
            if (res.status) {
                navigate('/');
            }
        });
    });

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <img src={image} alt={'logo'}/>
            </div>
            <h1 className={styles.title}>Авторизация</h1>
            <div className={styles.form}>
                {isLoading
                    ? <p className={styles.loaderText}>идет запрос...</p>
                    : <>
                        <Input
                            type={'text'}
                            fieldName={'login'}
                            control={control}
                            renderIcon={() => (
                                <ImageLayout
                                    src={userIcon}
                                    imageWidth={16}
                                    imageHeight={23}
                                    containerWidth={40}
                                    containerHeight={55}
                                />)}
                            onChange={value => handleChangeInput('login', value)}
                        />
                        <div className={styles.space30}/>
                        <Input
                            fieldName={'pwd'}
                            control={control}
                            type={'password'}
                            renderIcon={() => (
                                <ImageLayout
                                    src={passwordIcon}
                                    imageWidth={25}
                                    imageHeight={25}
                                    containerWidth={40}
                                    containerHeight={55}
                                />)}
                            onChange={value => handleChangeInput('pwd', value)}/>
                        <div className={styles.space30}/>
                        <Checkbox onChange={onChange}>
                            <span
                                className={styles.policy}>
                                согласен с политикой обработки и хранения персональных и биометрических данных
                            </span>
                        </Checkbox>
                        <div className={styles.space30}/>
                        <Button
                            text={'Вход'}
                            disabled={!isApprove}
                            style={{color: mainColor, fontSize: '18px', height: '57px'}}
                            textStyleType={'semiBold'}
                            onClick={submit}
                        />
                    </>}
            </div>
        </div>
    );
};
