import React from 'react';
import {MainLayout} from "../../layouts";
import Header from "../../components/header";
import {useLocation, useNavigate} from "react-router-dom";
import {extractValueFromString} from "../../helpers";
import {BackButton} from "../../components/backButton";

export const ListSurveys = () => {
    const navigate = useNavigate();
    const {search} = useLocation();
    const fromPageName = extractValueFromString(search, 'from');
    return (
        <MainLayout>
            <Header breadCrumbs={`${fromPageName} - Список:`}/>
            <BackButton text={`Назад в ${fromPageName}`} onClick={() => navigate(-1)}/>
        </MainLayout>
    );
};
