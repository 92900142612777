import React, {useEffect, useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from '../../hooks/app.hooks';
import {useLazyGetPhotoReportsQuery} from './store/photoReport.api';
import AppLoader from '../../components/appLoader';
import styles from './style.module.scss';
import {PhotoReportItem} from './store/types';
import ru_RU from 'antd/lib/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import {rangePresets} from '../../components/taskFilter/TaskFilter';
import {DatePicker} from 'antd';

const {RangePicker} = DatePicker;

const formatDate = 'YYYY-MM-DD';
const startDay = dayjs().add(-7, 'day').format(formatDate);
const endDay = dayjs().format(formatDate);

export const PhotoReportList = () => {
    const navigate = useNavigate();
    const {photoReportTypeId} = useAppSelector(state => state.photoReport);
    const {currentLocation} = useAppSelector(state => state.quiz);

    const [getPhotoReports, {data, isLoading}] = useLazyGetPhotoReportsQuery();

    const [date, setDate] = useState<string[]>([startDay, endDay]);

    useEffect(() => {
        const params = `Start=${date[0]}&Finish=${date[1]}&FTypes=${photoReportTypeId}&FObjects=${currentLocation?.id}`;
        getPhotoReports({params});
    }, [date]);

    const handleChangeDate = (dateStrings: string[]) => {
        setDate(dateStrings);
    };

    const handleJump = (id: number) => {
        navigate(`/photo-report-create?id=${id}`);
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Фото отчет из ${currentLocation?.name}`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {isLoading ? <AppLoader isFull={false}/> : (
                <div className={styles.container}>
                    <RangePicker
                        dropdownClassName="custom-range-picker-dropdown"
                        className={styles.inputRange}
                        locale={ru_RU}
                        format={formatDate}
                        presets={rangePresets}
                        value={[dayjs(date[0], formatDate), dayjs(date[1], formatDate)]}
                        onChange={(dates, dateStrings) => handleChangeDate(dateStrings)}
                        separator="-"
                        allowClear={false}
                    />
                    <div className={styles.cardList}>
                        {data?.map((item: PhotoReportItem) => (
                            <div key={item.ID} className={styles.photoReportCard} onClick={() => handleJump(item.ID)}>
                                <div className={styles.photoReportCardHeader}>
                                    <span className={styles.photoReportCardTextSm}>{item.Date.date.split(' ')[0]}</span>
                                    <span className={styles.photoReportCardTextSm}>{item.ObjName}</span>
                                </div>
                                <p className={styles.photoReportCardTitle}>{item.Name}</p>
                                <div className={styles.photoReportCardFooter}>
                                    <span className={styles.photoReportCardTextSm}>{item.DocType}</span>
                                    <span
                                        className={styles.photoReportCardTextSm}>Файлов в документе: {item.FilesCnt}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </MainLayout>
    );
};

