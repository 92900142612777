import React from 'react';
import {ImageLayout, MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {setFilter} from './store/monitoring.slice';
import {useAppDispatch} from '../../hooks/app.hooks';
import dayjs from 'dayjs';
import {IFilter} from '../../components/taskFilter/store/type';
import {getObjectByIdForm} from '../../helpers';
import {ListMenu} from '../home/store/types';
import {useGetAllQuestionsQuery} from '../home/store/questions.api';
import SimpleButton from '../../components/simpleButton';
import {typeFlow} from '../../store/types';
import {SplitStringWithLineBreak} from './components';


const today = dayjs();
const dayAgo = dayjs().subtract(1, 'day');

export const todayDateKeys: IFilter[] = [
    {type: 'Start', value: dayAgo.format('YYYY-MM-DD')},
    {type: 'Finish', value: today.format('YYYY-MM-DD')},
];


export const Monitoring = () => {
    const {idForm} = useParams<{ idForm: string }>();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {data} = useGetAllQuestionsQuery();

    const viewButtons = getObjectByIdForm(data?.ListMenu as ListMenu[], Number(idForm));

    const firstButton = viewButtons?.children.filter(i => ['MonitorToDay', 'MonitorMap'].includes(i.typeMob));
    const secondButton = viewButtons?.children.filter(i => !['MonitorToDay', 'MonitorMap'].includes(i.typeMob));
    const handlePress = (item: ListMenu) => {
        switch (item.typeMob) {
            case typeFlow.MonitorToDay:
                dispatch(setFilter(todayDateKeys));
                return navigate(`/monitoring-main/${item.idForm}`);
            case typeFlow.MonitorPer:
                return navigate(`/monitoring-range/${item.idForm}`);
            case typeFlow.MonitorIst:
                return navigate(`/monitoring-source/${item.idForm}`);
            case typeFlow.MonitorKur:
                return navigate(`/monitoring-courier/${item.idForm}`);
            case typeFlow.MonitorTime:
                return navigate(`/monitoring-step/${item.idForm}`);
            case typeFlow.MonitorMap:
                return navigate(`/monitoring-map/${item.idForm}`);
            default:
                return;
        }
    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Мониторинг'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.wrapper}>
                <p className={styles.title}>Сегодня:</p>
                <div className={styles.list}>

                    {firstButton?.map(item => {
                        return (
                            <SimpleButton
                                key={item.idForm}
                                column={2}
                                handler={() => handlePress(item)}
                            >
                                <div className={styles.listItem}>
                                    <ImageLayout
                                        src={item.icon.source}
                                        imageWidth={32}
                                        imageHeight={32}
                                        containerWidth={32}
                                        containerHeight={32}
                                    />
                                    <SplitStringWithLineBreak className={styles.listText} text={item.name}/>
                                </div>
                            </SimpleButton>
                        );
                    })}
                </div>
                <div className={styles.separator}/>
                <p className={styles.title}>За период:</p>
                <div className={styles.list}>
                    {secondButton?.map(item => {
                        return (
                            <SimpleButton
                                key={item.idForm}
                                column={2}
                                handler={() => handlePress(item)}
                            >
                                <div className={styles.listItem}>
                                    <ImageLayout
                                        src={item.icon.source}
                                        imageWidth={32}
                                        imageHeight={32}
                                        containerWidth={32}
                                        containerHeight={32}
                                    />
                                    <SplitStringWithLineBreak className={styles.listText} text={item.name}/>
                                </div>
                            </SimpleButton>
                        );
                    })}
                </div>
            </div>
            <Footer/>
        </MainLayout>
    );
};
