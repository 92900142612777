import {createSlice} from "@reduxjs/toolkit";
import {ChatState} from "./types";
import {chatApi} from "./chat.api";

const initialState: ChatState = {
    messages: []
};

const chatSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.messages = [];
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            chatApi.endpoints.getMessages.matchFulfilled,
            (state, {payload}) => {
                state.messages = payload.listMessage;
            }
        );
    }
});

export const {clearMessages} = chatSlice.actions;

export default chatSlice.reducer;
