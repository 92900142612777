import React, {useState} from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {useLocation, useNavigate} from 'react-router-dom';
import {useGetObjInfoQuery} from './store/info.api';
import styles from './style.module.scss';
import {SwitcherTab} from '../../components/switcherTab';
import {InfoTab} from './components';
import AppLoader from '../../components/appLoader';
import Footer from '../../components/footer';

const buttons: string[] = ['Сведения', 'Показатели', 'Оснащение'];

export const InfoView = () => {
    const navigate = useNavigate();
    const {state} = useLocation();
    const {data, isLoading} = useGetObjInfoQuery({FObjects: state.FObjects}, {skip: !state?.FObjects});

    const [activeTab, setActiveTab] = useState<number>(0);

    const renderTab = () => {
        switch (activeTab) {
            case 0:
                return <InfoTab object={data?.object}/>;
        }
    };

    return (
        <MainLayout>
            <Header breadCrumbs={`Объект - ${data?.object?.Name ?? ''}`}/>
            <BackButton text={'Назад в Информация - Поиск объекта'} onClick={() => navigate(-1)}/>
            <div className={styles.container}>
                <SwitcherTab
                    pageType={'info'}
                    buttons={buttons}
                    isActive={activeTab}
                    onClick={index => setActiveTab(index)}/>
                {isLoading ? <AppLoader isFull={false}/> : renderTab()}
            </div>
            <Footer/>
        </MainLayout>
    );
};
