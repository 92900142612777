import React from 'react';
import styles from "./style.module.scss";
import {ImageLayout} from "../../layouts";
import {Button} from "../../fields/button";
import {disabledColor, mainColor} from "../../utils/constants";

const iconSuccess = require('../../assets/images/face-success.png');
const iconFailed = require('../../assets/images/face-fail.png');

interface SuccessProps {
    title: string;
    text: string;
    type: 'success' | 'failed';
    onConfirmDisable: boolean;
    onCansel: () => void;
    onConfirm: () => void;
}

export const ModalContent = ({onConfirm, text, title, type, onConfirmDisable, onCansel}: SuccessProps) => {
    return (
        <>
            <p className={styles.modalText}>{title}</p>
            <ImageLayout
                src={type === 'success' ? iconSuccess : iconFailed}
                imageWidth={64}
                imageHeight={64}
                containerWidth={120}
                containerHeight={120}/>
            <p className={styles.modalText} style={{marginBottom: '10px'}}>{text}</p>
            <div className={styles.modalButtons}>

                <div className={styles.modalButtonsBox}>
                    <Button
                        text={'Да'}
                        textStyleType={'regular'}
                        onClick={onConfirm}
                        disabled={onConfirmDisable}
                        style={{
                            border: `1px solid ${onConfirmDisable ? disabledColor : mainColor}`,
                            borderRadius: '12px',
                            fontSize: '14px',
                            height: '44px'
                        }}/>
                </div>
                <div className={styles.modalButtonsBox}>
                    <Button
                        text={'Нет'}
                        textStyleType={'regular'}
                        onClick={onCansel}
                        style={{
                            border: `1px solid ${mainColor}`,
                            borderRadius: '12px',
                            fontSize: '14px',
                            height: '44px'
                        }}
                    />
                </div>
            </div>
        </>
    );
};
