import {createSlice} from '@reduxjs/toolkit';
import {OrderStore} from './types';
import {orderApi} from './order.api';

const initialState: OrderStore = {
    order: null
};

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        clearOrder: (state) => {
            state.order = null;
        }
    },
    extraReducers: builder => {
        builder.addMatcher(
            orderApi.endpoints.getOrderInfo.matchFulfilled,
            (state, {payload}) => {
                state.order = payload;
            }
        );
    }
});


export const {clearOrder} = orderSlice.actions;

export default orderSlice.reducer;
