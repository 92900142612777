import React from 'react';
import styles from '../style.module.scss';
import {CombineCard} from '../components';
import {IReport} from '../store/types';
import {TabProps} from './TodayTab';

export const YesTodayTab = ({loaderMain, loaderOther, costPrice, revenue, writeOff}: TabProps) => {

    return (
        <div className={styles.cards}>
            <CombineCard
                revenueLoad={loaderMain}
                writeOffLoad={loaderMain}
                costPriceLoad={loaderOther}
                corpRev={revenue?.corpRev as IReport}
                corpChecks={revenue?.corpChecks as IReport}
                corpAvgCheck={revenue?.corpAvgCheck as IReport}
                corpWriteOff={writeOff?.corpWriteOff as IReport}
                corpCostPrice={costPrice?.corpCostPrice as IReport}
                corpCostPricePercent={costPrice?.corpCostPricePercent as IReport}
            />
            <CombineCard
                revenueLoad={loaderMain}
                writeOffLoad={loaderMain}
                costPriceLoad={loaderOther}
                corpRev={revenue?.frRev as IReport}
                corpChecks={revenue?.frChecks as IReport}
                corpAvgCheck={revenue?.frAvgCheck as IReport}
                corpWriteOff={writeOff?.frWriteOff as IReport}
                corpCostPrice={costPrice?.frCostPrice as IReport}
                corpCostPricePercent={costPrice?.corpCostPricePercent as IReport}
            />
        </div>
    );
};
