import {createSlice} from '@reduxjs/toolkit';
import {ActionsState, IActionFilter} from './types';
import dayjs from 'dayjs';

const today = dayjs();
const monthAgo = dayjs().subtract(1, 'day');

export const dateKeys: IActionFilter[] = [
    {type: 'Start', value: monthAgo.format('YYYY-MM-DD')},
    {type: 'Finish', value: today.format('YYYY-MM-DD')},
];

const initialState: ActionsState = {
    keys: dateKeys,
};

const actionSlice = createSlice({
    name: 'actions',
    initialState,
    reducers: {
        setActionFilter: (state, {payload}) => {
            state.keys = payload;
        },
        setClearActionFilter: (state) => {
            state.keys = dateKeys;
        },
    }
});

export const {setActionFilter, setClearActionFilter} = actionSlice.actions;

export default actionSlice.reducer;
