import React from 'react';

export const CloseIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 3C7.02943 3 3 7.02943 3 12C3 16.9706 7.02943 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02943 16.9706 3 12 3ZM8.46449 8.46444C8.85502 8.07392 9.48818 8.07392 9.87871 8.46444L12 10.5858L14.1213 8.46444C14.5119 8.07392 15.145 8.07392 15.5356 8.46444C15.9261 8.85497 15.9261 9.48813 15.5356 9.87866L13.4142 12L15.5356 14.1213C15.9261 14.5118 15.9261 15.145 15.5356 15.5355C15.145 15.926 14.5119 15.926 14.1213 15.5355L12 13.4142L9.87871 15.5355C9.48818 15.926 8.85502 15.926 8.46449 15.5355C8.07397 15.145 8.07397 14.5118 8.46449 14.1213L10.5858 12L8.46449 9.87866C8.07397 9.48813 8.07397 8.85497 8.46449 8.46444Z"
                  fill="black" fillOpacity="0.65"/>
        </svg>
    );
};

